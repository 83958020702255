import Papa from "papaparse";
import {
  GetExitProtocolHistoryResponse,
  GetExitProtocolHistoryResponseModified,
} from "@/module/dayLevelAnalysis/api/exitprotocolhistory";
import moment from "moment";
import { UTCTimestamp } from "lightweight-charts";
export const defaultStrategy = "no strategy";

export const downloadCSV = (data: any) => {
  var csv = Papa.unparse(data);

  var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  var csvURL = null;
  if ((window.navigator as any).msSaveBlob) {
    csvURL = (window.navigator as any).msSaveBlob(csvData, "download.csv");
  } else {
    csvURL = window.URL.createObjectURL(csvData);
  }

  var tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", "download.csv");
  tempLink.click();
};

export const formatPoints = (value: number) => {
  if (value > 1) {
    return Math.round(value);
  } else {
    return Math.round(value * 10) / 10;
  }
};

export const formatRupees = (amount: number) => {
  if (amount < 1) {
    return amount.toFixed(1);
  } else if (amount < 1000) {
    return amount.toFixed();
  } else if (amount < 100000) {
    const inThousands = (amount / 1000).toFixed(1);
    return inThousands.endsWith(".0")
      ? `${inThousands.slice(0, -2)}k`
      : `${inThousands}k`;
  } else if (amount < 10000000) {
    const inLakhs = (amount / 100000).toFixed(1);
    return inLakhs.endsWith(".0") ? `${inLakhs.slice(0, -2)}L` : `${inLakhs}L`;
  } else {
    // Added condition for crore
    const inCrores = (amount / 10000000).toFixed(1);
    return inCrores.endsWith(".0")
      ? `${inCrores.slice(0, -2)}cr`
      : `${inCrores}cr`;
  }
};

export const formatToOneDecimal = (value: number) => {
  const roundedValue = Math.round(value * 10) / 10;
  const formattedValue = roundedValue.toFixed(1);
  return formattedValue.endsWith(".0")
    ? formattedValue.slice(0, -2)
    : formattedValue;
};

export const initialStrategyvalue = {
  version: 1,
  entry_criteria: [],
  exit_criteria: [],
  updated_at: "",
  strategy: defaultStrategy,
};
export const formatRRValue = (value: number) => {
  const formatNumber = (num: number) => {
    const fixed = num.toFixed(1);
    return fixed.endsWith(".0") ? parseInt(fixed, 10).toString() : fixed;
  };

  if (value >= 0.7) {
    const x = formatNumber(value);
    return `1:${x}`;
  } else {
    const x = formatNumber(1 / value);
    return `${x}:1`;
  }
};
export const formatPFValue = (value: number) => {
  const fixed = value.toFixed(2);
  return fixed.endsWith(".00") ? parseInt(fixed, 10).toString() : fixed;
};

export const sortExitProtocols = (data: GetExitProtocolHistoryResponse[]) => {
  const transformedData: GetExitProtocolHistoryResponseModified[] = data.map(
    (d: GetExitProtocolHistoryResponse) => ({
      ...d,
      time: (moment(d.trade_start_time).utcOffset(0, true).valueOf() /
        1000) as UTCTimestamp,
    })
  );
  transformedData.sort(
    (
      a: GetExitProtocolHistoryResponseModified,
      b: GetExitProtocolHistoryResponseModified
    ) => b.time - a.time
  );

  return transformedData;
};
