import { useRef } from "react";
import "../welcomePages/boardingflowstepper.css";
import { LogoutConfirmationModal } from "../welcomePages/LogoutConfirmationModal";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { updateConsent } from "@/module/auth/api/auth";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import "@/pages/auth/consentScreen/consentscreen.css";
import topshineIMG from "@/assets/web-top-shine.svg";
import bottomshineIMG from "@/assets/web-bottom-shine.svg";
import { useAppDispatch, useAppSelector } from "@/store/reduxHooks";
import { setIsNavigateToDashboard } from "../authSlice";

const ConsentScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userState = useAppSelector((state) => state.userState);
  const { user } = userState;
  const consentScreenRef = useRef<any>(null);
  const queryClient = useQueryClient();
  const updateConsentStatus = useMutation({
    mutationFn: updateConsent,
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        queryClient.invalidateQueries("user");

        if (!user.wg_seen_flag) {
          navigate("/add-widget");
        } else {
          dispatch(setIsNavigateToDashboard(true)); // loader while navigate to dashboard
        }
      }
    },
    onError: (error) => {
      toast.error(<p>Something went wrong!Please try again!</p>, {
        duration: 2000,
      });
    },
  });

  const handleAgree = () => {
    updateConsentStatus.mutate();
  };

  return (
    <>
      <div
        className="boarding-flow-welcome-screens h-[100vh] w-[100%] flex items-center justify-center"
        ref={consentScreenRef}
      >
        <LogoutConfirmationModal containerRef={consentScreenRef} />
        <div className="consent-screen-modal">
          <h1 className="text-[48px] font-semibold imp-info">
            Important <span className="text-[#fba137]">Information</span>
          </h1>
          <div className="consent-container flex flex-col justify-center items-start">
            <p>
              In order for us to provide you the insights, we will require the
              below information from your broker account:
            </p>
            <ul>
              <li>Tradebook data.</li>
              <li>Positions data.</li>
              <li>Order book data.</li>
            </ul>
            <p>
              For the first time data sync, we will be accessing the above for
              the last 90 days.
            </p>
          </div>
          <Button className="agree-consent-btn relative" onClick={handleAgree}>
            <img
              className="w-[20px] absolute left-[12px] top-[7px]"
              src={topshineIMG}
              alt=""
            />
            I Agree
            <img
              className="w-[20px] absolute right-[12px] bottom-[7px]"
              src={bottomshineIMG}
              alt=""
            />
          </Button>
        </div>
      </div>
    </>
  );
};

export default ConsentScreen;
