import { apiClient } from "@/utils/api";
import {
  LoginResponse,
  ResetPasswordData,
  verificationResponse,
} from "@/module/auth/interface/AuthInterface";
import type { AxiosResponse } from "axios";

interface ChangePasswordApiResponse {
  message: string;
  status: string;
}

interface ChangePasswordData {
  old_password: string;
  new_password: string;
}

export interface GetAllBrokersResponse {
  brkr_id: string;
  brkr_name: string;
}
export interface User {
  name: string;
  email_confirmed_at?: string;
  phone_number_confirmed_at?: string;
  user_id?: string;
  profile_set_flag?: boolean;
  consent_seen_flag?: boolean;
  go_seen_flag: boolean;
  wg_seen_flag: boolean;
}

// Login
const login = async (userData: any) => {
  const response = await apiClient.post<LoginResponse>(
    `/auth/v1/login`,
    userData.toString(),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return response.data;
};

const changePassword = async (data: ChangePasswordData) => {
  const response = await apiClient.post<ChangePasswordApiResponse>(
    "/auth/v1/change-password",
    data
  );
  return response.data;
};

const getUser = async (): Promise<User> => {
  const res: AxiosResponse<User> = await apiClient.get("/user/v1/show-user");

  return res.data;
};

const getAllBrokers = async (): Promise<GetAllBrokersResponse[]> => {
  const res: AxiosResponse<GetAllBrokersResponse[]> = await apiClient.get(
    "/user/v1/show-brokers"
  );

  return res.data;
};

// Login

const register = async (userData: any) => {
  const response = await apiClient.post<LoginResponse>(
    `/user/v1/create-user`,
    userData
  );
  return response.data;
};

// Verify Mobile No.

const sendMobileOtp = async () => {
  const response = await apiClient.post<verificationResponse>(`/verify/v1/sms`);
  return response.data;
};

const verifyMobileOtp = async (otp: string) => {
  const response = await apiClient.post<verificationResponse>(
    `/verify/v1/sms/confirm?code=${otp}`,
    otp
  );
  return response.data;
};

// Verify Email

const sendEmailOtp = async () => {
  const response = await apiClient.post<verificationResponse>(
    `/verify/v1/email`
  );
  return response.data;
};

const verifyEmailOtp = async (otp: string) => {
  const response = await apiClient.post<verificationResponse>(
    `/verify/v1/email/confirm?code=${otp}`,
    otp
  );
  return response.data;
};

// send forgot password code
const forgotPasswordOtp = async (email: string) => {
  const response = await apiClient.post<verificationResponse>(
    `/auth/v1/forgot-password?email=${email}`
  );
  return response.data;
};

const resetPassword = async (data: ResetPasswordData) => {
  const response = await apiClient.post<ChangePasswordApiResponse>(
    "/auth/v1/reset-password",
    data
  );
  return response.data;
};

const updateConsent = async () => {
  const response = await apiClient.post<verificationResponse>(
    `/user/v1/consent`
  );
  return response.data;
};
const updateGuidedFlowStatus = async () => {
  const response = await apiClient.post<verificationResponse>(`/user/v1/go`);
  return response.data;
};

const updateWidgetStatus = async () => {
  const response = await apiClient.post<verificationResponse>(`/user/v1/wg`);
  return response.data;
};

export {
  login,
  changePassword,
  getAllBrokers,
  getUser,
  register,
  sendMobileOtp,
  verifyMobileOtp,
  sendEmailOtp,
  verifyEmailOtp,
  forgotPasswordOtp,
  resetPassword,
  updateConsent,
  updateGuidedFlowStatus,
  updateWidgetStatus,
};
