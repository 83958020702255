import React, { useEffect, useState } from "react";
import "@/pages/auth/Signup.css";
import mainLogoImg from "@/assets/Group 1633.webp";
import hasIconImg from "@/assets/Group 86.webp";
import { useAppDispatch, useAppSelector } from "@/store/reduxHooks";
import { NavLink, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast";
import { getUser, register } from "@/module/auth/api/auth";
import { logOutUser, setAuth, setUser } from "./authSlice";
import Cookie from "js-cookie";
import { useFormik } from "formik";
import { UserRegisterData } from "@/module/auth/interface/AuthInterface";
import { registerValidation } from "@/validation/AuthValidation";
import { FiEye, FiEyeOff } from "react-icons/fi";
import betLogo from "@/assets/bet-logo.svg";

import topshineIMG from "@/assets/web-top-shine.svg";
import bottomshineIMG from "@/assets/web-bottom-shine.svg";

const Signup = () => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.userState);
  const { isLoggedIn } = userState;
  const navigate = useNavigate();

  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const [hideConfirmPassword, sethideConfirmPassword] = useState<boolean>(true);

  const mutation = useMutation({
    mutationFn: register,
    onSuccess: (data) => {
      toast.success(<p>Log In Success! Please verify Email!</p>, {
        duration: 2000,
      });
      if (data.access_token) {
        dispatch(setAuth(true));
        const token = data.access_token;
        var expiredIn = new Date();
        const expiredDays = 30;
        expiredIn.setTime(
          expiredIn.getTime() + expiredDays * 24 * 60 * 60 * 1000
        );

        Cookie.set("auth-token", token, {
          secure: true,
          sameSite: "strict",
          expires: expiredIn,
        });
      }
    },
    onError: (error: any) => {
      const msg = error?.response?.data?.detail;
      toast.error(<p>{msg}</p>, {
        duration: 2000,
      });
    },
  });

  const userQuery = useQuery({
    queryKey: ["user", isLoggedIn],
    queryFn: () => getUser(),
    refetchOnWindowFocus: false,
    enabled: isLoggedIn,
    retry: 0,
    onSuccess: (data) => {
      const {
        email_confirmed_at,
        phone_number_confirmed_at,
        profile_set_flag,
        ...userDetail
      } = data;
      dispatch(setUser(data));
      if (!email_confirmed_at) {
        navigate("/verifyemail");
      } else if (!phone_number_confirmed_at) {
        navigate("/verifynumber");
      } else if (!profile_set_flag) {
        navigate("/welcome");
      } else {
        navigate("/");
      }
    },
    onError: (error: any) => {
      const msg = error?.response?.data?.detail;
      if (
        msg == "Not authenticated" ||
        msg == "Could not validate credentials"
      ) {
        dispatch(logOutUser());
        toast.error(<p>{msg}!</p>, {
          duration: 2000,
          id: "authetication_error",
        });
      } else {
        toast.error(<p>{msg}!</p>, {
          duration: 2000,
          id: "authetication_error",
        });
      }
    },
  });

  const [initialValues, setInitialValues] = useState<UserRegisterData>({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
  });

  const onSubmit = async (values: UserRegisterData) => {
    const data = {
      name: values.name,
      email: values.email,
      password: values.password,
      phone_number: `+91${values.phoneNumber}`,
    };
    mutation.mutate(data);
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: registerValidation,
  });

  return (
    <div className="sign-up">
      <div className="sign-up-left displayFlexCentre">
        <div className="sign-up-left-content">
          <img src={mainLogoImg} alt="" />
        </div>
        <div className="sign-up-left-content">
          <h2>Level up your Game with Betwizr</h2>
        </div>
        <div className="sign-up-left-content">
          <h1>
            <span>Try Now, with</span>
            <br />
            Free Access during Beta period!
          </h1>
        </div>
        <div className="sign-up-left-content">
          <p>*No Credit Card Required</p>
        </div>
        <div className="sign-up-left-content">
          <NavLink to="/login">
            <button className="reg-log-button relative">
              <img
                className="w-[20px] absolute left-[12px] top-[7px]"
                src={topshineIMG}
                alt=""
              />
              Log In
              <img
                className="w-[20px] absolute right-[12px] bottom-[7px]"
                src={bottomshineIMG}
                alt=""
              />
            </button>
          </NavLink>
        </div>
        <div className="sign-up-left-content">
          <h4>Got a Question? Contact Us</h4>
        </div>
        <div className="sign-up-left-content support-email-signup displayFlexCentre">
          <p>support@betwizr.trade</p>
        </div>
      </div>

      <div className="sign-up-right displayFlexCentre">
        <div className="sign-up-right-content sign-up-right-content-logo">
          <div className="right-content-logo logo-text-container displayFlexCentre">
            <img src={betLogo} alt="" />
          </div>
          <h2>Create Account</h2>
        </div>
        {/* <div className="signup-gmail-btn create-forget-btn">
          <button>Sign In With Gmail Account</button>
        </div> */}
        <form onSubmit={formik.handleSubmit} className="logIn-form-containter">
          <div className="sign-up-right-content">
            <div className="lable">Name</div>
            <input
              type="text"
              placeholder="Enter your name..."
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name && (
              <p className="signupPage-input-error">
                {formik.errors.name.toString()}
              </p>
            )}
            <div className="lable">Email</div>
            <input
              type="text"
              placeholder="Enter email..."
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email && (
              <p className="signupPage-input-error">
                {formik.errors.email.toString()}
              </p>
            )}
            <div className="lable">You Phone no.</div>

            <div className="mt-1 relative rounded-md shadow-sm">
              <span className="inline-flex items-center indian-code-phone">
                +91{"  "}|
              </span>
              <input
                className="input-indian-number"
                type="text"
                placeholder="Enter phone no..."
                name="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                maxLength={10}
              />
            </div>

            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <p className="signupPage-input-error">
                {formik.errors.phoneNumber.toString()}
              </p>
            )}
            <div className="lable">Password</div>
            <div className="password-signup">
              <input
                type={hidePassword ? "password" : "text"}
                placeholder="Enter Password..."
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              {hidePassword ? (
                <FiEye
                  className="iconSPasswordR"
                  onClick={() => {
                    setHidePassword(false);
                  }}
                />
              ) : (
                <FiEyeOff
                  className="iconSPasswordR"
                  onClick={() => {
                    setHidePassword(true);
                  }}
                />
              )}
            </div>
            {formik.touched.password && formik.errors.password && (
              <p className="signupPage-input-error">
                {formik.errors.password.toString()}
              </p>
            )}
            <div className="lable">Re-Enter Passworrd</div>
            <div className="password-signup">
              <input
                type={hideConfirmPassword ? "password" : "text"}
                placeholder="Confirm Password..."
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                className="password-signup"
              />
              {hideConfirmPassword ? (
                <FiEye
                  className="iconSPasswordR"
                  onClick={() => {
                    sethideConfirmPassword(false);
                  }}
                />
              ) : (
                <FiEyeOff
                  className="iconSPasswordR"
                  onClick={() => {
                    sethideConfirmPassword(true);
                  }}
                />
              )}
            </div>
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <p className="signupPage-input-error">
                  {formik.errors.confirmPassword.toString()}
                </p>
              )}
          </div>

          <div className="sign-up-right-content">
            <button
              className="reg-log-button relative"
              type="submit"
              disabled={formik.isSubmitting}
            >
              <img
                className="w-[20px] absolute left-[12px] top-[7px]"
                src={topshineIMG}
                alt=""
              />
              Create
              <img
                className="w-[20px] absolute right-[12px] bottom-[7px]"
                src={bottomshineIMG}
                alt=""
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
