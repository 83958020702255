import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { getScripMap } from "@/module/dashboard/api/insights";
import { addDays, format } from "date-fns";
import { DateRange } from "react-day-picker";
import { useAppSelector } from "@/store/reduxHooks";
import { Treemap } from "react-vis";
import "react-vis/dist/style.css";
import { GetScripMapResponse } from "@/module/dashboard/interface/insightsInterface";
import { CustomErrorBoundary } from "../errorBoundary/CustomErrorBoundary";
interface dataTypeInterface {
  size: number;
  style: any;
  scrip_name: string;
  trades_cnt: number;
  total_mtm: number;
  total_roi: number;
  othersList?: GetScripMapResponse[];
}

const ScripMap = ({ date }: { date: DateRange | undefined }) => {
  const userState = useAppSelector((state) => state.userState);
  const { brokerId, isAuthenticated, isGuidedFlowSeen } = userState;
  const [isApiError, setIsApiError] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);

  const scripMap = useQuery({
    queryKey: ["scripMap", date, brokerId, isAuthenticated],
    queryFn: () => {
      setIsApiError(false);

      return getScripMap({
        start_date: date?.from
          ? format(date.from, "yyyy-MM-dd")
          : format(addDays(new Date(), -7), "yyyy-MM-dd"),
        end_date: date?.to
          ? format(date.to, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        broker_id: brokerId,
      });
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && brokerId.length > 0 && isGuidedFlowSeen,
    retry: 0,
    onError: (error) => {
      setIsApiError(true);
    },
  });

  const [scripMapData, setScripMapData] = useState<dataTypeInterface[]>([]);

  const [zeroError, setZeroError] = useState("");

  useEffect(() => {
    if (scripMap.isSuccess && scripMap.data) {
      const data = scripMap.data;

      const hasZeroTradeRoi = data.every((item) => item.total_roi == 0);
      if (hasZeroTradeRoi) {
        setZeroError("No data available for the selected date range");
      } else {
        setZeroError("");
      }

      const sortedData = data.sort(
        (a, b) => Math.abs(b.total_roi) - Math.abs(a.total_roi)
      );
      const top5 = sortedData.slice(0, 5);
      const sum_of_top5_roi = top5.reduce((acc, curr) => {
        return acc + Math.abs(curr.total_roi);
      }, 0);

      const res: dataTypeInterface[] = data
        .map((item) => {
          return {
            ...item,
            size:
              Math.abs((item.total_roi / sum_of_top5_roi) * 100 * 0.9) < 10
                ? 10
                : Math.abs((item.total_roi / sum_of_top5_roi) * 100 * 0.9),
            style: {
              borderRadius: "14px",
            },
          };
        })
        .sort((a, b) => Math.abs(b.size) - Math.abs(a.size));

      let sliceIndex = res.findIndex(
        (val: dataTypeInterface, index: number) => {
          if (val.total_roi == 0) {
            return true;
          }
          if (index == 5) {
            return true;
          }
          return false;
        }
      );

      sliceIndex = sliceIndex == -1 ? 5 : sliceIndex;
      const main = res.slice(0, sliceIndex);
      const others = res.slice(sliceIndex);
      if (others.length > 0) {
        const othersTotalRoi = others.reduce((acc, curr) => {
          return acc + curr.total_roi;
        }, 0);
        setScripMapData([
          ...main,

          {
            scrip_name: "Others",
            size: 10,
            total_roi: othersTotalRoi,
            style: {
              borderRadius: "14px",
            },
            othersList: others,
            trades_cnt: 0,
            total_mtm: 0,
          },
        ]);
      } else {
        setScripMapData(main);
      }
    }
  }, [scripMap.data]);

  function getColorVal(val: dataTypeInterface) {
    if (val.scrip_name === "Others") {
      return "#FEA338";
    }
    if (val.total_roi <= -1.5) {
      const roiFactor =
        Math.abs(val.total_roi) * 1.5 > 60 ? 60 : Math.abs(val.total_roi);
      const luminous = 80 - Math.floor(roiFactor);
      return `hsl(0, 100%, ${luminous}%)`; // luminous should be between 20 to 80
    } else {
      if (val.total_roi >= -1.5 && val.total_roi <= 1.5) return "#F4C430"; // Yellow for -10 to +10

      const roiFactor = val.total_roi * 1.5 > 60 ? 60 : val.total_roi;
      const luminous = 80 - Math.floor(roiFactor); // luminous should be between 20 to 80
      return `hsl(110, 80%, ${luminous}%)`;
    }
  }

  const data = {
    title: "scrips",
    opacity: 1,
    children: scripMapData,
  };

  const treeProps = {
    className: "my-treemap",
    data: data,
    hideRootNode: true,
    width: parentRef.current
      ? parentRef.current?.getBoundingClientRect().width
      : 100,
    height: parentRef.current
      ? parentRef.current?.getBoundingClientRect().height
      : 100,
    mode: "binary",
    padding: 10,
    colorType: "literal",
    getColor: (d: dataTypeInterface) => getColorVal(d),
    getLabel: (item: dataTypeInterface) => {
      return (
        <div style={{ color: "#fff" }}>
          <h3
            style={{ fontSize: "22px", color: "#181818BF" }}
            className="truncate"
          >
            {item.scrip_name}
          </h3>
          <p style={{ fontSize: "22px", textAlign: "center" }}>
            {item.total_roi > 1
              ? Math.round(item.total_roi)
              : item.total_roi.toFixed(2)}
            %
          </p>
        </div>
      );
    },
  };

  const [tooltipData, setTooltipData] = useState<any>({
    message: [],
    x: 0,
    y: 0,
    isVisible: false,
    targetElemWidth: 0,
  });

  const [isMouseOntooltip, setIsMouseOnTootip] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setTooltipData({
        message: [],
        x: 0,
        y: 0,
        isVisible: false,
        targetElemWidth: 0,
      });
    });
  }, []);

  return (
    <CustomErrorBoundary
      isError={scripMap.isError}
      isLoading={scripMap.isLoading}
      error={scripMap.error}
      isApiError={isApiError}
      childRef={parentRef}
      customClassName="scrip-map-error-bound"
    >
      <div
        style={{
          width: "100%",
          height: "98%",
        }}
        ref={parentRef}
        className="heatmap"
      >
        {scripMapData.length > 0 && !zeroError && (
          <>
            {/**@ts-ignore */}
            <Treemap
              {...treeProps}
              onLeafMouseOver={(datapoint: any, event) => {
                //@ts-ignore
                const boundingRect = event.target.getBoundingClientRect();
                if (datapoint.data.scrip_name == "Others") {
                  setTooltipData({
                    isVisible: true,
                    message: datapoint.data.othersList,
                    x: boundingRect.x,
                    y: boundingRect.y,
                    targetElemWidth: boundingRect.width,
                  });
                } else {
                  setTooltipData({
                    isVisible: true,
                    message: [datapoint.data],
                    x: boundingRect.x,
                    y: boundingRect.y,
                    targetElemWidth: boundingRect.width,
                  });
                }
              }}
              onLeafMouseOut={() => {
                if (!isMouseOntooltip) {
                  setTooltipData({
                    message: [],
                    x: 0,
                    y: 0,
                    isVisible: false,
                    targetElemWidth: 0,
                  });
                }
              }}
            />
            {tooltipData.isVisible && (
              <div
                onMouseOver={() => {
                  setIsMouseOnTootip(true);
                }}
                onMouseLeave={() => {
                  setTooltipData({
                    message: [],
                    x: 0,
                    y: 0,
                    isVisible: false,
                    targetElemWidth: 0,
                  });
                  setIsMouseOnTootip(false);
                }}
                style={{
                  position: "fixed",
                  width: "200px",
                  top: tooltipData.y + 20,
                  left: tooltipData.targetElemWidth / 2 + tooltipData.x,
                  padding: "2rem",
                  backgroundColor: "#28282B",
                  color: "#FFFFFF",
                  transform: "translate(-50%, -100%)",
                  zIndex: 5,
                  borderRadius: "8px",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                  maxHeight: "350px",
                  overflowY: "auto",
                }}
              >
                {tooltipData.message.map(
                  (el: dataTypeInterface, index: number) => {
                    return (
                      <div
                        key={index}
                        style={{
                          marginBottom:
                            index !== tooltipData.message.length - 1
                              ? "3px"
                              : "0",
                          backgroundColor: "#28282C",
                          padding: "4px",
                          borderRadius: "20px",
                        }}
                      >
                        <h1
                          style={{ fontSize: "1rem", marginBottom: "0.2rem" }}
                        >
                          Scrip: {el.scrip_name}
                        </h1>
                        <h1
                          style={{ fontSize: "1rem", marginBottom: "0.2rem" }}
                        >
                          ROI: {el.total_roi.toFixed(2)}%
                        </h1>
                        <h1
                          style={{ fontSize: "1rem", marginBottom: "0.2rem" }}
                        >
                          No. of trades: {el.trades_cnt}
                        </h1>
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </>
        )}
        {!scripMap.isLoading && zeroError.length > 0 && (
          <h1 className="flex items-center justify-center w-[98%] text-center h-[98%]">
            {zeroError}
          </h1>
        )}
      </div>
    </CustomErrorBoundary>
  );
};

export default ScripMap;
