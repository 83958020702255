import { getTod } from "@/module/dashboard/api/insights";
import { GetTodResponse } from "@/module/dashboard/interface/insightsInterface";
import { formatRupees } from "@/module/dayLevelAnalysis/components/ExitProtocolHistory/helper";
import { useAppSelector } from "@/store/reduxHooks";
import { ApexOptions } from "apexcharts";
import { addDays, format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { DateRange } from "react-day-picker";
import { useQuery } from "react-query";
import { addLabelToData } from "./helper";
import { CustomErrorBoundary } from "../errorBoundary/CustomErrorBoundary";

const TodChart = ({ date }: { date: DateRange | undefined }) => {
  const userState = useAppSelector((state) => state.userState);
  const { brokerId, isAuthenticated, isGuidedFlowSeen } = userState;
  const [isApiError, setIsApiError] = useState(false);
  const childRef = useRef<any>(null);

  const [todData, setTodData] = useState<GetTodResponse[]>([]);
  const TOD = useQuery({
    queryKey: ["TOD", date, brokerId, isAuthenticated],
    queryFn: () => {
      setIsApiError(false);

      return getTod({
        start_date: date?.from
          ? format(date.from, "yyyy-MM-dd")
          : format(addDays(new Date(), -7), "yyyy-MM-dd"),
        end_date: date?.to
          ? format(date.to, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        broker_id: brokerId,
      });
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && brokerId.length > 0 && isGuidedFlowSeen,
    retry: 0,
    onError: (error) => {
      setIsApiError(true);
    },
  });

  const minValue = Math.min(
    ...todData.map((el: GetTodResponse) => {
      return el.total_mtm;
    })
  );

  const maxValue = Math.max(
    ...todData.map((el: GetTodResponse) => {
      return el.total_mtm;
    })
  );

  const getMinMaxOffset = (minValue: number, maxValue: number) => {
    let minOffset = Math.round(minValue + 0.2 * minValue);
    let maxOffset = Math.round(maxValue + 0.2 * maxValue);
    if (maxValue > 0 && minValue > 0) {
      minOffset = -Math.round(minValue);
      maxOffset = Math.round(maxValue + 0.2 * maxValue);
    }
    if (maxValue < 0 && minValue < 0) {
      minOffset = Math.round(minValue + 0.2 * minValue);
      maxOffset = -Math.round(minValue);
    }

    return {
      minOffset,
      maxOffset,
    };
  };

  const offsets = getMinMaxOffset(minValue, maxValue);

  useEffect(() => {
    if (TOD.isSuccess) {
      const filteredData = TOD.data.filter((item: GetTodResponse) => {
        // Convert time_of_day string to a Date object
        const time = new Date(`2022-01-01T${item.time_of_day}`);

        // Define the start and end times for filtering
        const startTime = new Date(`2022-01-01T09:00:00`);
        const endTime = new Date(`2022-01-01T15:00:00`);

        // Check if the time is after 9AM and before 3:30PM
        return time >= startTime && time <= endTime;
      });

      const modifiedData = addLabelToData(filteredData);

      if (modifiedData.every((el) => el.trades_cnt === 0)) {
        setIsApiError(true);
      }
      setTodData(modifiedData);
    }
  }, [TOD.data]);
  const chartOptions: ApexOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },

    plotOptions: {
      bar: {
        columnWidth: "40%",
        borderRadius: 4,
        dataLabels: {
          position: "top",
        },
        colors: {
          ranges: [
            {
              from: -10000000000000,
              to: 0,
              color: "#D43D29", // Red for negative values
            },
            {
              from: 0,
              to: 10000000000000,
              color: "#2A7517", // Green for positive values
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        if (Number(val) == 0) {
          return "";
        }
        return (
          `${Number(val) > 0 ? "" : "- "}` +
          "₹ " +
          formatRupees(Math.abs(Number(val)))
        );
      },
      offsetY: -30,
      style: {
        fontSize: "14px",
        colors: ["#FFFFFF"],
        fontFamily: "Source Sans Pro",
        fontWeight: 400,
      },
    },
    grid: {
      show: true,
      borderColor: "#434046",
      strokeDashArray: 2,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },

    yaxis: {
      min: offsets.minOffset,
      max: offsets.maxOffset,
      tickAmount: 4,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          if (Number(val) == 0) {
            return "";
          }
          return (
            `${Number(val) > 0 ? "" : "- "}` +
            "₹ " +
            formatRupees(Math.abs(Number(val)))
          );
        },
      },
    },
    xaxis: {
      categories: todData.map((el: GetTodResponse) => {
        return el.label;
      }),
      tooltip: {
        enabled: false,
      },
      labels: {
        show: true,

        style: {
          colors: "grey",
          fontSize: "14px",
          fontWeight: 400,
          fontFamily: "Source Sans Pro",
        },
        formatter: function (value, timestamp: number, opts) {
          return value;
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickPlacement: "between",
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          borderColor: "#fff",
          strokeDashArray: 0,
          label: {
            borderColor: "#00E396",
            style: {
              color: "#fff",
              background: "#00E396",
            },
          },
        },
      ],
    },
  };

  const chartSeries = [
    {
      name: "pnl",
      data: todData.map((el: GetTodResponse) => {
        return el.total_mtm == 0 ? null : Math.round(el.total_mtm);
      }),
      fillColor: "#EB8C87",
      strokeColor: "#C23829",
    },
  ];

  return (
    <CustomErrorBoundary
      isError={TOD.isError}
      isLoading={TOD.isLoading}
      error={TOD.error}
      isApiError={isApiError}
      childRef={childRef}
      customClassName="daily-error-msg top-[50%]"
      noData={todData.every((el) => el.trades_cnt === 0)}
    >
      <div id="chart" ref={childRef}>
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={350}
          width={"100%"}
        />
      </div>
    </CustomErrorBoundary>
  );
};

export default TodChart;
