import { UTCTimestamp } from "lightweight-charts";
import moment from "moment";
import {
  GetChartReplayResponse,
  GetOrdersResponse,
  GetOrdersResponseModified,
  SingleTrade,
  SingleTradeWithUTCTimestamp,
} from "@/module/dayLevelAnalysis/api/chartReplay";
import { TimeInterval } from "./CandleStickChart";
import { GetTodResponse } from "@/module/dashboard/interface/insightsInterface";

type colorType = {
  from: number;
  to: number;
  color: string;
};

interface EmotionData {
  emotion: string;
  trades_cnt: number;
  total_mtm: number;
  percentage: number;
}

export interface EmotionsMap {
  [key: string]: EmotionData;
}
export const generateColorArray = (): colorType[] => {
  const colorArray = [];

  // Define the color range mappings
  const colorRanges = [
    { from: -100, to: -21, color: "#8B0000" }, // Darkest red for -100
    { from: -20, to: 0, color: "#CA553B" },
    { from: 0, to: 20, color: "#FEA338" },
    { from: 21, to: 50, color: "#44AE20" },
    { from: 51, to: 100, color: "#006400" }, // Darkest green for +100
  ];

  // Iterate through the range from -100 to 100 and assign colors
  for (let value = -100; value <= 100; value++) {
    // Find the matching color range for the given value
    const matchingRange = colorRanges.find(
      (range) => value >= range.from && value <= range.to
    );

    if (matchingRange) {
      colorArray.push(matchingRange);
    }
  }

  return colorArray;
};

export const initialEmotionsMap: EmotionsMap = {
  happy: { emotion: "happy", trades_cnt: 0, total_mtm: 0, percentage: 0 },
  zen: { emotion: "zen", trades_cnt: 0, total_mtm: 0, percentage: 0 },
  panic: { emotion: "panic", trades_cnt: 0, total_mtm: 0, percentage: 0 },
  angry: { emotion: "angry", trades_cnt: 0, total_mtm: 0, percentage: 0 },
  sad: { emotion: "sad", trades_cnt: 0, total_mtm: 0, percentage: 0 },
  null: { emotion: "null", trades_cnt: 0, total_mtm: 0, percentage: 0 },
};

export const colorsArray = generateColorArray();

export const transformAndSortData = (
  data: GetChartReplayResponse,
  interval: TimeInterval
) => {
  const transformedData = data[interval].map((d: SingleTrade) => ({
    ...d,
    time: (moment(d.time).utcOffset(0, true).valueOf() / 1000) as UTCTimestamp,
  }));
  return transformedData.sort(
    (a: SingleTradeWithUTCTimestamp, b: SingleTradeWithUTCTimestamp) =>
      a.time - b.time
  );
};

export const sortOrdersData = ({
  data,
  interval,
  date,
}: {
  data: GetOrdersResponse[];
  interval: TimeInterval;
  date: string;
}) => {
  const transformedData: GetOrdersResponseModified[] = data.map(
    (d: GetOrdersResponse) => ({
      ...d,
      time: (moment(d.order_timestamp).utcOffset(0, true).valueOf() /
        1000) as UTCTimestamp,
    })
  );
  transformedData.sort(
    (a: GetOrdersResponseModified, b: GetOrdersResponseModified) =>
      a.time - b.time
  );
  const newOrders = generateSummaryForIntervals(
    transformedData,
    date,
    interval
  );
  return newOrders;
};

//Candlestick time interval

const sumQuantitiesBetweenTimes = (
  orders: GetOrdersResponseModified[],
  startTime: number,
  endTime: number,
  newTimestamp: string
) => {
  const filteredOrders = orders.filter((order: any) => {
    const orderTime = new Date(order.order_timestamp).getTime() / 1000; // Convert to seconds

    return orderTime >= startTime && orderTime < endTime;
  });
  if (filteredOrders.length === 0) {
    return;
  }

  const totalQty = filteredOrders.reduce((sum, order) => {
    const qty =
      order.transaction_type == "BUY"
        ? +Number(order.quantity)
        : -Number(order.quantity);
    return sum + qty;
  }, 0);

  const sumOfEntryTypeQts = filteredOrders.reduce((sum, order) => {
    const qty = order.transaction_type == "BUY" ? +Number(order.quantity) : 0;
    return sum + qty;
  }, 0);

  const result = {
    time: (moment(newTimestamp).utcOffset(0, true).valueOf() /
      1000) as UTCTimestamp as UTCTimestamp,
    totalQty: totalQty,
    entryType: filteredOrders[0].transaction_type,
    entryQty: sumOfEntryTypeQts,
  };

  return result;
};

export const generateSummaryForIntervals = (
  orders: GetOrdersResponseModified[],
  date: any,
  interval: string
) => {
  const startTime = new Date(`${date}T09:00:00`).getTime() / 1000; // Convert to seconds
  const endTime = new Date(`${date}T15:30:00`).getTime() / 1000; // Convert to seconds
  let intervalMinutes = 5;
  if (interval == "m1") {
    intervalMinutes = 1;
  } else if (interval == "m15") {
    intervalMinutes = 15;
  }
  const result = [];
  let currentTime = startTime;

  while (currentTime < endTime) {
    const endTimeForInterval = currentTime + intervalMinutes * 60;
    const newTimestamp = new Date(currentTime * 1000).toISOString();

    const summary = sumQuantitiesBetweenTimes(
      orders,
      currentTime,
      endTimeForInterval,
      newTimestamp
    );
    if (summary) {
      result.push(summary);
    }

    currentTime = endTimeForInterval;
  }

  return result;
};

export const addLabelToData = (data: GetTodResponse[]) => {
  return data.map((item: GetTodResponse) => {
    const startTime = formatStartTime(item.time_of_day);
    const endTime = add30Minutes(item.time_of_day);

    // Create a label based on the time interval
    const label = `${
      startTime.startsWith("09") ? startTime.substring(1) : startTime
    }-${endTime}`;
    return { ...item, label };
  });
};

// Function to add 30 minutes to a given time
const add30Minutes = (time: string) => {
  const [hours, minutes, seconds] = time.split(":").map(Number);
  const date = new Date();
  date.setHours(hours);

  date.setMinutes(minutes + 30);
  date.setSeconds(seconds);

  // Format the time with leading zeros if needed
  const formattedTime = `${String(date.getHours()).padStart(2, "0")}:${String(
    date.getMinutes()
  ).padStart(2, "0")}`;

  return formattedTime;
};
const formatStartTime = (time: string) => {
  const [hours, minutes, seconds] = time.split(":").map(Number);
  const date = new Date();
  date.setHours(hours);
  if (time == "09:00:00") {
    date.setMinutes(minutes + 15);
  } else {
    date.setMinutes(minutes);
  }
  date.setSeconds(seconds);

  // Format the time with leading zeros if needed
  const formattedTime = `${String(date.getHours()).padStart(2, "0")}:${String(
    date.getMinutes()
  ).padStart(2, "0")}`;

  return formattedTime;
};

export const defaultCandleStickSeriesData: {
  time: UTCTimestamp;
  tradingsymbol: string;
  open: number;
  high: number;
  low: number;
  close: number;
}[] = [
  {
    time: 1713777300 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 48145.7,
    high: 48146.3,
    low: 47859.6,
    close: 47894.4,
  },
  {
    time: 1713777600 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47908.4,
    high: 47928.2,
    low: 47824.4,
    close: 47873.3,
  },
  {
    time: 1713777900 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47876.6,
    high: 47924.2,
    low: 47846.6,
    close: 47910.2,
  },
  {
    time: 1713778200 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47909.8,
    high: 47910,
    low: 47811.1,
    close: 47826.4,
  },
  {
    time: 1713778500 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47824.9,
    high: 47879.8,
    low: 47801.8,
    close: 47832.3,
  },
  {
    time: 1713778800 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47832.4,
    high: 47839.5,
    low: 47745.8,
    close: 47790.4,
  },
  {
    time: 1713779100 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47792.7,
    high: 47792.7,
    low: 47710.9,
    close: 47720.7,
  },
  {
    time: 1713779400 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47721.4,
    high: 47760.1,
    low: 47671.2,
    close: 47753.2,
  },
  {
    time: 1713779700 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47753,
    high: 47767.9,
    low: 47695.4,
    close: 47748.6,
  },
  {
    time: 1713780000 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47752.2,
    high: 47805.3,
    low: 47746.3,
    close: 47798.4,
  },
  {
    time: 1713780300 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47795.9,
    high: 47814.8,
    low: 47677.2,
    close: 47743.3,
  },
  {
    time: 1713780600 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47740.8,
    high: 47777.2,
    low: 47723.1,
    close: 47753.6,
  },
  {
    time: 1713780900 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47755.6,
    high: 47783.8,
    low: 47718.5,
    close: 47756.2,
  },
  {
    time: 1713781200 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47757.9,
    high: 47777.4,
    low: 47712.4,
    close: 47715.9,
  },
  {
    time: 1713781500 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47717,
    high: 47723,
    low: 47676.3,
    close: 47695,
  },
  {
    time: 1713781800 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47693,
    high: 47703,
    low: 47628.4,
    close: 47668.9,
  },
  {
    time: 1713782100 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47665.1,
    high: 47696.8,
    low: 47662.9,
    close: 47694.6,
  },
  {
    time: 1713782400 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47694.9,
    high: 47714.2,
    low: 47669,
    close: 47711.7,
  },
  {
    time: 1713782700 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47711.8,
    high: 47767.1,
    low: 47700.2,
    close: 47751.4,
  },
  {
    time: 1713783000 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47755.1,
    high: 47764.8,
    low: 47728.4,
    close: 47753.8,
  },
  {
    time: 1713783300 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47754.1,
    high: 47804.7,
    low: 47745.1,
    close: 47782.6,
  },
  {
    time: 1713783600 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47780.2,
    high: 47802.9,
    low: 47763.3,
    close: 47773.6,
  },
  {
    time: 1713783900 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47774.1,
    high: 47835.2,
    low: 47754.3,
    close: 47818.2,
  },
  {
    time: 1713784200 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47822.4,
    high: 47842,
    low: 47804.4,
    close: 47828.2,
  },
  {
    time: 1713784500 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47828.9,
    high: 47842.6,
    low: 47806.8,
    close: 47832.4,
  },
  {
    time: 1713784800 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47832.3,
    high: 47845.4,
    low: 47787.7,
    close: 47802.4,
  },
  {
    time: 1713785100 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47799.6,
    high: 47838.2,
    low: 47798.1,
    close: 47828.3,
  },
  {
    time: 1713785400 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47831.7,
    high: 47839,
    low: 47810.6,
    close: 47816.2,
  },
  {
    time: 1713785700 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47817.8,
    high: 47859.1,
    low: 47814,
    close: 47850.9,
  },
  {
    time: 1713786000 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47849.9,
    high: 47856.2,
    low: 47837.4,
    close: 47850.3,
  },
  {
    time: 1713786300 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47851.6,
    high: 47855.5,
    low: 47803.9,
    close: 47810.6,
  },
  {
    time: 1713786600 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47811.1,
    high: 47859.2,
    low: 47809.4,
    close: 47846.1,
  },
  {
    time: 1713786900 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47849.6,
    high: 47882.7,
    low: 47839.6,
    close: 47877.7,
  },
  {
    time: 1713787200 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47879.9,
    high: 47886.4,
    low: 47858.1,
    close: 47862.9,
  },
  {
    time: 1713787500 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47863.9,
    high: 47876.8,
    low: 47850.1,
    close: 47854.8,
  },
  {
    time: 1713787800 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47854.9,
    high: 47862.4,
    low: 47838.9,
    close: 47843.4,
  },
  {
    time: 1713788100 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47842,
    high: 47852.4,
    low: 47813.4,
    close: 47840.3,
  },
  {
    time: 1713788400 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47840.8,
    high: 47850.7,
    low: 47827,
    close: 47827.5,
  },
  {
    time: 1713788700 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47829.9,
    high: 47843.9,
    low: 47814.9,
    close: 47836.8,
  },
  {
    time: 1713789000 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47837.9,
    high: 47846.6,
    low: 47760.9,
    close: 47808.1,
  },
  {
    time: 1713789300 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47806.2,
    high: 47830.3,
    low: 47801.4,
    close: 47816.5,
  },
  {
    time: 1713789600 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47818.6,
    high: 47825.6,
    low: 47792.6,
    close: 47814.7,
  },
  {
    time: 1713789900 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47818.3,
    high: 47820.4,
    low: 47794.4,
    close: 47808.4,
  },
  {
    time: 1713790200 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47808,
    high: 47808.9,
    low: 47757.4,
    close: 47767.6,
  },
  {
    time: 1713790500 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47767.9,
    high: 47786.4,
    low: 47760.6,
    close: 47779.2,
  },
  {
    time: 1713790800 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47782.7,
    high: 47798.6,
    low: 47771.4,
    close: 47778.6,
  },
  {
    time: 1713791100 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47778.1,
    high: 47795.2,
    low: 47753.2,
    close: 47794.1,
  },
  {
    time: 1713791400 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47793.9,
    high: 47802,
    low: 47744.4,
    close: 47763,
  },
  {
    time: 1713791700 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47762.4,
    high: 47790.4,
    low: 47762.4,
    close: 47788.1,
  },
  {
    time: 1713792000 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47788.8,
    high: 47818.6,
    low: 47774.3,
    close: 47815.5,
  },
  {
    time: 1713792300 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47809.4,
    high: 47810.8,
    low: 47780,
    close: 47780,
  },
  {
    time: 1713792600 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47780.6,
    high: 47811.9,
    low: 47766.7,
    close: 47805.1,
  },
  {
    time: 1713792900 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47805.1,
    high: 47838.9,
    low: 47805.1,
    close: 47830.3,
  },
  {
    time: 1713793200 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47830.4,
    high: 47844.4,
    low: 47813.8,
    close: 47815.5,
  },
  {
    time: 1713793500 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47814.2,
    high: 47847,
    low: 47805.4,
    close: 47829.7,
  },
  {
    time: 1713793800 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47829.6,
    high: 47859.6,
    low: 47818.7,
    close: 47848.1,
  },
  {
    time: 1713794100 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47849.3,
    high: 47852.4,
    low: 47822.6,
    close: 47830.2,
  },
  {
    time: 1713794400 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47830.8,
    high: 47873.1,
    low: 47829.9,
    close: 47852.8,
  },
  {
    time: 1713794700 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47855.2,
    high: 47882.4,
    low: 47834.1,
    close: 47864.9,
  },
  {
    time: 1713795000 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47866.4,
    high: 47866.4,
    low: 47837.1,
    close: 47840.4,
  },
  {
    time: 1713795300 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47840.1,
    high: 47853.1,
    low: 47813.7,
    close: 47843.2,
  },
  {
    time: 1713795600 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47843.7,
    high: 47857.6,
    low: 47830.6,
    close: 47838.7,
  },
  {
    time: 1713795900 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47840.4,
    high: 47843.6,
    low: 47811.1,
    close: 47815.3,
  },
  {
    time: 1713796200 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47816.2,
    high: 47848.1,
    low: 47785.6,
    close: 47793.8,
  },
  {
    time: 1713796500 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47793.9,
    high: 47850.6,
    low: 47782.6,
    close: 47834.2,
  },
  {
    time: 1713796800 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47835.4,
    high: 47879.2,
    low: 47827.9,
    close: 47879.2,
  },
  {
    time: 1713797100 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47880.6,
    high: 47947.4,
    low: 47861.6,
    close: 47923.8,
  },
  {
    time: 1713797400 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47925.4,
    high: 47958.4,
    low: 47902.6,
    close: 47914.9,
  },
  {
    time: 1713797700 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47919.2,
    high: 47932.4,
    low: 47864.8,
    close: 47870.4,
  },
  {
    time: 1713798000 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47869.9,
    high: 47884.9,
    low: 47841.4,
    close: 47867.9,
  },
  {
    time: 1713798300 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47869.9,
    high: 47898.6,
    low: 47856.4,
    close: 47894.4,
  },
  {
    time: 1713798600 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47893.7,
    high: 47926.1,
    low: 47892.5,
    close: 47908.6,
  },
  {
    time: 1713798900 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47910.4,
    high: 47962.6,
    low: 47907.6,
    close: 47936.8,
  },
  {
    time: 1713799200 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47936.4,
    high: 47942,
    low: 47902.5,
    close: 47915.6,
  },
  {
    time: 1713799500 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47917.1,
    high: 47981.1,
    low: 47913.3,
    close: 47959.4,
  },
  {
    time: 1713799800 as UTCTimestamp,
    tradingsymbol: "BANKNIFTY",
    open: 47958.3,
    high: 48009.6,
    low: 47956.9,
    close: 47999.9,
  },
];
