import Header from "@/components/Header";
import Layout from "@/components/Layout";
import "@/pages/tutorials/tutorials.css";

const Tutorials = () => {
  return (
    <Layout hideBg>
      <Header>{""}</Header>

      <div className="flex flex-col items-center justify-center gap-20 pb-20 px-9">
        <div className="demo-video-pageheading  mt-32">
          <h1 className="tutorials-heading">Demo Videos</h1>
        </div>

        <div className="demo-videos-content flex items-center justify-center gap-20 flex-wrap ">
          <div className="flex items-center justify-center video-and-title">
            <div className="video-title-over">
              <h1 className="video-heading">Sign Up process</h1>
              <p className="video-overview">
                In this video we have explained in detail the sign up procedure
                for a smooth onboarding.
              </p>
            </div>
            <iframe
              className="iframe-video"
              src="https://www.youtube.com/embed/U5wiQeXwapo"
            ></iframe>
          </div>
          <div className="flex items-center justify-center video-and-title">
            <div className="video-title-over">
              <h1 className="video-heading">Product Demonstration</h1>
              <p className="video-overview">
                This video will help you understand our product better and
                showcase the full potential of Betwizr.
              </p>
            </div>
            <iframe
              className="iframe-video"
              src="https://www.youtube.com/embed/8iXkFZOl0jA"
            ></iframe>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Tutorials;
