import {
  GetAdheranceResponse,
  getAdherance,
} from "@/module/dashboard/api/adherance";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { addDays, format } from "date-fns";
import GaugeChart from "react-gauge-chart";
import { DateRange } from "react-day-picker";
import { useAppSelector } from "@/store/reduxHooks";
import { CustomErrorBoundary } from "../errorBoundary/CustomErrorBoundary";
import { formatRupees } from "@/module/dayLevelAnalysis/components/ExitProtocolHistory/helper";

const getColor = (percentage: number) => {
  if (percentage >= 0 && percentage < 20) {
    return "#B62A14";
  } else if (percentage >= 20 && percentage < 40) {
    return "#A56516";
  } else if (percentage >= 40 && percentage < 60) {
    return "#949017";
  } else if (percentage >= 60 && percentage <= 80) {
    return "#5B8417";
  } else {
    return "#2A7517";
  }
};

const TradePlanDiscipline = ({ date }: { date: DateRange | undefined }) => {
  const userState = useAppSelector((state) => state.userState);
  const { brokerId, isAuthenticated, isGuidedFlowSeen } = userState;
  const [isApiError, setIsApiError] = useState(false);
  const childRef = useRef<any>(null);
  const adherance = useQuery({
    queryKey: ["adherance", date, brokerId, isAuthenticated],
    queryFn: () => {
      setIsApiError(false);
      return getAdherance({
        start_date: date?.from
          ? format(date.from, "yyyy-MM-dd")
          : format(addDays(new Date(), -7), "yyyy-MM-dd"),
        end_date: date?.to
          ? format(date.to, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        broker_id: brokerId,
      });
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && brokerId.length > 0 && isGuidedFlowSeen,
    retry: 0,
    onError: (error) => {
      setIsApiError(true);
    },
  });

  const [adherenceData, setAdherenceData] = useState<GetAdheranceResponse>({
    dr_na_ptg: 0,
    dr_na_max: 0,
    dr_na_tot_pnl: 0,
    avg_drl_value: 0,
    avg_drl_ptg: 0,
    avg_acc_size: 1,
    tc_na_ptg: 0,

    tc_na_max: 0,
    tc_na_tot_pnl: 0,
    avg_trades_pd: 0,
    drm_na_ptg: 0,
    drm_na_min: 0,
    drm_na_tot_pnl: 0,
    avg_drm: 0,
  });

  useEffect(() => {
    if (adherance.isSuccess && adherance.data) {
      const data = adherance.data;
      setAdherenceData(data[0]);
    }
  }, [adherance.data]);

  return (
    <CustomErrorBoundary
      isError={adherance.isError}
      isLoading={adherance.isLoading}
      error={adherance.error}
      isApiError={isApiError || adherenceData.drm_na_ptg == null}
      childRef={childRef}
      customClassName="daily-error-msg"
      noData={adherenceData.drm_na_ptg == null}
    >
      <table className="dashboard-tradeplandiscipline-table" ref={childRef}>
        <thead>
          <tr>
            <th></th>
            <th>Daily Risk</th>
            <th>Trade Limit</th>
            <th>Desired R Multiple</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="adherance-table-heading">
              <span>Adherence %</span>
              <span className="trade-plan-sub-text">
                No of times you followed your trade plan
              </span>
            </td>
            <td
              className="gauge-meter-one"
              style={{
                color: getColor(100 - adherenceData.dr_na_ptg),
                fontWeight: 700,
              }}
            >
              <GaugeChart
                cornerRadius={0}
                style={{ width: "fit-content" }}
                id="gauge-chart1"
                arcsLength={[0.2, 0.2, 0.2, 0.2, 0.2]}
                colors={["#b62a14", "#2a7517"]}
                nrOfLevels={5}
                arcWidth={0.3}
                percent={(100 - adherenceData.dr_na_ptg) / 100}
                hideText={true}
                needleColor={getColor(100 - adherenceData.dr_na_ptg)}
                needleBaseColor={getColor(100 - adherenceData.dr_na_ptg)}
              />
              {100 - adherenceData.dr_na_ptg}%
            </td>
            <td
              className="gauge-meter-one"
              style={{
                color: getColor(100 - adherenceData.tc_na_ptg),
                fontWeight: 700,
              }}
            >
              <GaugeChart
                cornerRadius={0}
                style={{ width: "fit-content" }}
                id="gauge-chart2"
                colors={["#b62a14", "#2a7517"]}
                nrOfLevels={5}
                arcWidth={0.3}
                percent={(100 - adherenceData.tc_na_ptg) / 100}
                hideText={true}
                needleColor={getColor(100 - adherenceData.tc_na_ptg)}
                needleBaseColor={getColor(100 - adherenceData.tc_na_ptg)}
              />
              {100 - adherenceData.tc_na_ptg}%
            </td>
            <td
              className="gauge-meter-one"
              style={{
                color: getColor(100 - adherenceData.drm_na_ptg),
                fontWeight: 700,
              }}
            >
              <GaugeChart
                cornerRadius={0}
                style={{ width: "fit-content" }}
                id="gauge-chart2"
                colors={["#b62a14", "#2a7517"]}
                nrOfLevels={5}
                arcWidth={0.3}
                percent={(100 - adherenceData.drm_na_ptg) / 100}
                hideText={true}
                needleColor={getColor(100 - adherenceData.drm_na_ptg)}
                needleBaseColor={getColor(100 - adherenceData.drm_na_ptg)}
              />
              {100 - adherenceData.drm_na_ptg}%
            </td>
          </tr>
          <tr>
            <td>
              <span>Max Value</span>
              <span className="trade-plan-sub-text">On Non-Adherance Days</span>
            </td>
            <td>
              {adherenceData?.dr_na_max && adherenceData?.dr_na_max < 0
                ? "-"
                : ""}
              ₹
              {adherenceData?.dr_na_max
                ? formatRupees(Math.abs(adherenceData.dr_na_max))
                : 0}
            </td>
            <td>
              {adherenceData.tc_na_max ? adherenceData.tc_na_max : 0} Trades
            </td>
            <td>—</td>
          </tr>
          <tr>
            <td>
              <span>P&L</span>
              <span className="trade-plan-sub-text">On Non-Adherance Days</span>
            </td>
            <td>
              {adherenceData?.dr_na_tot_pnl && adherenceData?.dr_na_tot_pnl < 0
                ? "-"
                : ""}
              ₹
              {adherenceData?.dr_na_tot_pnl
                ? formatRupees(Math.abs(adherenceData.dr_na_tot_pnl))
                : 0}
            </td>
            <td>
              {adherenceData?.tc_na_tot_pnl && adherenceData?.tc_na_tot_pnl < 0
                ? "-"
                : ""}
              ₹
              {adherenceData?.tc_na_tot_pnl
                ? formatRupees(Math.abs(adherenceData.tc_na_tot_pnl))
                : 0}
            </td>
            <td>
              {adherenceData?.drm_na_tot_pnl &&
              adherenceData?.drm_na_tot_pnl < 0
                ? "-"
                : ""}
              ₹
              {adherenceData?.drm_na_tot_pnl
                ? formatRupees(Math.abs(adherenceData.drm_na_tot_pnl))
                : 0}
            </td>
          </tr>
        </tbody>
      </table>
    </CustomErrorBoundary>
  );
};

export default TradePlanDiscipline;
