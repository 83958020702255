import { addDays, format, isAfter } from "date-fns";
import cashfireImg from "@/assets/dollarBurn.svg";
import cashDImg from "@/assets/dollar.svg";
import diamondImg from "@/assets/diamond (1) 1.svg";
import isolationMoon from "@/assets/Isolation_Mode.svg";
import bombfireImg from "@/assets/bombfire.svg";
import { DateRange } from "react-day-picker";
import { GetPnLTrendResponse } from "@/module/dashboard/interface/insightsInterface";

export type eventType = {
  title: string;
  subtitle: string;
  date: string;
  className: string;
  url: string;
  dayType: string;
  roi: number;
  charges: number;
  profit: number;
  tradeCount: number;
  showTooltip: boolean;
};

export type dayTypeData = {
  pnl: number;
  tradeCount: number;
  changePtg?: number;
};

export type changePLPtg = {
  winChange: number;
  lossChange: number;
  bigWinChange: number;
  bigLossChange: number;
};

export const getEvents = (
  sortedData: GetPnLTrendResponse[],
  dateRange: DateRange | undefined
): eventType[] => {
  const events: eventType[] = [];

  const addIsolationMoon = (date: Date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    const dayOfWeek = date.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6 && !isAfter(date, new Date())) {
      events.push({
        title: "",
        date: formattedDate,
        className: "isolationMoon",
        url: `${isolationMoon}`,
        dayType: "",
        subtitle: "",
        roi: 0,
        charges: 0,
        profit: 0,
        tradeCount: 0,
        showTooltip: false,
      });
    }
  };

  const addEvent = (el: GetPnLTrendResponse) => {
    const formattedDate = format(new Date(el.trading_day), "yyyy-MM-dd");
    const dayOfWeek = new Date(formattedDate).getDay();

    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      events.push({
        title: `${el.tot_mtm}`,
        subtitle: `${el.trades_cnt}`,
        date: el.trading_day,
        className:
          el.day_type === "LD"
            ? "dollarBurn"
            : el.day_type === "WD"
            ? "dollar"
            : el.day_type === "BWD"
            ? "diamond"
            : el.day_type === "BLD"
            ? "bombicon"
            : "",
        url:
          el.day_type === "LD"
            ? `${cashfireImg}`
            : el.day_type === "WD"
            ? `${cashDImg}`
            : el.day_type === "BWD"
            ? `${diamondImg}`
            : el.day_type === "BLD"
            ? `${bombfireImg}`
            : "",
        dayType: el.day_type,
        roi: el.tot_roi,
        charges: el.tot_charges,
        profit: el.tot_mtm,
        tradeCount: el.trades_cnt,
        showTooltip: true,
      });
    }
  };

  if (dateRange && dateRange.from && dateRange.to) {
    const daysDifference =
      dateRange.to.getDate() - dateRange.from.getDate() + 1;
    let k = 0;
    let j = 0;
    for (let i = 1; i <= daysDifference; i++) {
      if (k < sortedData.length) {
        j = new Date(sortedData[k].trading_day).getDate();
      }
      if (j == i) {
        addEvent(sortedData[k]);
        k++;
      } else {
        addIsolationMoon(addDays(dateRange.from, i - 1));
      }
    }
  }

  return events;
};

export const getWinLossDays = (
  data: GetPnLTrendResponse[],
  dayType: string
): dayTypeData => {
  const dayTypeData: dayTypeData = data
    .filter((el: GetPnLTrendResponse) => el.day_type == dayType)
    .reduce(
      (
        accumulator: { pnl: number; tradeCount: number },
        el: GetPnLTrendResponse
      ) => {
        accumulator.pnl += el.tot_mtm;
        accumulator.tradeCount += el.trades_cnt;
        return accumulator;
      },
      { pnl: 0, tradeCount: 0 }
    );

  return dayTypeData;
};

export const getChangePtg = (
  winDay: dayTypeData | null,
  prevWinDay: dayTypeData | null,
  bigWinDay: dayTypeData | null,
  prevBigWinDay: dayTypeData | null,
  lossDay: dayTypeData | null,
  prevLossDay: dayTypeData | null,
  bigLossDay: dayTypeData | null,
  prevBigLossDay: dayTypeData | null
): changePLPtg => {
  const obj: changePLPtg = {
    winChange: 0,
    lossChange: 0,
    bigWinChange: 0,
    bigLossChange: 0,
  };
  if (winDay && prevWinDay) {
    if (prevWinDay.pnl == 0) {
      obj.winChange = 0;
    } else {
      obj.winChange =
        ((winDay?.pnl - prevWinDay?.pnl) /
          (prevWinDay.pnl == 0 ? 1 : prevWinDay.pnl)) *
        100;
    }
  }

  if (lossDay && prevLossDay) {
    if (prevLossDay?.pnl == 0) {
      obj.lossChange = 0;
    } else {
      obj.lossChange =
        ((lossDay?.pnl - prevLossDay?.pnl) /
          (prevLossDay.pnl == 0 ? 1 : prevLossDay.pnl)) *
        100;
    }
  }

  if (bigWinDay && prevBigWinDay) {
    if (prevBigWinDay.pnl == 0) {
      obj.bigWinChange = 0;
    } else {
      obj.bigWinChange =
        ((bigWinDay?.pnl - prevBigWinDay?.pnl) /
          (prevBigWinDay.pnl == 0 ? 1 : prevBigWinDay.pnl)) *
        100;
    }
  }
  if (bigLossDay && prevBigLossDay) {
    if (prevBigLossDay.pnl == 0) {
      obj.bigLossChange = 0;
    } else {
      obj.bigLossChange =
        ((bigLossDay?.pnl - prevBigLossDay?.pnl) /
          (prevBigLossDay.pnl == 0 ? 1 : prevBigLossDay.pnl)) *
        100;
    }
  }

  return obj;
};

export const convertSeconds = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let result = "";

  if (hours > 0) {
    result += `${hours}hr `;
  }
  if (minutes > 0) {
    result += `${minutes}m `;
  }
  if (remainingSeconds > 0 || result === "") {
    result += `${remainingSeconds}s`;
  }

  return result.trim();
};
