import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useQuery } from "react-query";
import { getPnLTrend } from "@/module/dashboard/api/insights";
import { addDays, format } from "date-fns";
import { DateRange } from "react-day-picker";
import { GetPnLTrendResponse } from "@/module/dashboard/interface/insightsInterface";
import { useAppDispatch, useAppSelector } from "@/store/reduxHooks";
import { formatRupees } from "@/module/dayLevelAnalysis/components/ExitProtocolHistory/helper";
import moment from "moment";
import { CustomErrorBoundary } from "../errorBoundary/CustomErrorBoundary";
import { useNavigate } from "react-router-dom";
import { setSingleDate } from "@/pages/auth/authSlice";

const PLGrowthChartCummulative = ({
  date,
}: {
  date: DateRange | undefined;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userState = useAppSelector((state) => state.userState);
  const { isAuthenticated, brokerId, isGuidedFlowSeen } = userState;
  const [isApiError, setIsApiError] = useState(false);
  const childRef = useRef<any>(null);
  const [pnlData, setPnlData] = useState<GetPnLTrendResponse[]>([]);
  const pnLTrend = useQuery({
    queryKey: ["pnLTrend", date, brokerId, isAuthenticated],
    queryFn: () => {
      setIsApiError(false);
      return getPnLTrend({
        start_date: date?.from
          ? format(date.from, "yyyy-MM-dd")
          : format(addDays(new Date(), -7), "yyyy-MM-dd"),
        end_date: date?.to
          ? format(date.to, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        broker_id: brokerId,
      });
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && brokerId.length > 0 && isGuidedFlowSeen,
    retry: 0,
    onError: (error) => {
      setIsApiError(true);
    },
  });

  useEffect(() => {
    if (pnLTrend.isSuccess) {
      const sortedData = pnLTrend.data.sort(
        (a: GetPnLTrendResponse, b: GetPnLTrendResponse) => {
          return (
            new Date(a.trading_day).getTime() -
            new Date(b.trading_day).getTime()
          );
        }
      );
      setPnlData(sortedData);
      if (sortedData.length == 0) {
        setIsApiError(true);
      }
    }
  }, [pnLTrend.data]);

  const minValue = Math.min(
    ...pnlData.map((el: GetPnLTrendResponse) => {
      return el.running_tot_mtm;
    })
  );

  const maxValue = Math.max(
    ...pnlData.map((el: GetPnLTrendResponse) => {
      return el.running_tot_mtm;
    })
  );

  const getMinMaxOffset = (minValue: number, maxValue: number) => {
    let minOffset = Math.round(minValue + 0.1 * minValue);
    let maxOffset = Math.round(maxValue + 0.1 * maxValue);
    if (maxValue > 0 && minValue > 0) {
      minOffset = -Math.round(minValue);
      maxOffset = Math.round(maxValue + 0.1 * maxValue);
    }
    if (maxValue < 0 && minValue < 0) {
      minOffset = Math.round(minValue + 0.1 * minValue);
      maxOffset = -Math.round(minValue);
    }

    return {
      minOffset,
      maxOffset,
    };
  };

  const offsets = getMinMaxOffset(minValue, maxValue);

  const options: ApexOptions = {
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
      events: {
        dataPointMouseEnter: function (event) {
          event.srcElement.style.cursor = "pointer";
        },
        dataPointSelection: function (
          event,
          chartContext,
          { seriesIndex, dataPointIndex, config }
        ) {
          if (seriesIndex !== undefined && dataPointIndex !== undefined) {
            var timestamp =
              chartContext.data.w.globals.categoryLabels[dataPointIndex];
            const originalDate = moment(timestamp, "DD/MM").toDate();

            setTimeout(() => {
              dispatch(setSingleDate(originalDate.toISOString()));
              navigate("/day-level-analysis");
            }, 10);
          }
        },
      },
    },
    markers: {
      size: 5,
    },
    plotOptions: {
      area: {
        fillTo: "end",
      },
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "round",
      colors: ["#FEA338"],
      width: 2,
      dashArray: 0,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        if (Number(val) == 0) {
          return "";
        }
        return (
          `${Number(val) > 0 ? "" : "-"}` +
          "₹ " +
          formatRupees(Math.abs(Number(val)))
        );
      },
      offsetY: -10,
      textAnchor: "middle",
      style: {
        fontSize: "14px",
        colors: ["#FFFFFF"],
        fontFamily: "Source Sans Pro",
        fontWeight: 400,
      },
      dropShadow: {
        enabled: false,
      },
      background: {
        enabled: false,
      },
    },

    colors: ["#FBA137"],
    fill: {
      gradient: {
        opacityFrom: 0.55,
        opacityTo: 0,
      },
    },

    grid: {
      borderColor: "#FFFFFF59",
      strokeDashArray: 2,
      show: true, // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: true, //or just here to disable only x axis grids
        },
      },
      yaxis: {
        lines: {
          show: false, //or just here to disable only y axis
        },
      },
      padding: {
        top: 5,
        right: 50,
        bottom: 5,
        left: 5,
      },
      position: "front",
    },
    xaxis: {
      categories: pnlData.map((el: GetPnLTrendResponse) => {
        return el.trading_day;
      }),
      tooltip: {
        enabled: false,
      },
      offsetY: 20,
      position: "bottom",
      axisBorder: {
        show: false,
      },
      labels: {
        hideOverlappingLabels: true,
        trim: true,
        style: {
          colors: "#FFFFFF",
          fontSize: "14px",
          fontWeight: 400,
          fontFamily: "Source Sans Pro",
        },
        formatter: function (value, timestamp: number, opts) {
          return moment(value).format("DD/M");
        },
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: offsets.minOffset,
      max: offsets.maxOffset,
      tickAmount: 6,
      labels: {
        formatter: function (val) {
          return (
            `${Number(val) > 0 ? "" : "-"}` +
            "₹ " +
            formatRupees(Math.abs(Number(val)))
          );
        },
        align: "right",
        minWidth: 100,
        maxWidth: 140,
        style: {
          colors: "#FFFFFF",
          fontSize: "14px",
          fontWeight: 400,
          fontFamily: "Source Sans Pro",
        },
        offsetX: -25,
      },
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      shared: false,
      intersect: true,
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          strokeDashArray: 0,
          borderColor: "#fff",
        },
      ],
    },
  };

  const series = [
    {
      name: "pnl",
      data: pnlData.map((el: GetPnLTrendResponse) => {
        return Math.round(el.running_tot_mtm);
      }),
    },
  ];

  return (
    <CustomErrorBoundary
      isError={pnLTrend.isError}
      isLoading={pnLTrend.isLoading}
      error={pnLTrend.error}
      isApiError={isApiError}
      childRef={childRef}
      customClassName="cumulative-error-msg top-[40%] h-[90%]"
      noData={pnlData.length == 0}
    >
      <div id="chart" ref={childRef}>
        <Chart
          options={options}
          series={series}
          type="area"
          height={350}
          width={"100%"}
        />
      </div>
    </CustomErrorBoundary>
  );
};

export default PLGrowthChartCummulative;
