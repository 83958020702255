import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { getPnL, getPnLTrend } from "@/module/dashboard/api/insights";
import { CalendarApi, EventClickArg } from "@fullcalendar/core";
import {
  eventType,
  getEvents,
  getWinLossDays,
  dayTypeData,
  getChangePtg,
  changePLPtg,
} from "@/module/dayLevelAnalysis/components/helper";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import cashfireImg from "@/assets/dollarBurn.svg";
import cashDImg from "@/assets/dollar.svg";
import bombfireImg from "@/assets/bombfire.svg";
import diamondImg from "@/assets/diamond (1) 1.svg";
import upmeterImg from "@/assets/upmeter.png";
import downWinMeterImg from "@/assets/downWinMeter.svg";
import downLossMeterImg from "@/assets/downLossMeter.svg";
import { addDays, format } from "date-fns";
import { DateRange } from "react-day-picker";
import { emDash } from "@/utils/constants";
import renderEventContent from "@/module/dayLevelAnalysis/components/RenderEventContent";
import {
  GetPnLResponse,
  GetPnLTrendResponse,
} from "@/module/dashboard/interface/insightsInterface";
import { useAppDispatch, useAppSelector } from "@/store/reduxHooks";
import { formatRupees } from "./ExitProtocolHistory/helper";
import { Button } from "@/components/ui/button";
import lossUpmeterIcon from "@/assets/lossUpmeterIcon.svg";
import { useDebounce } from "use-debounce";
import { CustomErrorBoundary } from "@/components/errorBoundary/CustomErrorBoundary";
import moment from "moment";
import { setSingleDate } from "@/pages/auth/authSlice";
import { Tooltip } from "react-tooltip";
import infoIcon from "@/assets/InfoIcon.svg";

const CalenderPage = () => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.userState);
  const { brokerId, isAuthenticated, singleDate, isGuidedFlowSeen } = userState;
  const modifiedDate = moment(singleDate).toDate();

  const [isApiError1, setIsApiError1] = useState(false);
  const [isApiError2, setIsApiError2] = useState(false);
  const childRef = useRef<any>(null);
  const sideUpperRef = useRef<any>(null);
  const sideLowerRef = useRef<any>(null);

  const calenderRef = useRef<any>();
  const [calender, setCalender] = useState<CalendarApi>();
  const [calenderDateRange, setCalenderDateRange] = useState<
    DateRange | undefined
  >();
  const [selectedButton, setSelectedButton] = useState("all"); // Default to 'All' button

  const [debouncedCalenderDateRange] = useDebounce(calenderDateRange, 500);

  const [prevMonthCalenderDateRange, setPrevMonthCalenderDateRange] = useState<
    DateRange | undefined
  >();

  const [debouncedPrevMonthCalenderDateRange] = useDebounce(
    prevMonthCalenderDateRange,
    500
  );

  const [winDay, setWinDay] = useState<dayTypeData>({
    pnl: 0,
    tradeCount: 0,
  });
  const [bigWinDay, setBigWinDay] = useState<dayTypeData>({
    pnl: 0,
    tradeCount: 0,
  });
  const [lossDay, setLossDay] = useState<dayTypeData>({
    pnl: 0,
    tradeCount: 0,
  });
  const [bigLossDay, setBigLossDay] = useState<dayTypeData>({
    pnl: 0,
    tradeCount: 0,
  });

  const pnL = useQuery({
    queryKey: ["pnL", debouncedCalenderDateRange, brokerId, isAuthenticated],
    queryFn: () => {
      setIsApiError1(false);

      return getPnL({
        start_date: debouncedCalenderDateRange?.from
          ? format(debouncedCalenderDateRange.from, "yyyy-MM-dd")
          : format(addDays(new Date(), -7), "yyyy-MM-dd"),
        end_date: debouncedCalenderDateRange?.to
          ? format(debouncedCalenderDateRange.to, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        broker_id: brokerId,
      });
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && brokerId.length > 0 && isGuidedFlowSeen,
    retry: 0,
    onError: (error) => {
      setIsApiError1(true);
    },
  });

  const pnLTrend = useQuery({
    queryKey: [
      "pnLTrend",
      debouncedCalenderDateRange,
      brokerId,
      isAuthenticated,
    ],
    queryFn: () => {
      setIsApiError2(false);
      return getPnLTrend({
        start_date: debouncedCalenderDateRange?.from
          ? format(debouncedCalenderDateRange.from, "yyyy-MM-dd")
          : format(addDays(new Date(), -7), "yyyy-MM-dd"),
        end_date: debouncedCalenderDateRange?.to
          ? format(debouncedCalenderDateRange.to, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        broker_id: brokerId,
      });
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && brokerId.length > 0 && isGuidedFlowSeen,
    retry: 0,
    onError: (error) => {
      setIsApiError2(true);
    },
  });

  const pnLTrend2 = useQuery({
    queryKey: [
      "pnLTrend2",
      debouncedPrevMonthCalenderDateRange,
      brokerId,
      isAuthenticated,
    ],
    queryFn: () =>
      getPnLTrend({
        start_date: debouncedPrevMonthCalenderDateRange?.from
          ? format(debouncedPrevMonthCalenderDateRange.from, "yyyy-MM-dd")
          : format(addDays(new Date(), -7), "yyyy-MM-dd"),
        end_date: debouncedPrevMonthCalenderDateRange?.to
          ? format(debouncedPrevMonthCalenderDateRange.to, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        broker_id: brokerId,
      }),
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && brokerId.length > 0 && isGuidedFlowSeen,
    retry: 0,
  });

  const [pnlData, setPnlData] = useState<GetPnLResponse>({
    trades_count: 0,
    total_points: 0,
    total_mtm: 0,
    roi: 0,
    total_charges: 0,
    total_charges_ptg: 0,
    avg_loss: 0,
    avg_win: 0,
    r_r: 1,
    total_win: 0,
    total_loss: 0,
    profit_factor: 0,
    win_count: 0,
    loss_count: 0,
    win_rate: 0,
  });

  useEffect(() => {
    if (pnL.isSuccess) {
      setPnlData(pnL.data[0]);
    }
  }, [pnL.data]);

  const [events, setEvents] = useState<eventType[]>([]);
  const [sortedEvents, setSortedEvents] = useState<eventType[]>([]);

  useEffect(() => {
    if (pnLTrend.isSuccess) {
      const sortedData: GetPnLTrendResponse[] = pnLTrend.data.sort(
        (a: GetPnLTrendResponse, b: GetPnLTrendResponse) => {
          return (
            new Date(a.trading_day).getTime() -
            new Date(b.trading_day).getTime()
          );
        }
      );
      const createEvents: eventType[] = getEvents(
        sortedData,
        calenderDateRange
      );

      handleSetEvents(selectedButton, createEvents);
      setSortedEvents(createEvents);
    }
  }, [pnLTrend.data]);

  const [ptgChange, setPtgChange] = useState<changePLPtg>();
  useEffect(() => {
    if (
      pnLTrend2.isSuccess &&
      pnLTrend.isSuccess &&
      pnLTrend2.data &&
      pnLTrend.data
    ) {
      const sortedData: GetPnLTrendResponse[] = pnLTrend.data.sort(
        (a: GetPnLTrendResponse, b: GetPnLTrendResponse) => {
          return (
            new Date(a.trading_day).getTime() -
            new Date(b.trading_day).getTime()
          );
        }
      );

      const prevSortedData: GetPnLTrendResponse[] = pnLTrend2.data.sort(
        (a: GetPnLTrendResponse, b: GetPnLTrendResponse) => {
          return (
            new Date(a.trading_day).getTime() -
            new Date(b.trading_day).getTime()
          );
        }
      );

      // current Month

      const winDayData: dayTypeData = getWinLossDays(sortedData, "WD");
      const bigWinDayData: dayTypeData = getWinLossDays(sortedData, "BWD");
      const lossDayData: dayTypeData = getWinLossDays(sortedData, "LD");
      const biglossDayData: dayTypeData = getWinLossDays(sortedData, "BLD");

      // prev Month

      const prevWinDay: dayTypeData = getWinLossDays(prevSortedData, "WD");
      const prevBigWinDay: dayTypeData = getWinLossDays(prevSortedData, "BWD");
      const prevlossDay: dayTypeData = getWinLossDays(prevSortedData, "LD");
      const prevBiglossDay: dayTypeData = getWinLossDays(prevSortedData, "BLD");

      const changePtgs: changePLPtg = getChangePtg(
        winDayData,
        prevWinDay,
        bigWinDayData,
        prevBigWinDay,
        lossDayData,
        prevlossDay,
        biglossDayData,
        prevBiglossDay
      );
      setPtgChange(changePtgs);
      setWinDay(winDayData);
      setBigWinDay(bigWinDayData);
      setLossDay(lossDayData);
      setBigLossDay(biglossDayData);
    }
  }, [pnLTrend2.data, pnLTrend.data]);

  const handleDateClick = (value: Date | null | undefined) => {
    if (!value) {
      return;
    }
    const firstDayOfMonth = new Date(value.getFullYear(), value.getMonth(), 1);
    const lastDayOfMonth = new Date(
      value.getFullYear(),
      value.getMonth() + 1,
      0
    );

    // Get the first day of the previous month
    const firstDayOfPrevMonth = new Date(
      value.getFullYear(),
      value.getMonth() - 1,
      1
    );

    // Get the last day of the previous month
    const lastDayOfPrevMonth = new Date(
      value.getFullYear(),
      value.getMonth(),
      0
    );

    if (
      firstDayOfMonth &&
      lastDayOfMonth &&
      firstDayOfPrevMonth &&
      lastDayOfPrevMonth
    ) {
      setCalenderDateRange({
        from: firstDayOfMonth,
        to: lastDayOfMonth,
      });
      setPrevMonthCalenderDateRange({
        from: firstDayOfPrevMonth,
        to: lastDayOfPrevMonth,
      });
    }
  };

  useEffect(() => {
    if (calenderRef.current) {
      const calender: CalendarApi = calenderRef.current.getApi();
      setCalender(calender);
    }
  }, []);

  useEffect(() => {
    if (singleDate !== undefined && calender) {
      handleDateClick(modifiedDate);
      calender?.gotoDate(singleDate);
    }
  }, [calender, singleDate]);

  const [tooltipData, setTooltipData] = useState({
    message: {
      charges: 0,
      profit: 0,
      roi: 0,
      tradeCount: 0,
      showTooltip: false,
    },
    x: 0,
    y: 0,
    isVisible: false,
    targetElemWidth: 0,
  });

  const handleButtonClick = (type: string, events: any) => {
    setSelectedButton(type);
    handleSetEvents(type, events);
  };

  const handleSetEvents = (type: string, events: any) => {
    switch (type) {
      case "all":
        setEvents(events);
        break;
      case "wins":
        const winEvents = events.filter(
          (el: eventType) => el.dayType === "WD" || el.dayType === "BWD"
        );
        setEvents(winEvents);
        break;
      case "losses":
        const lossEvents = events.filter(
          (el: eventType) => el.dayType === "LD" || el.dayType === "BLD"
        );
        setEvents(lossEvents);
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="tradeCalender displayFlexCentre padding-top-main-content"
      id="trade-calender"
    >
      <div className="yourCalender displayFlexCentre">
        <div
          className="calenderLogoText"
          id="day-level-analysis-calender-guide"
        >
          <h1 className="flex items-center gap-2">
            📅 Your Calendar{" "}
            <a
              data-tooltip-id="calender-tooltip-border"
              className="cursor-pointer"
            >
              <img className="w-[16px]" src={infoIcon} alt="" />
            </a>
            <Tooltip
              className="calender-page-header-tooltip"
              id="calender-tooltip-border"
              border={"1px solid #ffffff67"}
              content="Visual overview of your trading month"
            />
          </h1>
        </div>
      </div>
      <div className="tradeDates">
        <div className="tradeDatesLeft">
          <CustomErrorBoundary
            isError={pnLTrend.isError}
            isLoading={pnLTrend.isLoading}
            error={pnLTrend.error}
            isApiError={isApiError2}
            childRef={childRef}
          >
            <div ref={childRef}>
              <div className="calen-win-loss-all-btn">
                <Button
                  variant={"outline"}
                  onClick={() => {
                    handleButtonClick("all", sortedEvents);
                  }}
                  style={{
                    backgroundColor:
                      selectedButton == "all" ? "#FEA338" : "#34291c",
                    color: selectedButton == "all" ? "#181818" : "",
                  }}
                >
                  All
                </Button>
                <Button
                  variant={"outline"}
                  onClick={() => {
                    handleButtonClick("wins", sortedEvents);
                  }}
                  style={{
                    backgroundColor:
                      selectedButton == "wins" ? "#FEA338" : "#34291c",
                    color: selectedButton == "wins" ? "#181818" : "",
                  }}
                >
                  Wins
                </Button>
                <Button
                  variant={"outline"}
                  onClick={() => {
                    handleButtonClick("losses", sortedEvents);
                  }}
                  style={{
                    backgroundColor:
                      selectedButton == "losses" ? "#FEA338" : "#34291c",
                    color: selectedButton == "losses" ? "#181818" : "",
                  }}
                >
                  Loses
                </Button>
              </div>
              <FullCalendar
                eventMouseEnter={(mouseEnterInfo) => {
                  const { charges, profit, roi, tradeCount, showTooltip } =
                    mouseEnterInfo.event.extendedProps;
                  const boundingRect =
                    mouseEnterInfo.el.getBoundingClientRect();
                  setTooltipData({
                    isVisible: true,
                    message: { charges, profit, roi, tradeCount, showTooltip },
                    x: boundingRect.x,
                    y: boundingRect.y,
                    targetElemWidth: boundingRect.width,
                  });
                }}
                eventMouseLeave={() => {
                  setTooltipData({
                    isVisible: false,
                    message: {
                      charges: 0,
                      profit: 0,
                      roi: 0,
                      tradeCount: 0,
                      showTooltip: false,
                    },
                    x: 0,
                    y: 0,
                    targetElemWidth: 0,
                  });
                }}
                ref={calenderRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                headerToolbar={{
                  left: "prev,next",
                  center: "title",
                  right: "",
                }}
                events={events}
                eventContent={renderEventContent}
                eventClick={(info: EventClickArg) => {
                  info.jsEvent.preventDefault();
                  info.jsEvent.stopPropagation();
                  if (info.event.title.length > 0) {
                    dispatch(
                      setSingleDate(
                        info.event.start
                          ? info.event.start.toISOString()
                          : singleDate
                      )
                    );
                  }

                  handleDateClick(info.event.start);
                }}
                dateClick={(value) => {
                  handleDateClick(value.date);
                  calender?.gotoDate(value.date);
                }}
                datesSet={(dates) => {
                  const startDate = new Date(dates.start);
                  const endDate = new Date(dates.end);
                  const midDate = new Date(
                    (startDate.getTime() + endDate.getTime()) / 2
                  );
                  handleDateClick(midDate);
                }}
              />
            </div>
          </CustomErrorBoundary>
        </div>
        {tooltipData.isVisible && tooltipData.message.showTooltip && (
          <div
            className="daylevel-analysis-calendar-tooltip"
            style={{
              top: tooltipData.y,
              left: tooltipData.targetElemWidth / 2 + tooltipData.x,
            }}
          >
            <p>ROI: {tooltipData.message.roi}</p>
            <p>Charges: {formatRupees(tooltipData.message.charges)}</p>
            <p>Profit: {formatRupees(tooltipData.message.profit)}</p>
            <p>No. of trades: {tooltipData.message.tradeCount}</p>
          </div>
        )}
        <div className="tradeDatesRight">
          <CustomErrorBoundary
            isError={pnL.isError}
            isLoading={pnL.isLoading}
            error={pnL.error}
            isApiError={isApiError1}
            childRef={sideUpperRef}
          >
            <div className="rightOne" ref={sideUpperRef}>
              <div className="dateRightOne">
                <div className="roi displayFlexCentre">
                  <h3>ROI</h3>
                  <h2>{pnlData?.roi ? `${pnlData?.roi}%` : emDash}</h2>
                  {}{" "}
                </div>

                <div className="roi displayFlexCentre">
                  <h3>Charges</h3>
                  <h2>
                    {pnlData?.total_charges
                      ? `₹${formatRupees(pnlData?.total_charges)}`
                      : emDash}
                  </h2>
                </div>
              </div>
              <div className="dateRightTwo ">
                <div className="roi displayFlexCentre">
                  <h3>
                    {pnlData?.total_mtm && pnlData?.total_mtm < 0
                      ? "Loss"
                      : "Profit"}
                  </h3>
                  <h2>
                    {pnlData?.total_mtm
                      ? `${pnlData?.total_mtm > 0 ? "" : "-"} ₹${formatRupees(
                          Math.abs(pnlData?.total_mtm)
                        )}`
                      : emDash}
                  </h2>
                </div>

                <div className="roi displayFlexCentre">
                  <h3>No of Trades.</h3>
                  <h2>
                    {pnlData?.trades_count ? pnlData?.trades_count : emDash}
                  </h2>
                </div>
              </div>
            </div>
          </CustomErrorBoundary>

          <CustomErrorBoundary
            isError={pnLTrend.isError}
            isLoading={pnLTrend.isLoading}
            error={pnLTrend.error}
            isApiError={isApiError2}
            childRef={sideLowerRef}
            customClassName="daylevel-roi-error-cont"
          >
            <div
              className="rightTwo"
              ref={sideLowerRef}
              id="day-level-analysis-calendar-sidebar-guide"
            >
              <div className="dateRightThree">
                <div className="winDays displayFlexCentre">
                  <div className="imgPer">
                    <img className="winlossIndicator" src={cashDImg} alt="" />
                  </div>
                  {ptgChange && ptgChange?.winChange !== 0 && (
                    <div className="upmeter winupmeter displayFlexCentre">
                      <h3
                        style={{
                          color:
                            ptgChange?.winChange > 0 ? "#9ff975" : "#ff5858",
                        }}
                      >
                        {ptgChange?.winChange !== 0
                          ? `${ptgChange?.winChange.toFixed()}%`
                          : ""}
                      </h3>

                      {ptgChange?.winChange > 0 ? (
                        <img src={upmeterImg} alt="" />
                      ) : (
                        <img src={downLossMeterImg} alt="" />
                      )}
                    </div>
                  )}

                  <div className="winlossDynamicdata">
                    <h1
                      style={{
                        color: "#44BC59",
                        fontSize: "22px",
                        fontWeight: 600,
                      }}
                    >
                      Win Days
                    </h1>
                    <h2
                      style={{
                        color: "#fff",
                        fontSize: "22px",
                        fontWeight: 600,
                      }}
                    >
                      ₹{winDay.pnl != 0 ? formatRupees(winDay.pnl) : emDash}
                    </h2>
                    <p
                      style={{
                        color: "#9FF975",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      {winDay.tradeCount} Trades
                    </p>
                  </div>
                </div>
                <div
                  className="winDays displayFlexCentre"
                  style={{ border: "2px solid #44bc59" }}
                >
                  <div className="imgPer">
                    <img className="winlossIndicator" src={diamondImg} alt="" />
                  </div>

                  {ptgChange && ptgChange.bigWinChange !== 0 && (
                    <div className="upmeter winupmeter displayFlexCentre">
                      <h3
                        style={{
                          color:
                            ptgChange?.bigWinChange > 0 ? "#9ff975" : "#ff5858",
                        }}
                      >
                        {ptgChange?.bigWinChange.toFixed()}%
                      </h3>
                      {ptgChange?.bigWinChange > 0 ? (
                        <img src={upmeterImg} alt="" />
                      ) : (
                        <img src={downLossMeterImg} alt="" />
                      )}
                    </div>
                  )}
                  <div className="winlossDynamicdata">
                    <h1
                      style={{
                        color: "#44BC59",
                        fontSize: "22px",
                        fontWeight: 600,
                      }}
                    >
                      Big Win Days
                    </h1>
                    <h2
                      style={{
                        color: "#fff",
                        fontSize: "22px",
                        fontWeight: 600,
                      }}
                    >
                      ₹
                      {bigWinDay.pnl == 0
                        ? emDash
                        : formatRupees(bigWinDay.pnl)}
                    </h2>
                    <p
                      style={{
                        color: "#9FF975",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      {bigWinDay.tradeCount} Trades
                    </p>
                  </div>
                </div>
              </div>
              <div className="dateRightFour">
                <div className="lossDays displayFlexCentre">
                  <div className="imgPer">
                    <img
                      className="winlossIndicator"
                      src={cashfireImg}
                      alt=""
                    />
                  </div>

                  {ptgChange && ptgChange.lossChange !== 0 && (
                    <div className="upmeter lossupmeter displayFlexCentre">
                      <h3
                        style={{
                          color:
                            ptgChange?.lossChange < 0 ? "#9ff975" : "#ff5858",
                        }}
                      >
                        {ptgChange?.lossChange.toFixed()}%
                      </h3>
                      {ptgChange?.lossChange < 0 ? (
                        <img src={downWinMeterImg} alt="" />
                      ) : (
                        <img src={lossUpmeterIcon} alt="" />
                      )}
                    </div>
                  )}
                  <div className="winlossDynamicdata">
                    <h1
                      style={{
                        color: "#FF5858",
                        fontSize: "22px",
                        fontWeight: 600,
                      }}
                    >
                      Loss Days
                    </h1>
                    <h2
                      style={{
                        color: "#fff",
                        fontSize: "22px",
                        fontWeight: 600,
                      }}
                    >
                      {lossDay.pnl < 0 ? "-" : ""} ₹
                      {lossDay.pnl == 0
                        ? emDash
                        : formatRupees(Math.abs(lossDay.pnl))}
                    </h2>
                    <p
                      style={{
                        color: "#F84E4E",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      {lossDay.tradeCount} Trades
                    </p>
                  </div>
                </div>
                <div
                  className="lossDays displayFlexCentre"
                  style={{
                    border: "3px solid rgba(248, 78, 78, 0.75)",
                    background: "rgba(255, 0, 0, 0.08)",
                  }}
                >
                  <div className="imgPer">
                    <img
                      className="winlossIndicator"
                      src={bombfireImg}
                      alt=""
                    />
                  </div>

                  {ptgChange && ptgChange.bigLossChange !== 0 && (
                    <div className="upmeter lossupmeter displayFlexCentre">
                      <h3
                        style={{
                          color:
                            ptgChange?.bigLossChange < 0
                              ? "#9ff975"
                              : "#ff5858",
                        }}
                      >
                        {ptgChange?.bigLossChange.toFixed()}%
                      </h3>
                      {ptgChange?.bigLossChange < 0 ? (
                        <img src={downWinMeterImg} alt="" />
                      ) : (
                        <img src={lossUpmeterIcon} alt="" />
                      )}
                    </div>
                  )}
                  <div className="winlossDynamicdata">
                    <h1
                      style={{
                        color: "#FF5858",
                        fontSize: "22px",
                        fontWeight: 600,
                      }}
                    >
                      Big Loss Days
                    </h1>
                    <h2
                      style={{
                        color: "#fff",
                        fontSize: "22px",
                        fontWeight: 600,
                      }}
                    >
                      {bigLossDay.pnl < 0 ? "-" : ""}₹
                      {bigLossDay.pnl == 0
                        ? emDash
                        : formatRupees(Math.abs(bigLossDay.pnl))}
                    </h2>
                    <p
                      style={{
                        color: "#F84E4E",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      {bigLossDay.tradeCount} Trades
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </CustomErrorBoundary>

          {/* <CopilotInsights filterType={CopilotCategory.calendar} date={date} /> */}
        </div>
      </div>
    </div>
  );
};

export default CalenderPage;
