import { useEffect, useState } from "react";
import {
  GetExitProtocolHistoryResponse,
  getExitProtocolHistory,
} from "@/module/dayLevelAnalysis/api/exitprotocolhistory";
import CandleStickChart from "@/components/charts/CandleStickChart";
import { emDash } from "@/utils/constants";
import {
  formatRupees,
  sortExitProtocols,
} from "@/module/dayLevelAnalysis/components/ExitProtocolHistory/helper";
import { format } from "date-fns";
import imgASC from "@/assets/sortupICON.svg";
import imgDSC from "@/assets/sortdownICON.svg";
import { useAppSelector } from "@/store/reduxHooks";
import moment from "moment";
import { useQuery } from "react-query";
import { CustomErrorBoundary } from "@/components/errorBoundary/CustomErrorBoundary";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { convertSeconds } from "../helper";

const initialSelectTrade: GetExitProtocolHistoryResponse = {
  tradingsymbol: "",
  scrip: "",
  average_traded_qty: 0,
  trade_type: "",
  trade_points: 0,
  trade_mtm: 0,
  trade_roi: 0,
  trade_duration: 0,
  average_entry_price: 0,
  average_exit_price: 0,
  trade_id: "",
  trade_outcome: "",
  trade_start_time: "",
  broker_id: "",
  emotion: null,
  strategy: null,
  entry_criteria: null,
  exit_criteria: null,
  trade_plan_followed: null,
  exit_reason: null,
  notes: null,
  trade_end_time: "",
  trade_date: "",
  strategy_version: 1,
};

const ChartReplay = ({
  chartRef,
}: {
  chartRef: React.MutableRefObject<any>;
}) => {
  const userState = useAppSelector((state) => state.userState);
  const { brokerId, isAuthenticated, singleDate, isGuidedFlowSeen } = userState;
  const modifiedDate = moment(singleDate).toDate();

  const [selectedTrade, setSelectedTrade] =
    useState<GetExitProtocolHistoryResponse>(initialSelectTrade);

  const [sortOrder, setSortOrder] = useState("time");

  const [isApiError, setIsApiError] = useState(false);
  const [data, setData] = useState<GetExitProtocolHistoryResponse[]>([]);

  const exitprotocolhistory = useQuery({
    queryKey: ["exitprotocolhistory", modifiedDate, brokerId, isAuthenticated],
    queryFn: () =>
      getExitProtocolHistory({
        start_date: modifiedDate
          ? format(modifiedDate, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        end_date: modifiedDate
          ? format(modifiedDate, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        broker_id: brokerId,
      }),
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && brokerId.length > 0 && isGuidedFlowSeen,
    retry: 0,
    onError: () => {
      setIsApiError(true);
    },
    onSuccess: (data) => {
      setIsApiError(false);
      if (data.length > 0) {
        const sortedData = sortExitProtocols(data);
        setData([...sortedData]);
        setSelectedTrade(sortedData[0]);
        setSortOrder("time");
      } else {
        setData([]);
        setSelectedTrade(initialSelectTrade);
        setSortOrder("time");
      }
    },
  });

  useEffect(() => {
    if (data.length > 0) {
      if (sortOrder == "asc") {
        const sortedData = data.sort((a, b) => a.trade_points - b.trade_points);
        setData([...sortedData]);
        setSelectedTrade(sortedData[0]);
      } else if (sortOrder == "desc") {
        const sortedData = data.sort((a, b) => b.trade_points - a.trade_points);
        setData([...sortedData]);
        setSelectedTrade(sortedData[0]);
      } else if (sortOrder == "time") {
        const sortedData = sortExitProtocols(data);
        setData([...sortedData]);
        setSelectedTrade(sortedData[0]);
      }
    } else {
      setSelectedTrade(initialSelectTrade);
      setData([]);
    }
  }, [sortOrder]);

  return (
    <CustomErrorBoundary
      isError={exitprotocolhistory.isError}
      isLoading={exitprotocolhistory.isLoading}
      error={exitprotocolhistory.error}
      isApiError={isApiError}
      childRef={chartRef}
      customClassName="cumulative-error-msg"
      noData={data.length == 0}
    >
      <div className="chart-replay-section" ref={chartRef}>
        <div className="chart-replay-header-section">
          <div className="chart-replay-header-right items-end">
            <>
              <div className="select-and-sort">
                <label>Select Trades</label>
                <Select
                  onValueChange={(value: string) => {
                    setSortOrder(value);
                  }}
                  defaultValue={sortOrder}
                >
                  <SelectTrigger className="broker-id-select-menu-btn w-[220px]">
                    <SelectValue placeholder="Select a time interval" />
                  </SelectTrigger>
                  <SelectContent className="broker-dropdown-container w-[220px]">
                    <SelectGroup className="select-broker-popup">
                      <SelectItem
                        className="font-weight-bold font-bold flex flex-row"
                        value={"time"}
                      >
                        <span className="flex items-center gap-2">
                          Sort by latest entry time
                        </span>
                      </SelectItem>
                      <SelectItem
                        className="font-weight-bold font-bold flex flex-row"
                        value={"asc"}
                      >
                        <span className="flex items-center gap-2">
                          Sort by points - Low to High
                        </span>
                      </SelectItem>
                      <SelectItem
                        className="font-weight-bold font-bold flex flex-row"
                        value={"desc"}
                      >
                        <span className="flex items-center gap-2">
                          Sort by points - High to Low
                        </span>
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              {data.map((el: GetExitProtocolHistoryResponse) => {
                return (
                  <div
                    className="chart-replay-header-right-item"
                    onClick={() => setSelectedTrade(el)}
                    key={el.trade_id}
                  >
                    <div
                      className="btn-left-section-wrap"
                      style={{
                        borderColor:
                          selectedTrade.trade_id == el.trade_id ? "red" : "",
                      }}
                    >
                      <div className="btn-left-section">
                        {Math.floor(el.trade_points)}
                      </div>
                    </div>
                    <div
                      className="btn-right-section"
                      style={{
                        borderColor:
                          selectedTrade.trade_id == el.trade_id ? "red" : "",
                      }}
                    >
                      {el.average_traded_qty}
                    </div>
                  </div>
                );
              })}
            </>
          </div>
        </div>
        <div className="graph-data-box-wrapper">
          <div>
            <CandleStickChart
              tradingsymbol={selectedTrade.tradingsymbol}
              scrip={selectedTrade.scrip}
              exitProtocolHistory={selectedTrade}
            />
            <div className="flex items-center justify-between mt-[1em] gap-4">
              <div className="flex items-center gap-4 w-full">
                <div className="entry-rule-chart-box">
                  <span>Entry Rule</span>
                  <div className="entry-exit-chart-bottom flex flex-col gap-2">
                    {selectedTrade.entry_criteria?.map((el: string) => {
                      return (
                        <div className="chart-bottom-btn" key={el}>
                          <p>{el}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-4 w-full">
                <div className="entry-rule-chart-box">
                  <span>Exit Rule</span>
                  <div className="entry-exit-chart-bottom flex flex-col gap-2">
                    {selectedTrade.exit_criteria?.map((el: string) => {
                      return (
                        <div className="chart-bottom-btn" key={el}>
                          <p>{el}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {selectedTrade && (
            <div className="data-box">
              <div className="data-box-item">
                <span>ROI </span>
                <h4>
                  {selectedTrade.trade_roi == 0
                    ? emDash
                    : `${selectedTrade.trade_roi}%`}
                </h4>
              </div>
              <div className="data-box-item-grid">
                <div className="data-box-item-gridItem">
                  <span>Profit </span>
                  <h4>
                    {selectedTrade.trade_mtm == 0
                      ? emDash
                      : `${
                          selectedTrade.trade_mtm > 0 ? "" : "-"
                        }₹${formatRupees(Math.abs(selectedTrade.trade_mtm))}`}
                  </h4>
                </div>
                <div className="data-box-item-gridItem">
                  <span>Quantity Traded </span>
                  <h4>
                    {selectedTrade.average_traded_qty == 0
                      ? emDash
                      : selectedTrade.average_traded_qty}
                  </h4>
                </div>
                <div className="data-box-item-gridItem">
                  <span>Duration </span>
                  <h4>
                    {selectedTrade.trade_duration == 0
                      ? emDash
                      : convertSeconds(selectedTrade.trade_duration)}
                  </h4>
                </div>
                <div className="data-box-item-gridItem">
                  <span>Points Captured</span>
                  <h4>
                    {" "}
                    {selectedTrade.trade_points == 0
                      ? emDash
                      : selectedTrade.trade_points}
                  </h4>
                </div>
                <div className="data-box-item-gridItem">
                  <span>Entry Price </span>
                  <h4>
                    {selectedTrade.average_entry_price == 0
                      ? emDash
                      : `₹${formatRupees(selectedTrade.average_entry_price)}`}
                  </h4>
                </div>
                <div className="data-box-item-gridItem">
                  <span>Exit Price </span>
                  <h4>
                    {selectedTrade.average_exit_price == 0
                      ? emDash
                      : `₹${formatRupees(selectedTrade.average_exit_price)}`}
                  </h4>
                </div>
              </div>
              {selectedTrade && (
                <>
                  <div
                    className="data-box-item-scroll"
                    id="chart-replay-sidebar-guide"
                  >
                    <div className="text-center">
                      <span className="reflection-notes">Reflection Notes</span>
                    </div>

                    {selectedTrade.notes && selectedTrade.notes.length > 0
                      ? selectedTrade.notes?.map(
                          (el: string, index: number) => {
                            return (
                              <>
                                <div className="scroll-section" key={index}>
                                  <div className="scroller-item">
                                    <div className="reflection-notes-no-div">
                                      <span className="reflection-notes-no">
                                        {index + 1}
                                      </span>
                                    </div>
                                    <p>{el}</p>
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )
                      : "No notes available"}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </CustomErrorBoundary>
  );
};

export default ChartReplay;
