import { apiClient } from "@/utils/api";
import type { AxiosResponse } from "axios";
import { traderProfileType } from "../interface/ProfileInterface";

export interface GetTraderProfileResponse {
  broker_id: string;
  broker_name: string;
  trader_type: string;
  daily_risk_limit_ptg: number;
  weekly_risk_limit_ptg: number;
  trades_per_day: number;
  scrip: string;
  daily_profit_target_ptg: number;
  weekly_profit_target_ptg: number;
  charges_limit_per_day_ptg: number;
  account_size: number;
  trade_risk_limit_ptg: number;
  desired_r_multiple: number;
}

export interface InvitedUser {
  name: {
    first: string;
    middle: string;
    last: string;
  };
  email: string;
}

export interface ExistingUser {
  email: string;
}
const getAllTraderProfile = async (): Promise<GetTraderProfileResponse[]> => {
  const res: AxiosResponse<GetTraderProfileResponse[]> = await apiClient.get(
    "/user/v1/show-tp"
  );

  return res.data;
};

const updateTraderProfile = async (editProfile: traderProfileType) => {
  const response = await apiClient.post("/user/v1/create-tp", editProfile);
  return response;
};

const getTraderProfileByBrokerId = async (
  brokerId: string
): Promise<GetTraderProfileResponse> => {
  const res: AxiosResponse<GetTraderProfileResponse> = await apiClient.get(
    `/user/v1/show-tp/${brokerId}`
  );

  return res.data;
};

export { getAllTraderProfile, updateTraderProfile, getTraderProfileByBrokerId };
