import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "@/components/loader/Loader";
import { RouteInterface } from "@/interface/RouteInterface";

import AppRoutesData from "@/routes/AppRoutesData";
import { AuthGuard, VerifyGuard } from "@/heplers/Protected";
import { UnprotectedRoutes } from "@/routes/UnprotectedRoutes";
import { useAppSelector } from "@/store/reduxHooks";

const AppRoutes: React.FunctionComponent = () => {
  const { isLoading } = useAppSelector((state) => state.userState);
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          {AppRoutesData.map((route: RouteInterface, index: number) => {
            return (
              <Route
                key={index}
                path={`${route.path}`}
                element={
                  <AuthGuard>
                    <VerifyGuard>
                      <route.component />
                    </VerifyGuard>
                  </AuthGuard>
                }
              ></Route>
            );
          })}

          {UnprotectedRoutes.map((route: RouteInterface, index: number) => {
            return (
              <Route
                key={index}
                path={`${route.path}`}
                element={<>{isLoading ? <Loader /> : <route.component />}</>}
              ></Route>
            );
          })}
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;
