export const validateForm = (values: {
  strategyName: string;
  entryCriteria: string[];
  exitCriteria: string[];
}) => {
  const errors: any = {};

  if (!values.strategyName) {
    errors.strategyName = "Required";
  }

  return errors;
};
