import {
  traderProfileDataType,
  traderProfileType,
} from "@/module/Profile/interface/ProfileInterface";
import _ from "lodash";

export type ScripOptionType = {
  value: string;
  label: string;
};
export type traderTypeOptionsType = {
  value: string;
  label: string;
  disabled: boolean;
};
export const getError = (errors: any) => {
  let err = { ...errors };
  if (errors.weeklyRiskLimitPtg && errors.dailyRiskLimitPtg) {
    err = {
      ...errors,
      combinedRiskLimit:
        "Daily and weekly risk limit must be greater than zero.",
    };
  }
  if (errors.weeklyProfitTargetPtg && errors.dailyProfitTargetPtg) {
    err = {
      ...errors,
      combinedProfitTarget:
        "Daily and weekly profit target must be greater than zero.",
    };
  }
  return err;
};

export const scripOptions: ScripOptionType[] = [
  {
    value: "",
    label: "Select Scrip",
  },
  {
    value: "Index Options",
    label: "Index Options",
  },
  {
    value: "Index Futures",
    label: "Index Futures",
  },
  {
    value: "Stock Options",
    label: "Stock Options",
  },
  {
    value: "Stock Futures",
    label: "Stock Futures",
  },
  {
    value: "Stocks",
    label: "Stocks",
  },
];
export const traderTypeOptions: traderTypeOptionsType[] = [
  { value: "", label: "Select Type", disabled: false },
  { value: "Day", label: "Day", disabled: false },
  { value: "Scalper", label: "Scalper (Coming Soon)", disabled: true },
  { value: "Swing", label: "Swing (Coming Soon)", disabled: true },
];
export const brokerOptions: traderTypeOptionsType[] = [
  { value: "", label: "Select Broker", disabled: false },
  { value: "Zerodha", label: "Zerodha", disabled: false },
  { value: "Upstox", label: "Upstox (Coming Soon)", disabled: true },
  { value: "Grow", label: "Grow (Coming Soon)", disabled: true },
  {
    value: "Angel Broking",
    label: "Angel Broking (Coming Soon)",
    disabled: true,
  },
];

export const initialProfileData: traderProfileDataType = {
  brokerId: "",
  brokerName: "Zerodha",
  dailyRiskLimitPtg: 0,
  weeklyRiskLimitPtg: 0,
  tradesPerDay: 0,
  scrip: "Index Options",
  dailyProfitTargetPtg: 0,
  weeklyProfitTargetPtg: 0,
  chargesLimitPerDayPtg: 0,
  accountSize: 0,
  tradeRiskLimitPtg: 0,
  desiredReturnMultiple: 1,
  traderType: "Day",
};

export const initialTradeProfile: traderProfileType = {
  broker_id: "",
  broker_name: "",
  trader_type: "",
  daily_risk_limit_ptg: 0,
  weekly_risk_limit_ptg: 0,
  trades_per_day: 0,
  scrip: "",
  daily_profit_target_ptg: 0,
  weekly_profit_target_ptg: 0,
  charges_limit_per_day_ptg: 0,
  account_size: 0,
  trade_risk_limit_ptg: 0,
  desired_r_multiple: 0,
};

export const capitalizeFirstLetterOfWords = (sentence: string) => {
  // Split the sentence into an array of words
  const words = sentence.split(" ");

  // Capitalize the first character of each word
  const capitalizedWords = words.map((word) => {
    if (word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    } else {
      return word; // If the word is an empty string, keep it as is
    }
  });

  // Join the capitalized words back into a sentence
  const capitalizedSentence = capitalizedWords.join(" ");

  return capitalizedSentence;
};

const preprocessStringToLowerCase: any = (obj: any) => {
  if (typeof obj === "string") {
    return obj.toLowerCase();
  } else if (typeof obj === "number") {
    return obj.toString();
  } else if (Array.isArray(obj)) {
    return obj.map(preprocessStringToLowerCase);
  } else if (typeof obj === "object") {
    const newObj: any = {};
    Object.keys(obj).forEach((key) => {
      newObj[key] = preprocessStringToLowerCase(obj[key]);
    });
    return newObj;
  }
  return obj;
};

export const isEqualIgnoreCase = (objA: any, objB: any) => {
  const processedA = preprocessStringToLowerCase(objA);
  const processedB = preprocessStringToLowerCase(objB);
  return _.isEqual(processedA, processedB);
};
