import React, { useEffect, useRef, useState } from "react";
import Layout from "@/components/Layout";
import "@/pages/dayLevelAnalysis/DayLevelAnalysis.css";

import Header from "@/components/Header";
import CalenderPage from "@/module/dayLevelAnalysis/components/CalenderPage";
import DatePicker from "@/components/ui/DatePicker";
import ExitProtocolHistory from "@/module/dayLevelAnalysis/components/ExitProtocolHistory/ExitProtocolHistory";
import ChartReplay from "@/module/dayLevelAnalysis/components/chartReplay/ChartReplay";
import { useAppDispatch, useAppSelector } from "@/store/reduxHooks";
import { setSingleDate } from "../auth/authSlice";
import { DayPickerProvider } from "react-day-picker";
import { Button } from "@/components/ui/button";
import moment from "moment";

const DayLevelAnalysis = () => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.userState);
  const { singleDate } = userState;
  const modifiedDate = moment(singleDate).toDate();
  const [date, setDate] = React.useState<Date | undefined>(modifiedDate);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    modifiedDate
  );
  const chartRef = useRef<any>(null);

  //  header calender popover
  const [openCalenderPopover, setOpenCalenderPopover] = useState(false);
  const handleConfirm = () => {
    const formatdate = {
      from: date,
    };

    if (!date) {
      formatdate.from = new Date();
    }
    setSelectedDate(formatdate.from);
    setOpenCalenderPopover(false);
    dispatch(
      setSingleDate(
        formatdate.from ? formatdate.from.toISOString() : singleDate
      )
    );
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.scrollIntoView();
    }
    setSelectedDate(moment(singleDate).toDate());
  }, [singleDate]);

  return (
    <Layout>
      <Header>
        <DayPickerProvider initialProps={{}}>
          <DatePicker
            date={date}
            setDate={setDate}
            open={openCalenderPopover}
            setOpen={setOpenCalenderPopover}
            selectedDate={modifiedDate}
          >
            <div id="headerConfirmCancelButtons">
              <Button onClick={handleConfirm}>Confirm</Button>
              <Button
                onClick={() => {
                  setOpenCalenderPopover(false);
                  setDate(selectedDate);
                }}
              >
                Cancel
              </Button>
            </div>
          </DatePicker>
        </DayPickerProvider>
      </Header>
      <CalenderPage />
      <div className="section-div">
        <div className="section-content">
          <div className="section-content-title" id="chart-replay-guide">
            📈 Chart Replay
          </div>
          <ChartReplay chartRef={chartRef} />
        </div>
        <ExitProtocolHistory />
      </div>
    </Layout>
  );
};

export default DayLevelAnalysis;
