import PageNotFound from "@/pages/404/PageNotFound";
import Login from "@/pages/auth/Login";
import Signup from "@/pages/auth/Signup";
import ResetPassword from "@/pages/auth/forgotPassword.tsx/ResetPassword";
import SendForgotPasswordEmailOtp from "@/pages/auth/forgotPassword.tsx/SendForgotPasswordEmailOtp";

export const UnprotectedRoutes = [
  {
    path: "/login",
    component: Login,
    name: "Login",
  },
  {
    path: "/signup",
    component: Signup,
    name: "Signup",
  },
  {
    path: "/sendresetotp",
    component: SendForgotPasswordEmailOtp,
    name: "SendForgotPasswordEmailOtp",
  },
  {
    path: "/resetpassword",
    component: ResetPassword,
    name: "ResetPassword",
  },
  {
    path: "*",
    component: PageNotFound,
    name: "PageNotFound",
  },
];
