import { useState } from "react";
import mainLogoImg from "@/assets/Group 1633.webp";
import "@/pages/auth/login.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { loginValidation } from "@/validation/AuthValidation";
import { objectToSearchParams } from "@/pages/auth/helper";
import { useMutation } from "react-query";
import { login } from "@/module/auth/api/auth";
import toast from "react-hot-toast";
import { UserLoginData } from "@/module/auth/interface/AuthInterface";
import { useAppDispatch, useAppSelector } from "@/store/reduxHooks";
import { setAuth } from "@/pages/auth/authSlice";
import Cookie from "js-cookie";
import { FiEye, FiEyeOff } from "react-icons/fi";
import betLogo from "@/assets/bet-logo.svg";
import topshineIMG from "@/assets/web-top-shine.svg";
import bottomshineIMG from "@/assets/web-bottom-shine.svg";

const Login = () => {
  const dispatch = useAppDispatch();
  const { isLoggedIn } = useAppSelector((state) => state.userState);
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      toast.success(<p>Log In Success!</p>, {
        duration: 2000,
      });
      if (data.access_token) {
        dispatch(setAuth(true));
        const token = data.access_token;
        var expiredIn = new Date();
        const expiredDays = 30;
        expiredIn.setTime(
          expiredIn.getTime() + expiredDays * 24 * 60 * 60 * 1000
        );
        Cookie.set("auth-token", token, {
          secure: true,
          sameSite: "strict",
          expires: expiredIn,
        });
        navigate("/");
        window.location.reload();
      }
    },
    onError: (error) => {
      toast.error(<p>Invalid Username or Password!</p>, {
        duration: 2000,
      });
    },
  });

  if (isLoggedIn) {
    navigate("/");
  }

  const [initialValues, setInitialValues] = useState({
    username: "",
    password: "",
    scope: "",
    client_id: "",
    client_secret: "",
  });

  const onSubmit = async (values: UserLoginData) => {
    const params = objectToSearchParams(values);
    mutation.mutate(params);
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: loginValidation,
  });

  return (
    <div>
      <div className="log-in">
        <div className="log-in-left displayFlexCentre">
          <div className="log-in-left-content">
            <img src={mainLogoImg} alt="" />
          </div>
          <div className="log-in-left-content">
            <h2>Level up your Game with Betwizr</h2>
          </div>
          <div className="log-in-left-content">
            <h1>
              <span>Try Now, with</span>
              <br />
              Free Access during Beta period!
            </h1>
          </div>
          <div className="log-in-left-content">
            <p>*No Credit Card Required</p>
          </div>
          <div className="log-in-left-content">
            <NavLink to="/signup">
              <button type="button" className="reg-log-button relative">
                <img
                  className="w-[20px] absolute left-[12px] top-[7px]"
                  src={topshineIMG}
                  alt=""
                />
                Register Today
                <img
                  className="w-[20px] absolute right-[12px] bottom-[7px]"
                  src={bottomshineIMG}
                  alt=""
                />
              </button>
            </NavLink>
          </div>
          <div className="log-in-left-content">
            <h4>Got a Question? Contact Us</h4>
          </div>
          <div className="log-in-left-content logo-text-container displayFlexCentre">
            <p>support@betwizr.trade</p>
          </div>
        </div>

        <div className="log-in-right displayFlexCentre">
          <div className="log-in-right-content log-in-right-content-logo">
            <div className="right-content-logo logo-text-container displayFlexCentre">
              <img src={betLogo} alt="" />
            </div>
            <h2>Login To Dashboard</h2>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="logIn-form-containter"
          >
            <div className="log-in-right-content">
              <div className="lable">Login</div>
              <input
                type="text"
                placeholder="Username..."
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
              />
              {formik.touched.username && formik.errors.username && (
                <p className="loginPage-input-error">
                  {formik.errors.username.toString()}
                </p>
              )}
              <div className="lable">Password</div>
              <div className="password-login">
                <input
                  type={hidePassword ? "password" : "text"}
                  placeholder="Enter Password..."
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                {hidePassword ? (
                  <FiEye
                    className="iconSPasswordR"
                    onClick={() => {
                      setHidePassword(false);
                    }}
                  />
                ) : (
                  <FiEyeOff
                    className="iconSPasswordR"
                    onClick={() => {
                      setHidePassword(true);
                    }}
                  />
                )}
              </div>
              {formik.touched.password && formik.errors.password && (
                <p className="loginPage-input-error">
                  {formik.errors.password.toString()}
                </p>
              )}
            </div>
            {/* <div className="log-in-right-content google-signIn-btn-container">
              <button type="button" className="google-button">
                <img src={gmailIconImg} alt="" />
                Sign In With Gmail Account
              </button>
            </div> */}
            <div className="log-in-right-content create-forget-btn">
              <NavLink to="/signup">
                <button className="create-account-btn" type="button">
                  Create Account
                </button>
              </NavLink>

              <button
                type="button"
                onClick={() => {
                  navigate("/sendresetotp");
                }}
              >
                Forgot Password
              </button>
            </div>
            <div className="log-in-right-content">
              <button type="submit" className="reg-log-button relative">
                <img
                  className="w-[20px] absolute left-[12px] top-[7px]"
                  src={topshineIMG}
                  alt=""
                />
                Login
                <img
                  className="w-[20px] absolute right-[12px] bottom-[7px]"
                  src={bottomshineIMG}
                  alt=""
                />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
