import { useEffect, useRef, useState } from "react";
import {
  createChart,
  CrosshairMode,
  IChartApi,
  ISeriesApi,
  SeriesMarker,
  Time,
  UTCTimestamp,
} from "lightweight-charts";
import { useQuery } from "react-query";
import {
  getAllOrders,
  getChartReplay,
} from "@/module/dayLevelAnalysis/api/chartReplay";
import {
  defaultCandleStickSeriesData,
  sortOrdersData,
  transformAndSortData,
} from "@/components/charts/helper";
import { GetExitProtocolHistoryResponse } from "@/module/dayLevelAnalysis/api/exitprotocolhistory";
import moment from "moment";
import { useAppSelector } from "@/store/reduxHooks";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { CustomErrorBoundary } from "../errorBoundary/CustomErrorBoundary";
import { format } from "date-fns";
import dummyChartReplayImg from "@/assets/dummyChartReplay.png";

export type TimeInterval = "m1" | "m5";

const CandleStickChart = ({
  scrip,
  tradingsymbol,
  exitProtocolHistory,
}: {
  scrip: string;
  tradingsymbol: string;
  exitProtocolHistory: GetExitProtocolHistoryResponse;
}) => {
  const userState = useAppSelector((state) => state.userState);
  const { isAuthenticated, singleDate, isGuidedFlowSeen } = userState;
  const modifiedDate = moment(singleDate).toDate();

  const chartContainerRef = useRef<any>();
  const chart = useRef<IChartApi>();
  const resizeObserver = useRef<ResizeObserver>();
  const candleSeries = useRef<ISeriesApi<"Candlestick">>();
  const [isApiError, setIsApiError] = useState(false);
  const [interval, setInterval] = useState<TimeInterval>("m5");

  const [seriesData, setSeriesData] = useState(defaultCandleStickSeriesData);

  const [seriesMarkers, setSeriesMarkers] = useState<SeriesMarker<Time>[]>([]);
  const [isChartReplaySuccess, setIsChartReplaySuccess] = useState(false);

  const chartReplay = useQuery({
    queryKey: [
      "chartReplay",
      modifiedDate,
      scrip,
      tradingsymbol,
      isAuthenticated,
      interval,
    ],
    queryFn: () => {
      return getChartReplay({
        trade_date: format(modifiedDate, "yyyy-MM-dd"),
        scrip,
        tradingsymbol,
      });
    },
    onError: (error) => {
      setIsApiError(true);
      setSeriesData(defaultCandleStickSeriesData);
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && scrip.length > 0 && isGuidedFlowSeen,
    retry: 0,
    onSuccess: (data) => {
      setIsApiError(false);

      const modifiedData = transformAndSortData(data, interval);
      setSeriesData(modifiedData);
      setIsChartReplaySuccess(true);
    },
  });

  const allOrders = useQuery({
    queryKey: [
      "allOrders",
      exitProtocolHistory.trade_id,
      isAuthenticated,
      interval,
    ],
    queryFn: () =>
      getAllOrders({
        trade_id: exitProtocolHistory.trade_id,
      }),
    refetchOnWindowFocus: false,
    enabled:
      isAuthenticated &&
      exitProtocolHistory.trade_id.length > 0 &&
      isChartReplaySuccess &&
      isGuidedFlowSeen,
    retry: 0,
    onSuccess: (data) => {
      let ordersMarkerData = sortOrdersData({
        data: data,
        interval,
        date: format(modifiedDate, "yyyy-MM-dd"),
      });

      let ordersMarker: SeriesMarker<Time>[] = [];

      if (ordersMarkerData.length > 1) {
        ordersMarkerData.map((el, index) => {
          if (index == 0) {
            return ordersMarker.push({
              time: el.time,
              position: "aboveBar",
              color: el.totalQty >= 0 ? "#119043" : "#FF0000",
              shape: "arrowDown",
              text: `Entry ${el.totalQty >= 0 ? "+" : "-"} ${Math.abs(
                el.totalQty
              )}`,
              size: 0.5,
            });
          } else if (index == ordersMarkerData.length - 1) {
            return ordersMarker.push({
              time: el.time,
              position: "aboveBar",
              color: el.totalQty >= 0 ? "#119043" : "#FF0000",
              shape: "arrowDown",
              text: `Exit ${el.totalQty >= 0 ? "+" : "-"} ${Math.abs(
                el.totalQty
              )}`,
              size: 0.5,
            });
          } else {
            return ordersMarker.push({
              time: el.time,
              position: "aboveBar",
              color:
                ordersMarkerData[0].totalQty >= 0
                  ? ordersMarkerData[0].totalQty >= 0 !== el.totalQty >= 0
                    ? "#FF0000"
                    : "#119043"
                  : ordersMarkerData[0].totalQty >= 0 !== el.totalQty >= 0
                  ? "#119043"
                  : "#FF0000",
              shape: "arrowDown",
              text: ` ${
                ordersMarkerData[0].totalQty >= 0
                  ? ordersMarkerData[0].totalQty >= 0 !== el.totalQty >= 0
                    ? "partial exit -"
                    : "partial entry -"
                  : ordersMarkerData[0].totalQty >= 0 !== el.totalQty >= 0
                  ? "partial exit +"
                  : "partial entry -"
              } ${Math.abs(el.totalQty)}`,

              size: 0.5,
            });
          }
        });
      } else if (ordersMarkerData.length === 1) {
        ordersMarker.push({
          time: ordersMarkerData[0].time,
          position: "aboveBar",
          color: ordersMarkerData[0].entryType == "BUY" ? "#119043" : "#FF0000",
          shape: "arrowDown",
          text: `Traded here ${ordersMarkerData[0].entryQty}`,
          size: 0.5,
        });
      }

      setSeriesMarkers(ordersMarker);
    },
  });

  useEffect(() => {
    if (candleSeries.current) {
      candleSeries?.current?.setData(seriesData);
      if (!isApiError) {
        candleSeries.current.setMarkers(seriesMarkers);
        if (chart.current) {
          let beforeOffset = 0.5; // 30 min
          let afterOffset = 0.4; // 24 min
          if (interval == "m1") {
            beforeOffset = 0.1; // 6 min
            afterOffset = 0.067; // 4 min
          }
          chart.current.timeScale().setVisibleRange({
            from: (moment(exitProtocolHistory.trade_start_time)
              .utcOffset(0, true)
              .subtract(beforeOffset, "hours")
              .valueOf() / 1000) as UTCTimestamp,
            to: (moment(exitProtocolHistory.trade_end_time)
              .utcOffset(0, true)
              .add(afterOffset, "hours")
              .valueOf() / 1000) as UTCTimestamp,
          });
        }
      } else {
        if (chart.current) {
          candleSeries.current.setMarkers([]);
          chart.current.timeScale().fitContent();
        }
      }
    }
  }, [seriesData, seriesMarkers, candleSeries]);

  useEffect(() => {
    if (chart.current) return;
    if (chartContainerRef.current) {
      const bounds = chartContainerRef?.current.getBoundingClientRect();
      chart.current = createChart(chartContainerRef.current, {
        width: bounds.width,
        height: bounds.height,
        layout: {
          background: { color: "transparent" },
          textColor: "rgba(255, 255, 255, 0.9)",
        },
        grid: {
          vertLines: {
            color: "transparent",
          },
          horzLines: {
            color: "transparent",
          },
        },
        crosshair: {
          mode: CrosshairMode.Normal,
        },
        rightPriceScale: {
          borderColor: "#485c7b",
        },
        timeScale: {
          rightOffset: 12,
          barSpacing: 3,
          fixLeftEdge: true,
          lockVisibleTimeRangeOnResize: false,
          rightBarStaysOnScroll: true,
          borderVisible: false,
          borderColor: "#fff000",
          visible: true,
          timeVisible: true,
          secondsVisible: false,
        },
      });

      candleSeries.current = chart.current.addCandlestickSeries({
        upColor: "#119043",
        downColor: "#C13232",
        wickDownColor: "#838ca1",
        wickUpColor: "#838ca1",
      });
      candleSeries?.current?.setData(seriesData);
    }
  }, []);

  // Resize chart on container resizes.
  useEffect(() => {
    resizeObserver.current = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      if (chart.current) {
        chart.current.applyOptions({ width, height });
      }
      setTimeout(() => {
        if (chart.current) {
          chart.current.timeScale().fitContent();
        }
      }, 0);
    });

    resizeObserver.current.observe(chartContainerRef.current);

    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
    };
  }, []);

  return (
    <div>
      <>
        <div className="candle-chart-min-button-container font-bold">
          <Select
            onValueChange={(value: TimeInterval) => {
              setInterval(value);
            }}
            defaultValue={interval}
          >
            <SelectTrigger
              className="broker-id-select-menu-btn w-[130px]"
              disabled={isApiError || exitProtocolHistory.scrip.length == 0}
            >
              <SelectValue placeholder="Select a time interval" />
            </SelectTrigger>
            <SelectContent className="broker-dropdown-container w-[100px]">
              <SelectGroup className="select-broker-popup">
                <SelectItem className="font-weight-bold font-bold" value={"m1"}>
                  1 Minute
                </SelectItem>
                <SelectItem className="font-weight-bold font-bold" value={"m5"}>
                  5 Minute
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <CustomErrorBoundary
          isError={chartReplay.isError}
          isLoading={chartReplay.isLoading}
          error={chartReplay.error}
          isApiError={isApiError || exitProtocolHistory.scrip.length == 0}
          childRef={chartContainerRef}
          customClassName="cumulative-error-msg"
          noData={exitProtocolHistory.scrip.length == 0}
        >
          <div
            ref={chartContainerRef}
            className="chart-container w-[96%] h-[500px]"
            style={{
              display:
                isApiError || exitProtocolHistory.scrip.length == 0
                  ? "none"
                  : "",
            }}
          />
          <div
            className="chart-container w-[96%] h-[500px]"
            style={{
              display:
                isApiError || exitProtocolHistory.scrip.length == 0
                  ? ""
                  : "none",
            }}
          >
            <img
              src={dummyChartReplayImg}
              style={{
                objectFit: "contain",
                maxHeight: "100%",
                maxWidth: "100%",
                width: "100%",
                height: "100%",
              }}
            />
          </div>
        </CustomErrorBoundary>
      </>
    </div>
  );
};

export default CandleStickChart;
