import { apiClient } from "@/utils/api";
import type { AxiosResponse } from "axios";
import {
  GetCopilotResponse,
  GetEmotionsResponse,
  GetPnLResponse,
  GetPnLTrendResponse,
  GetScoreTrendResponse,
  GetScripMapResponse,
  GetTodResponse,
  GetWinRateTrendResponse,
  GetWizrScoreResponse,
} from "@/module/dashboard/interface/insightsInterface";

const getTod = async ({
  start_date,
  end_date,
  broker_id,
}: {
  start_date: string;
  end_date: string;
  broker_id: string;
}): Promise<GetTodResponse[]> => {
  const param = {
    start_date,
    end_date,
    broker_id,
  };

  const res: AxiosResponse<GetTodResponse[]> = await apiClient.get(
    "/insights/v1/tod",
    {
      params: param,
    }
  );

  return res.data;
};
const getWinRateTrend = async ({
  start_date,
  end_date,
  broker_id,
}: {
  start_date: string;
  end_date: string;
  broker_id: string;
}): Promise<GetWinRateTrendResponse[]> => {
  const param = {
    start_date,
    end_date,
    broker_id,
  };

  const res: AxiosResponse<GetWinRateTrendResponse[]> = await apiClient.get(
    "/insights/v1/wr-trend",
    {
      params: param,
    }
  );

  return res.data;
};
const getPnLTrend = async ({
  start_date,
  end_date,
  broker_id,
}: {
  start_date: string;
  end_date: string;
  broker_id: string;
}): Promise<GetPnLTrendResponse[]> => {
  const param = {
    start_date,
    end_date,
    broker_id,
  };

  const res: AxiosResponse<GetPnLTrendResponse[]> = await apiClient.get(
    "/insights/v1/pnl-trend",
    {
      params: param,
    }
  );

  return res.data;
};
const getPnL = async ({
  start_date,
  end_date,
  broker_id,
}: {
  start_date: string;
  end_date: string;
  broker_id: string;
}): Promise<GetPnLResponse[]> => {
  const param = {
    start_date,
    end_date,
    broker_id,
  };

  const res: AxiosResponse<GetPnLResponse[]> = await apiClient.get(
    "/insights/v1/pnl",
    {
      params: param,
    }
  );

  return res.data;
};
const getScripMap = async ({
  start_date,
  end_date,
  broker_id,
}: {
  start_date: string;
  end_date: string;
  broker_id: string;
}): Promise<GetScripMapResponse[]> => {
  const param = {
    start_date,
    end_date,
    broker_id,
  };

  const res: AxiosResponse<GetScripMapResponse[]> = await apiClient.get(
    "/insights/v1/scrip-map",
    {
      params: param,
    }
  );

  return res.data;
};
const getWizrScore = async ({
  start_date,
  end_date,
  broker_id,
}: {
  start_date: string;
  end_date: string;
  broker_id: string;
}): Promise<GetWizrScoreResponse> => {
  const param = {
    start_date,
    end_date,
    broker_id,
  };
  const res: AxiosResponse<GetWizrScoreResponse> = await apiClient.get(
    "/insights/v1/score",
    {
      params: param,
    }
  );

  return res.data;
};

const getAllCopilot = async ({
  start_date,
  end_date,
  broker_id,
}: {
  start_date: string;
  end_date: string;
  broker_id: string;
}): Promise<GetCopilotResponse[]> => {
  const param = {
    start_date,
    end_date,
    broker_id,
  };
  const res: AxiosResponse<GetCopilotResponse[]> = await apiClient.get(
    "/insights/v1/copilot",
    {
      params: param,
    }
  );

  return res.data;
};
const getEmotions = async ({
  start_date,
  end_date,
  broker_id,
}: {
  start_date: string;
  end_date: string;
  broker_id: string;
}): Promise<GetEmotionsResponse[]> => {
  const param = {
    start_date,
    end_date,
    broker_id,
  };
  const res: AxiosResponse<GetEmotionsResponse[]> = await apiClient.get(
    "/insights/v1/emotions",
    {
      params: param,
    }
  );

  return res.data;
};

const getScoreTrend = async ({
  start_date,
  end_date,
  broker_id,
}: {
  start_date: string;
  end_date: string;
  broker_id: string;
}): Promise<GetScoreTrendResponse[]> => {
  const param = {
    start_date,
    end_date,
    broker_id,
  };

  const res: AxiosResponse<GetScoreTrendResponse[]> = await apiClient.get(
    "/insights/v1/score-trend",
    {
      params: param,
    }
  );

  return res.data;
};

export {
  getTod,
  getWinRateTrend,
  getPnLTrend,
  getPnL,
  getScripMap,
  getWizrScore,
  getAllCopilot,
  getEmotions,
  getScoreTrend,
};
