import { useEffect, useState } from "react";
import AppRoutes from "@/routes/AppRoutes";
import Cookie from "js-cookie";
import {
  getItemFromLocalStorage,
  logOutUser,
  setAuth,
  setBroker,
  setBrokersList,
  setEmailOtpState,
  setPhOtpState,
  setUser,
} from "@/pages/auth/authSlice";
import store from "@/store/store";
import { useQuery, useQueryClient } from "react-query";
import { getAllBrokers, getUser } from "./module/auth/api/auth";
import { useAppSelector } from "./store/reduxHooks";
import { checkMobileDevice } from "./utils/helper";
import ComingSoon from "./pages/comingSoon/ComingSoon";

function App() {
  const userState = useAppSelector((state) => state.userState);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  useEffect(() => {
    if (checkMobileDevice()) {
      setIsMobileDevice(true);
    }
  }, []);
  const { isLoggedIn } = userState;
  const queryClient = useQueryClient();
  const brokers = useQuery({
    queryKey: ["brokers", isLoggedIn],
    queryFn: () => getAllBrokers(),
    refetchOnWindowFocus: false,
    enabled: isLoggedIn,
    retry: 0,
  });

  useEffect(() => {
    if (brokers.isSuccess && brokers.data) {
      const data = brokers.data;
      if (data.length > 0) {
        store.dispatch(setBroker(data[0].brkr_id));
        store.dispatch(setBrokersList(data));
      }
    }
  }, [brokers.data]);

  const userQuery = useQuery({
    queryKey: ["user", isLoggedIn],
    queryFn: () => getUser(),
    refetchOnWindowFocus: false,
    enabled: isLoggedIn,
    retry: 0,
    onSuccess: (data) => {
      store.dispatch(setUser(data));
    },
  });

  useEffect(() => {
    const user = getItemFromLocalStorage("user");
    const isEmailOtpSent = getItemFromLocalStorage("isEmailOtpSent");
    const isMobileSent = getItemFromLocalStorage("isPhOtpSent");

    const token = Cookie.get("auth-token");
    if (token) {
      store.dispatch(setAuth(true));
    } else {
      store.dispatch(setAuth(false));
      store.dispatch(logOutUser());
      queryClient.clear();
    }

    if (user && token) {
      store.dispatch(setUser(user));
    }
    if (isEmailOtpSent && token) {
      store.dispatch(setEmailOtpState());
    }
    if (isMobileSent && token) {
      store.dispatch(setPhOtpState());
    }
  }, []);
  return (
    <div className="App">{isMobileDevice ? <ComingSoon /> : <AppRoutes />}</div>
  );
}

export default App;
