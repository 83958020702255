import { getPnLTrend } from "@/module/dashboard/api/insights";
import { GetPnLTrendResponse } from "@/module/dashboard/interface/insightsInterface";
import { useAppDispatch, useAppSelector } from "@/store/reduxHooks";
import { addDays, format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { DateRange } from "react-day-picker";
import { useQuery } from "react-query";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import moment from "moment";
import { formatRupees } from "@/module/dayLevelAnalysis/components/ExitProtocolHistory/helper";
import { CustomErrorBoundary } from "../errorBoundary/CustomErrorBoundary";
import { useNavigate } from "react-router-dom";
import { setSingleDate } from "@/pages/auth/authSlice";

const PLGrowthChartDaily = ({ date }: { date: DateRange | undefined }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userState = useAppSelector((state) => state.userState);
  const { isAuthenticated, brokerId, isGuidedFlowSeen } = userState;
  const [isApiError, setIsApiError] = useState(false);
  const childRef = useRef<any>(null);

  const [pnlData, setPnlData] = useState<GetPnLTrendResponse[]>([]);
  const pnLTrend = useQuery({
    queryKey: ["pnLTrend", date, brokerId, isAuthenticated],
    queryFn: () => {
      setIsApiError(false);

      return getPnLTrend({
        start_date: date?.from
          ? format(date.from, "yyyy-MM-dd")
          : format(addDays(new Date(), -7), "yyyy-MM-dd"),
        end_date: date?.to
          ? format(date.to, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        broker_id: brokerId,
      });
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && brokerId.length > 0 && isGuidedFlowSeen,
    retry: 0,
    onError: (error) => {
      setIsApiError(true);
    },
  });

  useEffect(() => {
    if (pnLTrend.isSuccess) {
      const sortedData = pnLTrend.data.sort(
        (a: GetPnLTrendResponse, b: GetPnLTrendResponse) => {
          return (
            new Date(a.trading_day).getTime() -
            new Date(b.trading_day).getTime()
          );
        }
      );
      const modifiedData = sortedData.map(
        (el: GetPnLTrendResponse, index: number) => {
          return {
            x: moment(el.trading_day).format("MM-DD-YYYY [GMT]"),
            y: el.tot_mtm,
          };
        }
      );
      setPnlData(sortedData);
      if (sortedData.length == 0) {
        setIsApiError(true);
      }
    }
  }, [pnLTrend.data]);

  const minValue = Math.min(
    ...pnlData.map((el: GetPnLTrendResponse) => {
      return el.tot_mtm;
    })
  );

  const maxValue = Math.max(
    ...pnlData.map((el: GetPnLTrendResponse) => {
      return el.tot_mtm;
    })
  );

  const getMinMaxOffset = (minValue: number, maxValue: number) => {
    let minOffset = Math.round(minValue + 0.1 * minValue);
    let maxOffset = Math.round(maxValue + 0.1 * maxValue);
    if (maxValue > 0 && minValue > 0) {
      minOffset = -Math.round(minValue);
      maxOffset = Math.round(maxValue + 0.1 * maxValue);
    }
    if (maxValue < 0 && minValue < 0) {
      minOffset = Math.round(minValue + 0.1 * minValue);
      maxOffset = -Math.round(minValue);
    }

    return {
      minOffset,
      maxOffset,
    };
  };

  const offsets = getMinMaxOffset(minValue, maxValue);

  const chartOptions: ApexOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      events: {
        dataPointMouseEnter: function (event) {
          event.srcElement.style.cursor = "pointer";
        },
        dataPointSelection: function (
          event,
          chartContext,
          { seriesIndex, dataPointIndex, config }
        ) {
          if (seriesIndex !== undefined && dataPointIndex !== undefined) {
            var timestamp = chartContext.data.w.globals.labels[dataPointIndex];
            const originalDate = moment(timestamp, "YYYY-MM-DD").toDate();

            setTimeout(() => {
              dispatch(setSingleDate(originalDate.toISOString()));
              navigate("/day-level-analysis");
            }, 10);
          }
        },
      },
    },

    plotOptions: {
      bar: {
        columnWidth: "40%",
        borderRadius: 4,
        dataLabels: {
          position: "top",
        },
        colors: {
          ranges: [
            {
              from: -10000000000000,
              to: 0,
              color: "#D43D29", // Red for negative values
            },
            {
              from: 0,
              to: 10000000000000,
              color: "#2A7517", // Green for positive values
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        if (Number(val) == 0) {
          return "";
        }
        return (
          `${Number(val) > 0 ? "" : "- "}` +
          "₹ " +
          formatRupees(Math.abs(Number(val)))
        );
      },
      offsetY: -30,
      style: {
        fontSize: "14px",
        colors: ["#FFFFFF"],
        fontFamily: "Source Sans Pro",
        fontWeight: 400,
      },
      dropShadow: {
        enabled: false,
      },
      background: {
        enabled: false,
      },
    },
    grid: {
      show: true,
      borderColor: "#434046",
      strokeDashArray: 2,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      shared: false,
      intersect: true,
    },

    yaxis: {
      min: offsets.minOffset,
      max: offsets.maxOffset,
      tickAmount: 4,
      labels: {
        formatter: function (val) {
          if (Number(val) == 0) {
            return "";
          }
          return (
            `${Number(val) > 0 ? "" : "- "}` +
            "₹ " +
            formatRupees(Math.abs(Number(val)))
          );
        },
        align: "right",
        minWidth: 100,
        maxWidth: 140,
        style: {
          colors: "#FFFFFF",
          fontSize: "14px",
          fontWeight: 400,
          fontFamily: "Source Sans Pro",
        },
        offsetX: -25,
      },
    },
    xaxis: {
      categories: pnlData.map((el: GetPnLTrendResponse) => {
        return el.trading_day;
      }),
      tooltip: {
        enabled: false,
      },
      labels: {
        hideOverlappingLabels: true,
        trim: true,
        style: {
          colors: "#FFFFFF",
          fontSize: "14px",
          fontWeight: 400,
          fontFamily: "Source Sans Pro",
        },
        formatter: function (value, timestamp: number, opts) {
          return moment(value).format("DD/M");
        },
      },

      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickPlacement: "between",
    },

    annotations: {
      yaxis: [
        {
          y: 0,
          borderColor: "#fff",
          strokeDashArray: 0,
          label: {
            borderColor: "#00E396",
            style: {
              color: "#fff",
              background: "#00E396",
            },
          },
        },
      ],
    },
  };
  const chartSeries = [
    {
      name: "pnl",
      data: pnlData.map((el: GetPnLTrendResponse) => {
        return el.tot_mtm == 0 ? null : Math.round(el.tot_mtm);
      }),
      fillColor: "#EB8C87",
      strokeColor: "#C23829",
    },
  ];

  return (
    <CustomErrorBoundary
      isError={pnLTrend.isError}
      isLoading={pnLTrend.isLoading}
      error={pnLTrend.error}
      isApiError={isApiError}
      childRef={childRef}
      customClassName="cumulative-error-msg"
      noData={pnlData.length == 0}
    >
      <div id="chart" ref={childRef}>
        <Chart
          type="bar"
          series={chartSeries}
          options={chartOptions}
          width="100%"
          height={350}
        />
      </div>
    </CustomErrorBoundary>
  );
};

export default PLGrowthChartDaily;
