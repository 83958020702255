import { formatRupees } from "./ExitProtocolHistory/helper";
const renderEventContent = (eventInfo: any) => {
  return (
    <div className="aman" id="amanc">
      <img className="eventimage" src={eventInfo.event.url} alt="" />
      {eventInfo.event.title !== 0 &&
        formatRupees(Math.abs(eventInfo.event.title)) !== "0.0" && (
          <p>
            {eventInfo.event.title > 0 ? "" : "-"}₹
            {formatRupees(Math.abs(eventInfo.event.title))}
          </p>
        )}

      {eventInfo.event.extendedProps.subtitle && (
        <span className="subtitle">
          {eventInfo.event.extendedProps.subtitle}
        </span>
      )}
    </div>
  );
};

export default renderEventContent;
