import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Toaster } from "react-hot-toast";

import App from "@/App";
import store from "@/store/store";
import "@/styles/globals.css";
import "@/globalStyle.css";
import * as Sentry from "@sentry/react";
import MultiRouteWrapper from "./module/guidedTour/MultiRouteWrapper";

Sentry.init({
  dsn: process.env.REACT_APP_DSN_URL,
  integrations: [Sentry.browserTracingIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/betwizr\.trade\/api/],
  environment: process.env.REACT_APP_ENV,
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
        <MultiRouteWrapper />
        <ReactQueryDevtools />
      </QueryClientProvider>
      <Toaster containerStyle={{ zIndex: 99999 }} />
    </ReduxProvider>
  </BrowserRouter>
);
