import { useState } from "react";
import { useMutation } from "react-query";
import { FormikHelpers, useFormik } from "formik";
import toast from "react-hot-toast";
import { emailValidation } from "@/validation/AuthValidation";
import { forgotPasswordOtp } from "@/module/auth/api/auth";
import { useNavigate } from "react-router-dom";
import "@/pages/auth/forgotPassword.tsx/resetpassword.css";

const SendForgotPasswordEmailOtp = () => {
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    email: "",
  });
  const mutation = useMutation({
    mutationFn: forgotPasswordOtp,
    onSuccess: (data) => {
      if (data.status == "SUCCESS") {
        toast.success(
          <p>
            Otp to reset your account has been sent to registered email! Please
            check your email!
          </p>,
          {
            duration: 2000,
          }
        );
        navigate("/resetpassword");
        return;
      }
    },
    onError: (error) => {
      toast.error(<p>Something went wrong!</p>, {
        duration: 2000,
      });
    },
  });

  const onSubmit = async (
    values: { email: string },
    actions: FormikHelpers<{ email: string }>
  ) => {
    mutation.mutate(values.email);
    actions.resetForm();
    setTimeout(() => {
      actions.setSubmitting(false);
    }, 500);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: emailValidation,
  });
  return (
    <div className="reset-password-container">
      <form
        onSubmit={formik.handleSubmit}
        className="reset-change-password-form-container display-flex-align-justy-center"
      >
        <div className="sign-up-right-content">
          <div className="send-otp-reset-password-lable">Email</div>
          <input
            type="text"
            placeholder="Enter email..."
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            className="reset-password-otp-input"
          />
          {formik.touched.email && formik.errors.email && (
            <p className="signupPage-input-error">
              {formik.errors.email.toString()}
            </p>
          )}
        </div>

        <div className="adButtons">
          <button
            className="saveButton adButton1 password-change-save-btn button-max-width"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Send Otp
          </button>
        </div>
      </form>
    </div>
  );
};

export default SendForgotPasswordEmailOtp;
