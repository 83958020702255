import { Button } from "@/components/ui/button";
import { Dialog, DialogClose, DialogContent } from "@/components/ui/dialog";
import { FormikHelpers, useFormik } from "formik";
import { useEffect, useState } from "react";
import { capitalizeFirstLetterOfWords } from "@/pages/profiles/editProfile/helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ExitProtocolInterface } from "@/module/dayLevelAnalysis/interface/ExitPorotocolInterface";
import {
  defaultStrategy,
  formatPoints,
  formatRupees,
  formatToOneDecimal,
  initialStrategyvalue,
} from "@/module/dayLevelAnalysis/components/ExitProtocolHistory/helper";
import { Input } from "@/components/ui/input";
import {
  GetExitProtocolHistoryResponse,
  updateExitProtocol,
} from "@/module/dayLevelAnalysis/api/exitprotocolhistory";
import { ExitProtocolValidationSchema } from "@/validation/ExitProtocolValidationSchema";
import CreateStrategyModal from "@/module/dayLevelAnalysis/components/createStrategy/CreateStrategyModal";
import "@/module/dayLevelAnalysis/components/ExitProtocolHistory/exitprotocol.css";
import toast from "react-hot-toast";
import {
  getStrategies,
  getStrategyByName,
} from "@/module/strategy/api/strategy";
import { GetStrategyResponse } from "@/module/strategy/interface/StrategyInterface";
import { useAppSelector } from "@/store/reduxHooks";
import angryImg from "@/assets/angry-emotion.svg";
import sadImg from "@/assets/sad-emaotion.svg";
import panicImg from "@/assets/panic-emotion.svg";
import happyImg from "@/assets/happy-emotion.svg";
import zenImg from "@/assets/zen-emotion.svg";
import { CgClose } from "react-icons/cg";

const EditExitPortocolModal = ({
  exitData,
  open,
  setOpen,
}: {
  exitData: GetExitProtocolHistoryResponse;
  open: boolean;
  setOpen: (value: boolean) => void;
}) => {
  const userState = useAppSelector((state) => state.userState);
  const { brokerId, isAuthenticated } = userState;
  const queryClient = useQueryClient();
  const [strategyName, setStrategyName] = useState<string>("");

  const userTradeStrategies = useQuery({
    queryKey: ["userTradeStrategies", isAuthenticated],
    queryFn: () => getStrategies(),
    refetchOnWindowFocus: false,
    enabled: isAuthenticated,
    retry: 0,
  });

  const singleStrategy = useQuery({
    queryKey: ["singleStrategy", strategyName, isAuthenticated],
    queryFn: () => getStrategyByName(strategyName),
    refetchOnWindowFocus: false,
    enabled:
      isAuthenticated &&
      strategyName.length > 0 &&
      strategyName !== defaultStrategy,
    retry: 0,
  });

  const mutation = useMutation({
    mutationFn: updateExitProtocol,
    onSuccess: (data) => {
      toast.success(<p> Exit protocal Updated Successfully!</p>, {
        duration: 2000,
      });
      queryClient.invalidateQueries("exitprotocolhistory");
    },
    onError: (error) => {
      toast.error(<p>Error while updating exit protocol!</p>, {
        duration: 2000,
      });
    },
  });

  useEffect(() => {
    if (userTradeStrategies.isSuccess && userTradeStrategies.data) {
      setStrategy([initialStrategyvalue, ...userTradeStrategies.data]);
    }
  }, [userTradeStrategies.data]);

  const [initialValues, setInitialValues] = useState<ExitProtocolInterface>({
    newNote: "",
    notes: exitData.notes ? exitData.notes : [],
    type: exitData.trade_type,
    duration: exitData.trade_duration,
    buyPrice: exitData.average_entry_price,
    sellPrice: exitData.average_exit_price,
    points: exitData.trade_points,
    roi: exitData.trade_roi,
    profit: exitData.trade_mtm,
    emotion: exitData.emotion
      ? capitalizeFirstLetterOfWords(exitData.emotion)
      : "",
    newStrategy: exitData.strategy ? exitData.strategy : defaultStrategy,
    exitReason: exitData.exit_reason
      ? capitalizeFirstLetterOfWords(exitData.exit_reason)
      : "",
    exitCriteria: exitData.exit_criteria ? exitData.exit_criteria : [],
    entryCriteria: exitData.entry_criteria ? exitData.entry_criteria : [],
  });
  const handleSubmit = async (
    values: ExitProtocolInterface,
    actions: FormikHelpers<ExitProtocolInterface>
  ) => {
    const data = {
      trade_id: exitData && exitData.trade_id ? exitData.trade_id : "",
      emotion: values.emotion || "",
      strategy: values.newStrategy || "",
      strategy_version: selectedStrategyVersion?.version,
      exit_reason: values.exitReason || "",
      notes: values.notes.length > 0 ? values.notes : null,
      trade_end_time: exitData.trade_end_time,
      entry_criteria:
        values.entryCriteria.length > 0 ? values.entryCriteria : null,
      exit_criteria:
        values.exitCriteria.length > 0 ? values.exitCriteria : null,
      trade_plan_followed:
        selectedStrategyVersion.strategy !== defaultStrategy &&
        selectedStrategyVersion.entry_criteria &&
        selectedStrategyVersion.exit_criteria
          ? values.entryCriteria.length ===
              selectedStrategyVersion.entry_criteria.length &&
            values.exitCriteria.length ===
              selectedStrategyVersion.exit_criteria.length
            ? "Yes"
            : "No"
          : null,
    };

    mutation.mutate(data);
    setTimeout(() => {
      actions.setSubmitting(false);
      setOpen(false);
    }, 500);
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: ExitProtocolValidationSchema,
  });

  const addNote = () => {
    if (formik.values.newNote.trim() !== "") {
      formik.setValues({
        ...formik.values,
        notes: [...formik.values.notes, formik.values.newNote],
        newNote: "", // Clear the input field after adding the strategy
      });
    }
  };

  const [strategy, setStrategy] = useState<GetStrategyResponse[]>([
    initialStrategyvalue,
  ]);
  const [selectedStrategy, setSelectedStrategy] =
    useState<GetStrategyResponse>(initialStrategyvalue);
  const [exitCriteria, setExitCriteria] = useState<string[]>([]);
  const [entryCriteria, setEntyCriteria] = useState<string[]>([]);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (singleStrategy?.isSuccess && singleStrategy.data) {
      const strategyVersions = singleStrategy.data;
      setStrategyVersions(strategyVersions);
      const latestVersion = strategyVersions[strategyVersions.length - 1];

      if (
        counter < 1 &&
        exitData.strategy &&
        exitData.strategy.length > 0 &&
        exitData.strategy !== defaultStrategy
      ) {
        const selectedValue = exitData.strategy_version;
        const selectedStrategy = strategyVersions.find(
          (el: GetStrategyResponse) => el.version === selectedValue
        );
        if (selectedStrategy) {
          handleStrategySelectedStrategyVersion(selectedStrategy, false);
        }
        setCounter((prev) => prev + 1);
      } else {
        handleStrategySelectedStrategyVersion(latestVersion, true);
      }
    }
  }, [singleStrategy?.data]);

  //select all checkbox

  const handleSelectAllEntryCriteria = () => {
    const allSelected = entryCriteria.every((el) =>
      formik.values.entryCriteria.includes(el)
    );

    const entryCriteriaCopy = allSelected
      ? [] // Deselect all if all are currently selected
      : entryCriteria.slice(); // Select all if not all are currently selected

    formik.setFieldValue("entryCriteria", entryCriteriaCopy);
  };
  const handleSelectAllExitCriteria = () => {
    const allSelected = exitCriteria.every((el) =>
      formik.values.exitCriteria.includes(el)
    );

    const exitCriteriaCopy = allSelected
      ? [] // Deselect all if all are currently selected
      : exitCriteria.slice(); // Select all if not all are currently selected

    formik.setFieldValue("exitCriteria", exitCriteriaCopy);
  };

  const handleRemoveNotes = (indexToRemove: number) => {
    formik.setFieldValue(
      "notes",
      formik.values.notes.filter(
        (_: any, index: number) => index !== indexToRemove
      )
    );
  };
  const [strategyVersions, setStrategyVersions] = useState<
    GetStrategyResponse[]
  >([]);

  const [selectedStrategyVersion, setSelectedStrategyVersion] =
    useState<GetStrategyResponse>(initialStrategyvalue);

  const handleStrategySelectedStrategyVersion = (
    el: GetStrategyResponse,
    setInitialState: boolean
  ) => {
    setSelectedStrategyVersion(el);
    if (el.entry_criteria) {
      setEntyCriteria(el.entry_criteria);
    } else {
      setEntyCriteria([]);
    }
    if (el.exit_criteria) {
      setExitCriteria(el.exit_criteria);
    } else {
      setExitCriteria([]);
    }
    if (setInitialState) {
      formik.setFieldValue("entryCriteria", []);
      formik.setFieldValue("exitCriteria", []);
    }
  };

  useEffect(() => {
    if (strategyName == defaultStrategy) {
      setEntyCriteria([]);
      setExitCriteria([]);
      setStrategyVersions([]);
      setSelectedStrategyVersion(initialStrategyvalue);
    }
  }, [strategyName]);

  useEffect(() => {
    if (
      exitData.strategy &&
      exitData.strategy?.length > 0 &&
      exitData.strategy !== defaultStrategy
    ) {
      setStrategyName(exitData.strategy);
    }
  }, [exitData]);

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="lg:max-w-[1000px] w-screen web-exit-proto-container-main p-0">
          <form
            action=""
            className="web-exit-proto-wrapper"
            onSubmit={formik.handleSubmit}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          >
            <div className="web-exit-proto-form">
              <div className="web-exit-proto-tradeEmotion">
                <div className="web-exit-proto-select1">
                  <div className="web-exit-proto-one-left">
                    <h1 className="web-exit-proto-mainHedingExitproto">
                      Trade Exit Protocol
                    </h1>
                    <p className="web-exit-proto-ExitbankniftytagLine">
                      {exitData && exitData.tradingsymbol
                        ? exitData.tradingsymbol
                        : "No Symbol"}
                    </p>
                  </div>
                </div>
                <div className="web-exit-proto-select2">
                  <div className="web-exit-proto-two-left">
                    <div className="web-exit-proto-tdpBox">
                      <p>Type</p>
                      <h1>{formik.values.type}</h1>
                    </div>
                    <div className="web-exit-proto-tdpBox">
                      <p>Duration</p>
                      <h1>{formik.values.duration}</h1>
                    </div>
                    <div className="web-exit-proto-tdpBox">
                      <p>Points</p>
                      <h1>
                        {formik.values.points < 0 ? "-" : ""}
                        {formatPoints(Math.abs(formik.values.points))}
                      </h1>
                    </div>
                    <div className="web-exit-proto-tdpBox">
                      <p>Buy Price</p>
                      <h1>₹ {formatRupees(formik.values.buyPrice)}</h1>
                    </div>
                    <div className="web-exit-proto-tdpBox">
                      <p>Sell Price</p>
                      <h1>₹ {formatRupees(formik.values.sellPrice)}</h1>
                    </div>
                  </div>
                  <div className="web-exit-proto-sp-line"></div>
                  <div className="web-exit-proto-two-right">
                    <div
                      className="web-exit-proto-greenBox"
                      style={{
                        backgroundColor:
                          formik.values.profit > 0 ? "" : "#36201D",
                      }}
                    >
                      <p>ROI</p>
                      <h1
                        style={{
                          color:
                            formik.values.profit > 0 ? "#97FF7D" : "#FF4329",
                        }}
                      >
                        {formatToOneDecimal(formik.values.roi)}%
                      </h1>
                    </div>
                    <div
                      className="web-exit-proto-greenBox"
                      style={{
                        backgroundColor:
                          formik.values.profit > 0 ? "" : "#36201D",
                      }}
                    >
                      {formik.values.profit > 0 ? (
                        <>
                          <p>Profit</p>
                          <h1
                            style={{
                              color:
                                formik.values.profit > 0
                                  ? "#97FF7D"
                                  : "#FF4329",
                            }}
                          >
                            ₹{formatRupees(formik.values.profit)}
                          </h1>
                        </>
                      ) : (
                        <>
                          <p>Loss</p>
                          <h1
                            style={{
                              color:
                                formik.values.profit > 0
                                  ? "#97FF7D"
                                  : "#FF4329",
                            }}
                          >
                            ₹{formatRupees(Math.abs(formik.values.profit))}
                          </h1>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="web-exit-proto-select3">
                  <div className="web-exit-proto-three-Left web-exit-proto-exitThreeLeft">
                    <div className="web-exit-proto-selectHasnum">
                      <div className="web-exit-proto-hasnumber">
                        <h2>#1</h2>
                      </div>
                      <div className="web-exit-proto-selectionTag">
                        <h2>Select</h2>
                        <h1>Your Emotions:</h1>
                        {formik.touched.emotion && formik.errors.emotion && (
                          <p>{formik.errors.emotion.toString()}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="web-exit-proto-three-right web-exit-proto-exitSelectRightWidth">
                    <label
                      className={`web-exit-proto-crl-img-wrapper ${
                        formik.values.emotion === "Happy" ? "checked" : ""
                      } `}
                    >
                      <input
                        type="radio"
                        name="emotion"
                        value="Happy"
                        className="web-exit-proto-radiobtnBox "
                        checked={formik.values.emotion == "Happy"}
                        onChange={formik.handleChange}
                      />
                      <div
                        className={`web-exit-proto-crl-box ${
                          formik.values.emotion === "Happy" ? "checked" : ""
                        }`}
                      >
                        <div className="web-exit-proto-astroImg">
                          <img className="w-[3em]" src={happyImg} alt="Happy" />
                        </div>
                        <h3>Happy</h3>
                      </div>
                    </label>

                    <label
                      className={`web-exit-proto-crl-img-wrapper ${
                        formik.values.emotion === "Zen" ? "checked" : ""
                      } `}
                    >
                      <input
                        type="radio"
                        name="emotion"
                        value="Zen"
                        className="web-exit-proto-radiobtnBox"
                        checked={formik.values.emotion == "Zen"}
                        onChange={formik.handleChange}
                      />
                      <div
                        className={`web-exit-proto-crl-box ${
                          formik.values.emotion === "Zen" ? "checked" : ""
                        }`}
                      >
                        <div className="web-exit-proto-astroImg">
                          <img className="w-[3em]" src={zenImg} alt="Zen" />
                        </div>
                        <h3>Zen</h3>
                      </div>
                    </label>
                    <label
                      className={`web-exit-proto-crl-img-wrapper ${
                        formik.values.emotion === "Angry" ? "checked" : ""
                      } `}
                    >
                      <input
                        type="radio"
                        name="emotion"
                        value="Angry"
                        className="web-exit-proto-radiobtnBox"
                        checked={formik.values.emotion == "Angry"}
                        onChange={formik.handleChange}
                      />
                      <div
                        className={`web-exit-proto-crl-box ${
                          formik.values.emotion === "Angry" ? "checked" : ""
                        }`}
                      >
                        <div className="web-exit-proto-astroImg">
                          <img
                            style={{ marginLeft: "12px" }}
                            className="w-[4.6em]"
                            src={angryImg}
                            alt="Angry"
                          />
                        </div>
                        <h3>Angry</h3>
                      </div>
                    </label>
                    <label
                      className={`web-exit-proto-crl-img-wrapper ${
                        formik.values.emotion === "Panic" ? "checked" : ""
                      } `}
                    >
                      <input
                        type="radio"
                        name="emotion"
                        value="Panic"
                        className="web-exit-proto-radiobtnBox"
                        checked={formik.values.emotion == "Panic"}
                        onChange={formik.handleChange}
                      />
                      <div
                        className={`web-exit-proto-crl-box ${
                          formik.values.emotion === "Panic" ? "checked" : ""
                        }`}
                      >
                        <div className="web-exit-proto-astroImg">
                          <img
                            className="w-[4.6em]"
                            src={panicImg}
                            alt="Panic"
                          />
                        </div>
                        <h3 className="mt-1">Panic</h3>
                      </div>
                    </label>
                    <label
                      className={`web-exit-proto-crl-img-wrapper ${
                        formik.values.emotion === "Sad" ? "checked" : ""
                      } `}
                    >
                      <input
                        type="radio"
                        name="emotion"
                        value="Sad"
                        className="web-exit-proto-radiobtnBox"
                        checked={formik.values.emotion == "Sad"}
                        onChange={formik.handleChange}
                      />
                      <div
                        className={`web-exit-proto-crl-box ${
                          formik.values.emotion === "Sad" ? "checked" : ""
                        }`}
                      >
                        <div className="web-exit-proto-astroImg">
                          <img className="w-[4.5em]" src={sadImg} alt="Sad" />
                        </div>
                        <h3 className="mt-1">Sad</h3>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="web-exit-proto-select4">
                <div className="web-exit-proto-four-Left web-exit-proto-exitSelectLeftWidth">
                  <div className="web-exit-proto-selectHasnum">
                    <div className="web-exit-proto-hasnumber">
                      <h2>#2</h2>
                    </div>
                    <div className="web-exit-proto-selectionTag">
                      <h2>Select</h2>
                      <h1>Trade Strategy:</h1>
                    </div>
                  </div>
                </div>
                <div className="web-exit-proto-four-right">
                  <div className="web-exit-proto-newstrategyBtncontainer">
                    <select
                      id="web-exit-proto-newStrategy"
                      name="newStrategy"
                      value={formik.values.newStrategy}
                      onChange={(event) => {
                        const selectedValue = event.target.value;
                        setStrategyName(selectedValue);
                        formik.setFieldValue("entryCriteria", []);
                        formik.setFieldValue("exitCriteria", []);
                        formik.setFieldValue("newStrategy", selectedValue);
                      }}
                    >
                      {strategy &&
                        strategy.map(
                          (el: GetStrategyResponse, index: number) => (
                            //@ts-ignore
                            <option key={index} value={el.id}>
                              {el.strategy}
                            </option>
                          )
                        )}
                    </select>
                    {formik.touched.newStrategy &&
                      formik.errors.newStrategy && (
                        <p>{formik.errors.newStrategy.toString()}</p>
                      )}

                    {formik.values.newStrategy !== defaultStrategy && (
                      <select
                        className="web-exit-proto-version-select-exitproto"
                        onChange={(event) => {
                          const selectedValue = Number(event.target.value);
                          const selectedStrategy = strategyVersions.find(
                            (el) => el.version === selectedValue
                          );
                          if (selectedStrategy) {
                            handleStrategySelectedStrategyVersion(
                              selectedStrategy,
                              true
                            );
                          }
                        }}
                        value={selectedStrategyVersion.version}
                      >
                        {strategyVersions.length > 0 &&
                          strategyVersions
                            .slice()
                            .reverse()
                            .map((el, index) => (
                              <option key={index} value={el.version}>
                                Version {el.version}
                              </option>
                            ))}
                      </select>
                    )}
                    <CreateStrategyModal btnText="" />
                  </div>
                </div>
              </div>

              <div className="web-exit-proto-select5">
                <div className="web-exit-proto-five-Left web-exit-proto-exitSelectLeftWidth">
                  <div className="web-exit-proto-selectHasnum">
                    <div className="web-exit-proto-hasnumber">
                      <h2>#3</h2>
                    </div>
                    <div className="web-exit-proto-selectionTag">
                      <h2>Select</h2>
                      <h1>Trade Plan:</h1>
                    </div>
                  </div>
                </div>
                <div className="web-exit-proto-five-right web-exit-proto-exitSelectRightWidth">
                  <div className="web-exit-proto-exit-criteria">
                    <h4 className="flex items-center gap-2">
                      {entryCriteria.length > 0 && (
                        <input
                          type="checkbox"
                          className="h-[1em] web-exit-proto-select-all-criteria-box-check"
                          checked={
                            entryCriteria.length > 0 &&
                            entryCriteria.length ===
                              formik.values.entryCriteria.length
                          }
                          onChange={() => {
                            handleSelectAllEntryCriteria();
                          }}
                        />
                      )}
                      Entry Criteria
                    </h4>

                    {entryCriteria.map((el: string) => (
                      <div className="web-exit-proto-exit-entry-criteria-condition-btn">
                        <label
                          className={
                            formik.values.entryCriteria.includes(el)
                              ? "web-exit-proto-exit-box-btn checked"
                              : "web-exit-proto-exit-box-btn"
                          }
                        >
                          <input
                            type="checkbox"
                            className="web-exit-proto-radiobtnBox"
                            checked={formik.values.entryCriteria.includes(el)}
                            onChange={() => {
                              const entryCriteriaCopy = [
                                ...formik.values.entryCriteria,
                              ];
                              const index = entryCriteriaCopy.indexOf(el);
                              if (index === -1) {
                                entryCriteriaCopy.push(el);
                              } else {
                                entryCriteriaCopy.splice(index, 1);
                              }
                              formik.setFieldValue(
                                "entryCriteria",
                                entryCriteriaCopy
                              );
                            }}
                            name={el}
                          />
                          <div>
                            <h3>{capitalizeFirstLetterOfWords(el)}</h3>
                          </div>
                        </label>
                      </div>
                    ))}

                    {formik.touched.entryCriteria &&
                      formik.errors.entryCriteria && (
                        <p>{formik.errors.entryCriteria.toString()}</p>
                      )}
                  </div>
                  <div className="web-exit-proto-exit-criteria">
                    <h4 className="flex items-center gap-2">
                      {exitCriteria.length > 0 && (
                        <input
                          type="checkbox"
                          className="h-[1em] web-exit-proto-select-all-criteria-box-check"
                          checked={
                            exitCriteria.length > 0 &&
                            exitCriteria.length ===
                              formik.values.exitCriteria.length
                          }
                          onChange={handleSelectAllExitCriteria}
                        />
                      )}
                      Exit Criteria
                    </h4>

                    {exitCriteria.map((el: string) => (
                      <div className="web-exit-proto-exit-entry-criteria-condition-btn">
                        <label
                          className={
                            formik.values.exitCriteria.includes(el)
                              ? "web-exit-proto-exit-box-btn checked"
                              : "web-exit-proto-exit-box-btn"
                          }
                        >
                          <input
                            type="checkbox"
                            className="web-exit-proto-radiobtnBox"
                            checked={formik.values.exitCriteria.includes(el)}
                            onChange={() => {
                              const exitCriteriaCopy = [
                                ...formik.values.exitCriteria,
                              ];
                              const index = exitCriteriaCopy.indexOf(el);
                              if (index === -1) {
                                exitCriteriaCopy.push(el);
                              } else {
                                exitCriteriaCopy.splice(index, 1);
                              }
                              formik.setFieldValue(
                                "exitCriteria",
                                exitCriteriaCopy
                              );
                            }}
                            name={el}
                          />
                          <div>
                            <h3>{capitalizeFirstLetterOfWords(el)}</h3>
                          </div>
                        </label>
                      </div>
                    ))}

                    {formik.touched.exitCriteria &&
                      formik.errors.exitCriteria && (
                        <p>{formik.errors.exitCriteria.toString()}</p>
                      )}
                  </div>
                </div>
              </div>
              <div className="web-exit-proto-select6">
                <div className="web-exit-proto-six-Left web-exit-proto-exitSelectLeftWidth">
                  <div className="web-exit-proto-selectHasnum">
                    <div className="web-exit-proto-hasnumber">
                      <h2>#4</h2>
                    </div>
                    <div className="web-exit-proto-selectionTag">
                      <h2>Select</h2>
                      <h1>Exit Reason</h1>
                      {formik.touched.exitReason &&
                        formik.errors.exitReason && (
                          <p>{formik.errors.exitReason.toString()}</p>
                        )}
                    </div>
                  </div>
                </div>
                <div className="web-exit-proto-six-right">
                  <div className="web-exit-proto-exit-reason-buttons-hit">
                    <label
                      className={`hit-sl-box ${
                        formik.values.exitReason === "Hit Stop Loss"
                          ? "checked"
                          : ""
                      } `}
                    >
                      <input
                        type="radio"
                        name="exitReason"
                        value="Hit Stop Loss"
                        onChange={formik.handleChange}
                        checked={formik.values.exitReason == "Hit Stop Loss"}
                        className="web-exit-proto-radiobtnBox"
                      />
                      Hit SL
                    </label>

                    <label
                      className={`hit-target-box ${
                        formik.values.exitReason === "Hit Target"
                          ? "checked"
                          : ""
                      } `}
                    >
                      <input
                        type="radio"
                        name="exitReason"
                        value="Hit Target"
                        onChange={formik.handleChange}
                        checked={formik.values.exitReason == "Hit Target"}
                        className="web-exit-proto-radiobtnBox"
                      />
                      Hit Target
                    </label>

                    <label
                      className={`hit-panic-box ${
                        formik.values.exitReason === "Panic Exit"
                          ? "checked"
                          : ""
                      } `}
                    >
                      <input
                        type="radio"
                        name="exitReason"
                        value="Panic Exit"
                        onChange={formik.handleChange}
                        checked={formik.values.exitReason == "Panic Exit"}
                        className="web-exit-proto-radiobtnBox"
                      />
                      Panic Exit
                    </label>
                  </div>
                </div>
              </div>
              <div className="web-exit-proto-select7">
                <h1 className="web-exit-proto-mynote">My Notes:</h1>
                {/* <button type="button" className="web-exit-proto-micBtn">
                  <img src={getUrl("Vector.svg")} alt="" />
                </button> */}

                <div className="web-exit-proto-currentNotesInputPlus">
                  <Input
                    name="newNote"
                    value={formik.values.newNote}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    placeholder="type here..."
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        addNote();
                      }
                    }}
                  />
                  <Button
                    type="button"
                    className="web-exit-proto-plusbutton"
                    color="primary"
                    onClick={addNote}
                  >
                    +
                  </Button>
                </div>
              </div>

              <div className="web-exit-proto-select8">
                <div className="web-exit-proto-currentNotes">
                  {formik.values.notes &&
                    formik.values.notes.map((note: string, index: number) => (
                      <div className="web-exit-proto-currents-notes-wrapper-num-remove">
                        <p
                          key={index}
                          className="web-exit-proto-currentNotesPeraNumber"
                        >
                          <span className="web-exit-proto-currentNotesIndex">
                            {index + 1}
                          </span>
                          <span className="web-exit-proto-currentNotesPera">
                            {note}
                          </span>
                        </p>
                        <button
                          className="web-exit-proto-entry-exit-btn-minus"
                          type="button"
                          onClick={() => handleRemoveNotes(index)}
                        >
                          <CgClose />
                        </button>
                      </div>
                    ))}
                </div>
              </div>
              <div className="web-exit-proto-select9">
                <button
                  className="web-exit-proto-submitBtn"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditExitPortocolModal;
