import React from "react";
import betlogoIMG from "@/assets/bet-logo.svg";

const ComingSoon = () => {
  return (
    <div className="w-[100%] h-[100vh] p-4 flex flex-col items-center justify-center gap-4">
      <div className="bet-logo-mobile-screen w-[70%] h-[40%] bg-contain">
        <img src={betlogoIMG} alt="" />
      </div>
      <h1 className="mobile-comming-soon-text leading-10 text-2xl">
        Currently, the Betwizr app is not available on mobile. No need to worry,
        Trader! We'll soon be operational on mobile.
      </h1>
    </div>
  );
};

export default ComingSoon;
