import { logOutUser } from "@/pages/auth/authSlice";
import store from "@/store/store";
import axios, { AxiosError } from "axios";
import Cookie from "js-cookie";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/react";

const apiUrl = process.env.REACT_APP_API_URL;

const HEADERS = {
  "Content-Type": "application/json",
};

const apiClient = axios.create({
  baseURL: apiUrl,
  headers: {
    ...HEADERS,
  },
});

apiClient.interceptors.request.use((request) => {
  const token = Cookie.get("auth-token");
  if (token) {
    request.headers = request.headers || {};
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
});

// Response interceptor
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (
      (error.response && error.response.status === 403) ||
      (error.response && error.response.status === 401)
    ) {
      toast.error("Session expired. Please log in again.", {
        id: "session_expired",
        duration: 2000,
      });
      window.location.href = "/login";
      store.dispatch(logOutUser());
    } else {
      Sentry.withScope((scope) => {
        const {
          brokerId,
          user: { user_id },
          brokersList,
        } = store.getState().userState;
        scope.setTag("api_endpoint", error.config?.url || "unknown");
        if (error.response) {
          scope.setExtras({
            response: error.response.data,
            status: error.response.status,
            brokerId: brokerId ?? "",
            brokerName:
              (brokersList.length > 0 && brokersList[0].brkr_name) ?? "",
            userId: user_id ?? "",
          });
          scope.setUser({ id: user_id ?? "" });
        } else {
          scope.setExtras({
            response: null,
            status: null,
            brokerId: brokerId ?? "",
            brokerName:
              (brokersList.length > 0 && brokersList[0].brkr_name) ?? "",
            userId: user_id ?? "",
          });
          scope.setUser({ id: user_id ?? "" });
        }
        Sentry.captureException(error);
      });
    }

    return Promise.reject(error);
  }
);

export { apiClient, apiUrl };
