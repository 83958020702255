import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { changePassword } from "@/module/auth/api/auth";
import { ChangePasswordInterface } from "@/module/auth/interface/AuthInterface";
import { useAppDispatch } from "@/store/reduxHooks";
import { changePasswordValidation } from "@/validation/AuthValidation";
import { DialogClose } from "@radix-ui/react-dialog";
import { FormikHelpers, useFormik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { useMutation, useQueryClient } from "react-query";
import { logOutUser } from "@/pages/auth/authSlice";
import "@/pages/auth/changePassword/changepassword.css";
import topshineIMG from "@/assets/web-top-shine.svg";
import bottomshineIMG from "@/assets/web-bottom-shine.svg";

const ChangePasswordModal = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const initialValues = {
    old_password: "",
    new_password: "",
  };

  const [hideNewPassword, setHideNewPassword] = useState<boolean>(true);
  const [hideCurrentPassword, setHideCurrentPassword] = useState<boolean>(true);

  const mutation = useMutation({
    mutationFn: changePassword,
    onSuccess: (data) => {
      toast.success(<p>Password Updated Successfully!</p>, {
        duration: 2000,
      });
      dispatch(logOutUser());
      queryClient.clear();
    },
    onError: (error) => {
      toast.error(<p>Incorrect Password!</p>, {
        duration: 2000,
      });
    },
  });

  const onSubmit = async (
    values: ChangePasswordInterface,
    actions: FormikHelpers<ChangePasswordInterface>
  ) => {
    mutation.mutate(values);
    actions.resetForm();
    setTimeout(() => {
      actions.setSubmitting(false);
    }, 500);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: changePasswordValidation,
    enableReinitialize: true,
  });

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" className="border-none-for-button relative">
          <img
            className="w-[20px] absolute left-[10px] top-[6px]"
            src={topshineIMG}
            alt=""
          />
          Change Password
          <img
            className="w-[20px] absolute right-[10px] bottom-[6px]"
            src={bottomshineIMG}
            alt=""
          />
        </Button>
      </DialogTrigger>
      <DialogContent className="changePassword-container">
        <DialogHeader>
          <DialogTitle className="changer-your-password-heading">
            Change your Password
          </DialogTitle>
        </DialogHeader>

        <form
          onSubmit={formik.handleSubmit}
          className="change-password-form-container display-flex-align-justy-center"
        >
          <div className="adInputsreset change-pass-input-lable-container .display-flex-align-justy-center">
            <div className="textFieldI textFieldI">
              <h2 className="accDetH2 current-new-password-lable">
                Current Password
              </h2>
              <div className="passwordInputRow display-flex-align-justy-center">
                <input
                  className="input-current-new-password"
                  type={hideCurrentPassword ? "password" : "text"}
                  placeholder="Current Password"
                  onBlur={formik.handleBlur}
                  name="old_password"
                  value={formik.values.old_password}
                  onChange={formik.handleChange}
                />
                {hideCurrentPassword ? (
                  <FiEye
                    className="iconSPasswordcp"
                    onClick={() => {
                      setHideCurrentPassword(false);
                    }}
                  />
                ) : (
                  <FiEyeOff
                    className="iconSPasswordcp"
                    onClick={() => {
                      setHideCurrentPassword(true);
                    }}
                  />
                )}
              </div>
              {formik.touched.old_password && formik.errors.old_password && (
                <p className="change-password-error-text">
                  {formik.errors.old_password.toString()}
                </p>
              )}
            </div>
            <div className="textFieldI textFieldI">
              <h2 className="accDetH2 current-new-password-lable">
                New Password
              </h2>
              <div className="passwordInputRow display-flex-align-justy-center">
                <input
                  className="input-current-new-password"
                  type={hideNewPassword ? "password" : "text"}
                  placeholder="Password"
                  onBlur={formik.handleBlur}
                  name="new_password"
                  value={formik.values.new_password}
                  onChange={formik.handleChange}
                />
                {hideNewPassword ? (
                  <FiEye
                    className="iconSPasswordcp"
                    onClick={() => {
                      setHideNewPassword(false);
                    }}
                  />
                ) : (
                  <FiEyeOff
                    className="iconSPasswordcp"
                    onClick={() => {
                      setHideNewPassword(true);
                    }}
                  />
                )}
              </div>
              {formik.touched.new_password && formik.errors.new_password && (
                <p className="change-password-error-text">
                  {formik.errors.new_password.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="adButtons">
            <button
              className="saveButton adButton1 password-change-save-btn relative"
              type="submit"
              disabled={formik.isSubmitting}
            >
              <img
                className="w-[24px] absolute left-[14px] top-[7px]"
                src={topshineIMG}
                alt=""
              />
              Save
              <img
                className="w-[24px] absolute right-[14px] bottom-[7px]"
                src={bottomshineIMG}
                alt=""
              />
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordModal;
