import { apiClient } from "@/utils/api";
import type { AxiosResponse } from "axios";

export interface GetAdheranceResponse {
  dr_na_ptg: number;
  dr_na_max: number;
  dr_na_tot_pnl: number;
  avg_drl_value: number;
  avg_drl_ptg: number;
  avg_acc_size: number;
  tc_na_ptg: number;
  tc_na_max: number;
  tc_na_tot_pnl: number;
  avg_trades_pd: number;
  drm_na_ptg: number;
  drm_na_min: number;
  drm_na_tot_pnl: number;
  avg_drm: number;
}

const getAdherance = async ({
  start_date,
  end_date,
  broker_id,
}: {
  start_date: string;
  end_date: string;
  broker_id?: string;
}): Promise<GetAdheranceResponse[]> => {
  const param = {
    start_date,
    end_date,
    broker_id,
  };
  if (!broker_id) {
    delete param?.broker_id;
  }
  const res: AxiosResponse<GetAdheranceResponse[]> = await apiClient.get(
    "/insights/v1/adherence",
    {
      params: param,
    }
  );

  return res.data;
};

export { getAdherance };
