import { useEffect, useRef, useState } from "react";
import "./boardingflowstepper.css";
import InfoIcon from "@/assets/png/infoIcon.png";
import strategyAnlysisImage from "@/assets/strategyAnalysis.webp";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  ScripOptionType,
  brokerOptions,
  getError,
  initialProfileData,
  scripOptions,
  traderTypeOptions,
  traderTypeOptionsType,
} from "@/pages/profiles/editProfile/helper";
import { updateTraderProfile } from "@/module/Profile/api/traderProfile";
import toast from "react-hot-toast";
import {
  traderProfileDataType,
  traderProfileType,
} from "@/module/Profile/interface/ProfileInterface";
import { FormikHelpers, useFormik } from "formik";
import { createTraderProfileValidationSchema } from "@/validation/TraderProfileValidation";
import { useAppDispatch, useAppSelector } from "@/store/reduxHooks";
import { useNavigate } from "react-router-dom";
import CreateStrategyModal from "@/module/dayLevelAnalysis/components/createStrategy/CreateStrategyModal";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import SlideQuickTooltip from "./SlideQuickTooltip";
import { capitalizeFirstLetterOfWords } from "../../profiles/editProfile/helper";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import winzerScoreWings from "@/assets/enjoyWizrWings.svg";
import onboardWidgetIMG from "@/assets/boardingFlowWidget.svg";
import onboardExitIMG from "@/assets/onboard-exit-carousel.webp";
import onboardWidgIMG from "@/assets/widget-carousel-Image.webp";
import { getUser } from "@/module/auth/api/auth";
import Loader from "@/components/loader/Loader";
import { setIsNavigateToDashboard } from "../authSlice";
import { LogoutConfirmationModal } from "./LogoutConfirmationModal";
import topshineIMG from "@/assets/web-top-shine.svg";
import bottomshineIMG from "@/assets/web-bottom-shine.svg";
import blacktopshineIMG from "@/assets/web-black-top-shine.svg";
import blackbottomshineIMG from "@/assets/web-black-bottom-shine.svg";

const BoardingFlowStepper = () => {
  const dispatch = useAppDispatch();
  const boadingFlowWrapperRef = useRef<any>(null);

  const {
    user: { name },
    isNavigateToDashboard,
  } = useAppSelector((state) => state.userState);
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState(1);
  const nextView = () => {
    setCurrentView((prevView) => prevView + 1);
  };
  const prevView = () => {
    setCurrentView((prevView) => prevView - 1);
  };

  //Trader Profile
  const [initialValues, setInitialvalues] = useState(initialProfileData);

  const mutation = useMutation({
    mutationFn: updateTraderProfile,
    onSuccess: (data) => {
      toast.success(<p> Trader profile created successfully!</p>, {
        duration: 2000,
      });
    },
    onError: (error) => {
      toast.error(<p>Error while creating trader profile!</p>, {
        duration: 2000,
      });
    },
  });

  const userQuery = useQuery({
    queryKey: ["user", isNavigateToDashboard],
    queryFn: () => getUser(),
    refetchOnWindowFocus: false,
    enabled: isNavigateToDashboard,
    retry: 0,
  });

  useEffect(() => {
    if (userQuery.isSuccess && userQuery.data) {
      navigate("/");
      window.location.reload();
    }
  }, [userQuery.data]);

  const onSubmit = (
    values: traderProfileDataType,
    actions: FormikHelpers<traderProfileDataType>
  ) => {
    const data: traderProfileType = {
      broker_id: values.brokerId,
      broker_name: values.brokerName,
      trader_type: values.traderType,
      daily_risk_limit_ptg: values.dailyRiskLimitPtg,
      weekly_risk_limit_ptg: values.weeklyRiskLimitPtg,
      trades_per_day: values.tradesPerDay,
      scrip: values.scrip,
      daily_profit_target_ptg: values.dailyProfitTargetPtg,
      weekly_profit_target_ptg: values.weeklyProfitTargetPtg,
      charges_limit_per_day_ptg: values.chargesLimitPerDayPtg,
      account_size: values.accountSize,
      trade_risk_limit_ptg: values.tradeRiskLimitPtg,
      desired_r_multiple: values.desiredReturnMultiple,
    };
    mutation.mutate(data);
    nextView();
    setTimeout(() => {
      actions.setSubmitting(false);
    }, 500);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: createTraderProfileValidationSchema,
  });

  /// Convert charges percentage to charges value and charges percentage to charges value

  const [chargesValue, setChargesValue] = useState(0);

  const convertToChargesPercentage = () => {
    const charges = chargesValue || 0; // Ensure chargesValue is a number
    const accountSize = formik.values.accountSize || 1; // Avoid division by zero
    const percentageValue = ((charges / accountSize) * 100).toFixed(2);
    formik.setFieldValue("chargesLimitPerDayPtg", percentageValue);
  };

  const convertToChargesValue = () => {
    const chargesPercentage = formik.values.chargesLimitPerDayPtg || 0; // Ensure chargesPercentage is a number
    const accountSize = formik.values.accountSize || 1; // Avoid division by zero
    const value = Number(((chargesPercentage * accountSize) / 100).toFixed(2));
    setChargesValue(value);
  };

  const handleKeyPress = (event: any) => {
    const charCode = event.charCode;

    // Allow digits (0-9) and the decimal point (.)
    if (!((charCode >= 48 && charCode <= 57) || charCode === 46)) {
      event.preventDefault();
    }
  };

  const handlevalidateStep1 = () => {
    formik.validateField("brokerName");
    formik.validateField("brokerId");
    formik.setTouched({ brokerId: true, brokerName: true }, true);
    formik.validateForm().then((errors) => {
      if (errors.brokerId || errors.brokerName) {
        return;
      }
      nextView();
    });
  };

  const handleNavigateToHomePage = () => {
    dispatch(setIsNavigateToDashboard(true));
  };

  return (
    <>
      {isNavigateToDashboard ? (
        <Loader />
      ) : (
        <div className="boarding-flow-welcome-screens">
          <div
            className="boarding-flow-wrapper p-0"
            ref={boadingFlowWrapperRef}
          >
            <LogoutConfirmationModal containerRef={boadingFlowWrapperRef} />

            <form
              className="traderProfile-form-field savvy-form-boardingflow flex items-center justify-center"
              onSubmit={formik.handleSubmit}
            >
              {currentView === 1 && (
                <div className="flow flow-one" id="one">
                  <div className="text-logo-wrapper">
                    <div className="logo-text-container">
                      <h2>
                        Congratulations{" "}
                        {capitalizeFirstLetterOfWords(name).trim()},
                      </h2>
                      <h4>
                        You’ve just joined the ranks of savvy traders on Betwizr
                      </h4>
                      <p className="leading-[20px]">
                        We’re here to personalize your experience since your
                        trading style is unique to YOU. Help us to know you
                        better.
                      </p>
                    </div>
                    <div className="stepper">
                      <div className="steps step-one" id="1">
                        1
                      </div>
                      <div className="step-line"></div>
                      <div className="steps step-two">2</div>
                      <div className="step-line"></div>
                      <div className="steps step-three">3</div>
                    </div>
                  </div>

                  <div className="inputs-limit-wrapper">
                    <div className="onboarding-error-input-box">
                      <div className="profit-target all-inputs-box">
                        <h6>Broker Name</h6>

                        <div className="inputs-imgs">
                          <select
                            className="boarding-broker-name-select"
                            name="brokerName"
                            value={formik.values.brokerName}
                            onChange={formik.handleChange}
                          >
                            {brokerOptions.map(
                              (el: traderTypeOptionsType, index: number) => (
                                <option
                                  className="boarding-broker-name-select-option"
                                  key={index}
                                  value={el.value}
                                  disabled={el.disabled}
                                >
                                  {el.label}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="error-message-red">
                        {formik.touched.brokerName &&
                          formik.errors.brokerName && (
                            <p>{formik.errors.brokerName.toString()}</p>
                          )}
                      </div>
                    </div>
                    <div className="onboarding-error-input-box">
                      <div className="profit-target all-inputs-box">
                        <h6>
                          Broker Id{" "}
                          <span className="user-id-used-to-text">
                            (User ID used to login to broker terminal)
                          </span>
                        </h6>

                        <div className="inputs-imgs">
                          <input
                            className="big-input-box"
                            type="text"
                            name="brokerId"
                            onChange={formik.handleChange}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                            value={formik.values.brokerId}
                            placeholder="eg. AB0001"
                          />
                        </div>
                      </div>
                      <div className="error-message-red">
                        {formik.touched.brokerId && formik.errors.brokerId && (
                          <p>{formik.errors.brokerId.toString()}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      className="borading-next-button relative"
                      type="button"
                      onClick={() => handlevalidateStep1()}
                    >
                      <img
                        className="w-[20px] absolute left-[12px] top-[7px]"
                        src={topshineIMG}
                        alt=""
                      />
                      Next
                      <img
                        className="w-[20px] absolute right-[12px] bottom-[7px]"
                        src={bottomshineIMG}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              )}
              {currentView === 2 && (
                <div className="flow flow-two gap-0" id="two">
                  <div className="boarding-flow-inner-trader-profile">
                    <div className="text-logo-wrapper">
                      <div className="logo-text-container">
                        <h2>Trader Profile</h2>
                        <h4>
                          We will track your trading discipline based on limits
                          set by you
                        </h4>
                      </div>
                      <div className="stepper">
                        <div className="steps step-one">1</div>
                        <div className="step-line"></div>
                        <div className="steps step-two" id="steptwo">
                          2
                        </div>
                        <div className="step-line"></div>
                        <div className="steps step-three">3</div>
                      </div>
                    </div>

                    <div className="betwizr-trader-profile-inputs">
                      <div className="inputs-limit-wrapper">
                        <div className="risk-profit">
                          <div className="type-daily info-limit-text">
                            <div className="type">
                              <h6>Type</h6>
                            </div>
                            <div className="day-week-info">
                              <h6 className="daily-heding-marging-right">
                                Daily{" "}
                                <span className="opacity-50 text-sm">
                                  (in %)
                                </span>
                              </h6>
                              <h6>
                                Weekly{" "}
                                <span className="opacity-50 text-sm">
                                  (in %)
                                </span>
                              </h6>
                            </div>
                          </div>
                          <div className="risk-profit-target all-inputs-wrapper">
                            <div className="onboarding-error-input-box">
                              <div className="risk-limit all-inputs-box">
                                <h6>Risk Limit</h6>

                                <div className="inputs-imgs">
                                  <div className="input-width-percent-sign relative">
                                    <input
                                      className="padding-right-input"
                                      name="dailyRiskLimitPtg"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      type="text"
                                      value={formik.values.dailyRiskLimitPtg}
                                      onKeyPress={handleKeyPress}
                                    />
                                    <span className="inline-flex items-center percent-inside-input">
                                      |{"  "}%
                                    </span>
                                  </div>
                                  <div className="web-division-sign text-2xl opacity-50">
                                    |
                                  </div>
                                  <div className="input-width-percent-sign relative">
                                    <input
                                      className="padding-right-input"
                                      type="text"
                                      name="weeklyRiskLimitPtg"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.weeklyRiskLimitPtg}
                                      onKeyPress={handleKeyPress}
                                    />
                                    <span className="inline-flex items-center percent-inside-input">
                                      |{"  "}%
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="error-message-red">
                                {(() => {
                                  const errors = getError(formik.errors);
                                  return (
                                    <>
                                      {(formik.touched.weeklyRiskLimitPtg ||
                                        formik.touched.dailyRiskLimitPtg) && (
                                        <p>
                                          {errors.combinedRiskLimit ||
                                            errors.weeklyRiskLimitPtg ||
                                            errors.dailyRiskLimitPtg}
                                        </p>
                                      )}
                                    </>
                                  );
                                })()}
                              </div>
                            </div>
                            <div className="onboarding-error-input-box">
                              <div className="profit-target all-inputs-box">
                                <h6>Profit Target</h6>

                                <div className="inputs-imgs">
                                  <div className="input-width-percent-sign relative">
                                    <input
                                      className="padding-right-input"
                                      type="text"
                                      name="dailyProfitTargetPtg"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.dailyProfitTargetPtg}
                                      onKeyPress={handleKeyPress}
                                    />
                                    <span className="inline-flex items-center percent-inside-input">
                                      |{"  "}%
                                    </span>
                                  </div>
                                  <div className="web-division-sign text-2xl opacity-50">
                                    |
                                  </div>
                                  <div className="input-width-percent-sign relative">
                                    <input
                                      className="padding-right-input"
                                      type="text"
                                      name="weeklyProfitTargetPtg"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={
                                        formik.values.weeklyProfitTargetPtg
                                      }
                                      onKeyPress={handleKeyPress}
                                    />
                                    <span className="inline-flex items-center percent-inside-input">
                                      |{"  "}%
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="error-message-red">
                                {(() => {
                                  const errors = getError(formik.errors);
                                  return (
                                    <>
                                      {(formik.touched.dailyProfitTargetPtg ||
                                        formik.touched
                                          .weeklyProfitTargetPtg) && (
                                        <p>
                                          {errors.combinedProfitTarget ||
                                            errors.weeklyProfitTargetPtg ||
                                            errors.dailyProfitTargetPtg}
                                        </p>
                                      )}
                                    </>
                                  );
                                })()}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="divide-line"></div>
                        <div className="trade-all">
                          <div className="type-daily info-limit-text">
                            <div className="type">
                              <h6>Type</h6>
                            </div>
                            <div className="day-week-info mr-[60px]">
                              <h6 className="limit-heading-mr-right ">Limit</h6>
                            </div>
                          </div>
                          <div className="risk-profit-target all-inputs-wrapper">
                            <div className="onboarding-error-input-box">
                              <div className="profit-target all-inputs-box">
                                <h6>Account Size</h6>

                                <div className="inputs-imgs">
                                  <div className="input-width-percent-sign relative">
                                    <span className="inline-flex items-center rupees-inside-input">
                                      ₹{"  "}|
                                    </span>
                                    <input
                                      className="big-input-box padding-left-input padding-right-big-input"
                                      type="text"
                                      name="accountSize"
                                      onChange={formik.handleChange}
                                      onBlur={(e) => {
                                        formik.handleBlur(e);
                                        convertToChargesValue();
                                      }}
                                      value={formik.values.accountSize}
                                      onKeyPress={handleKeyPress}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="error-message-red">
                                {formik.touched.accountSize &&
                                  formik.errors.accountSize && (
                                    <p>
                                      {formik.errors.accountSize.toString()}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="onboarding-error-input-box">
                              <div className="risk-limit all-inputs-box">
                                <h6>Trade Level Risk </h6>

                                <div className="inputs-imgs">
                                  <div className="input-width-percent-sign relative">
                                    <input
                                      className="big-input-box padding-right-input padding-left-big-input"
                                      type="text"
                                      name="tradeRiskLimitPtg"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.tradeRiskLimitPtg}
                                      onKeyPress={handleKeyPress}
                                    />
                                    <span className="inline-flex items-center percent-inside-input">
                                      |{"  "}%
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="error-message-red">
                                {formik.touched.tradeRiskLimitPtg &&
                                  formik.errors.tradeRiskLimitPtg && (
                                    <p>
                                      {formik.errors.tradeRiskLimitPtg.toString()}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="onboarding-error-input-box">
                              <div className="profit-target all-inputs-box">
                                <h6>Daily No. of Trades</h6>

                                <div className="inputs-imgs">
                                  <input
                                    className="big-input-box"
                                    type="text"
                                    name="tradesPerDay"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.tradesPerDay}
                                    onKeyPress={handleKeyPress}
                                  />
                                </div>
                              </div>
                              <div className="error-message-red">
                                {formik.touched.tradesPerDay &&
                                  formik.errors.tradesPerDay && (
                                    <p>
                                      {formik.errors.tradesPerDay.toString()}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="onboarding-error-input-box">
                              <div className="profit-target all-inputs-box">
                                <h6>Charges per day ₹/%</h6>

                                <div className="inputs-imgs">
                                  <div className="input-width-percent-sign relative">
                                    <span className="inline-flex items-center rupees-inside-input">
                                      ₹{"  "}|
                                    </span>
                                    <input
                                      className="padding-left-input"
                                      type="text"
                                      value={chargesValue}
                                      onChange={(e) =>
                                        setChargesValue(Number(e.target.value))
                                      }
                                      onBlur={convertToChargesPercentage}
                                      onKeyPress={handleKeyPress}
                                    />
                                  </div>
                                  <div className="web-division-sign text-2xl opacity-50">
                                    |
                                  </div>
                                  <div className="input-width-percent-sign relative">
                                    <input
                                      className="padding-right-input"
                                      type="text"
                                      name="chargesLimitPerDayPtg"
                                      onChange={formik.handleChange}
                                      onBlur={(e) => {
                                        formik.handleBlur(e);
                                        convertToChargesValue();
                                      }}
                                      value={
                                        formik.values.chargesLimitPerDayPtg
                                      }
                                      onKeyPress={handleKeyPress}
                                    />
                                    <span className="inline-flex items-center percent-inside-input">
                                      |{"  "}%
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="error-message-red">
                                {formik.touched.chargesLimitPerDayPtg &&
                                  formik.errors.chargesLimitPerDayPtg && (
                                    <p>
                                      {formik.errors.chargesLimitPerDayPtg.toString()}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="onboarding-error-input-box">
                              <div className="profit-target all-inputs-box">
                                <h6>Scrip Mostly Traded</h6>

                                <div className="inputs-imgs">
                                  <select
                                    className="boarding-scrip-select"
                                    name="scrip"
                                    value={formik.values.scrip}
                                    onChange={formik.handleChange}
                                  >
                                    {scripOptions.map(
                                      (el: ScripOptionType, index: number) => (
                                        <option
                                          className="scrip-option-trade"
                                          key={index}
                                          value={el.value}
                                        >
                                          {el.label}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                              <div className="error-message-red">
                                {formik.touched.scrip &&
                                  formik.errors.scrip && (
                                    <p>{formik.errors.scrip.toString()}</p>
                                  )}
                              </div>
                            </div>
                            <div className="onboarding-error-input-box">
                              <div className="profit-target all-inputs-box">
                                <h6>Desired R:R</h6>

                                <div className="inputs-imgs">
                                  <p className="desired-rr-input-trader-profilefile">
                                    1 :
                                  </p>
                                  <input
                                    type="text"
                                    name="desiredReturnMultiple"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.desiredReturnMultiple}
                                    onKeyPress={handleKeyPress}
                                  />
                                </div>
                              </div>
                              <div className="error-message-red">
                                {formik.touched.desiredReturnMultiple &&
                                  formik.errors.desiredReturnMultiple && (
                                    <p>
                                      {formik.errors.desiredReturnMultiple.toString()}
                                    </p>
                                  )}
                              </div>
                            </div>

                            <div className="onboarding-error-input-box">
                              <div className="profit-target all-inputs-box">
                                <h6>Trader Type</h6>

                                <div className="inputs-imgs">
                                  <select
                                    className="boarding-scrip-select"
                                    name="traderType"
                                    value={formik.values.traderType}
                                    onChange={formik.handleChange}
                                  >
                                    {traderTypeOptions.map(
                                      (
                                        el: traderTypeOptionsType,
                                        index: number
                                      ) => (
                                        <option
                                          key={index}
                                          value={el.value}
                                          className="trader-type-option-trade"
                                          disabled={el.disabled}
                                        >
                                          {el.label}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                              <div className="error-message-red">
                                {formik.touched.traderType &&
                                  formik.errors.traderType && (
                                    <p>{formik.errors.traderType.toString()}</p>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer traderprofile-footer w-[100%]">
                        <div className="onboarding-modal-footer">
                          <button
                            className="borading-next-button onboarding-preview-btn relative"
                            onClick={prevView}
                          >
                            <img
                              className="w-[20px] absolute left-[12px] top-[7px]"
                              src={blacktopshineIMG}
                              alt=""
                            />
                            Prev
                            <img
                              className="w-[20px] absolute right-[12px] bottom-[7px]"
                              src={blackbottomshineIMG}
                              alt=""
                            />
                          </button>
                          <button
                            className="borading-next-button relative"
                            type="submit"
                            disabled={formik.isSubmitting}
                          >
                            <img
                              className="w-[20px] absolute left-[12px] top-[7px]"
                              src={topshineIMG}
                              alt=""
                            />
                            Save
                            <img
                              className="w-[20px] absolute right-[12px] bottom-[7px]"
                              src={bottomshineIMG}
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
            {currentView === 3 && (
              <div className="flow flwo-three" id="three">
                <div className="text-logo-wrapper">
                  <div className="logo-text-container">
                    <div className="book-logo-txt">
                      <h2>
                        📖<span>Set up your</span> Trade Strategies
                      </h2>
                    </div>
                    <h6>
                      Don’t worry you can always change this by going to your
                      Profile Page.
                    </h6>
                  </div>
                  <div className="stepper">
                    <div className="steps step-one">1</div>
                    <div className="step-line"></div>
                    <div className="steps step-two" id="steptwo">
                      2
                    </div>
                    <div className="step-line"></div>
                    <div className="steps step-three" id="stepthree">
                      3
                    </div>
                  </div>
                </div>
                <div className="main-image-text-container">
                  <div className="text-left-container">
                    <div className="text-left-one">
                      <h1 style={{ color: "#FEA338" }}>
                        What is a Strategy setup?
                      </h1>
                      <p>
                        A trading strategy is a set of basic conditions you use
                        to enter and exit a trade. This could be market
                        conditions, trends, technical indicators, patterns etc.
                        This setup over time, becomes your trading signature.
                      </p>
                    </div>
                    <div className="text-left-one">
                      <h1>How will this be used?</h1>
                      <p>We will help you discover your best strategy</p>
                    </div>
                    <div className="text-left-one">
                      <h1>Quick Journalling</h1>
                      <p>
                        Tag strategies used while you exit a trade. Track what
                        rules you followed and what you didnt on a click
                      </p>
                    </div>
                    <div className="text-left-one">
                      <h1>Strategy Analysis</h1>
                      <p>To Compare & Analyze Strategies</p>
                      <p>Learn what’s working for you and what’s not</p>
                    </div>
                  </div>
                  <div className="img-right-container">
                    <img src={strategyAnlysisImage} alt="" />
                  </div>
                </div>

                <div className="modal-footer skip-create-button flex justify-center align-center gap-4">
                  <button
                    className="borading-next-button onboarding-preview-btn relative"
                    onClick={nextView}
                  >
                    <img
                      className="w-[20px] absolute left-[12px] top-[7px]"
                      src={blacktopshineIMG}
                      alt=""
                    />
                    Skip this Step
                    <img
                      className="w-[20px] absolute right-[12px] bottom-[7px]"
                      src={blackbottomshineIMG}
                      alt=""
                    />
                  </button>
                  <CreateStrategyModal
                    customClassName="borading-next-button create-strategy-onboarding-btn"
                    btnText="Create Strategy"
                    source="welcome"
                    handleNextPage={nextView}
                  />
                </div>
              </div>
            )}
            {currentView === 4 && (
              <div className="onboarding-swiper-welcome">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className="onboarding-mySwiper"
                >
                  <SwiperSlide className="onboarding-swiper">
                    <div className="realtime-widget-img-boarding flex flex-col justify-center items-center gap-4">
                      <div className="onboarding-slide1 flex flex-col justify-center items-center ">
                        <h1 className="onboarding-slide-mainheading-quick">
                          📖 Some Quick{" "}
                          <span className="onbording-head-yellow">
                            ToolTips
                          </span>
                        </h1>
                        <p className="how-start-your-text onboarding-slide-subheading mt-4 text-2xl">
                          How to Start your Trading Day?
                        </p>
                        <p className="onboarding-slide-bottom-text mt-1 text-base">
                          Don’t worry you can check quick tips in your profile
                          section!
                        </p>
                      </div>

                      <div className="onboarding-slide-two-line-two w-[80%] mr-[2em]">
                        <img src={onboardWidgIMG} alt="" />
                      </div>
                      <div className="onboarding-slide4 quick-tool-tip-btn">
                        <SlideQuickTooltip />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="onboarding-swiper">
                    <div className="flex flex-col justify-center items-center gap-4 second-last-boarding">
                      <div className="journaling-third-boarding-top onboarding-slide1 flex flex-col justify-center items-center gap-2">
                        <h1 className="onboarding-slide-mainheading-quick">
                          📖 Some Quick{" "}
                          <span className="onbording-head-yellow">
                            ToolTips
                          </span>
                        </h1>
                        <p className="onboarding-slide-subheading text-2xl journaling-text">
                          Journaling made lightning fast
                        </p>
                        <p className="makesure-text-slides  journaling-text">
                          Log your trades for easy reflection
                        </p>
                      </div>

                      <div className="onboarding-slide-three-line-two w-[70%] ml-[8em] mt-[1em] exitproto-image-boarding">
                        <img src={onboardExitIMG} alt="" />
                      </div>
                      <div className="onboarding-slide4 mt-2">
                        <p className="makesure-text-slides">
                          *Make sure you take care of it
                        </p>
                      </div>
                      <div className="quick-tool-tip-btn">
                        <SlideQuickTooltip />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="onboarding-swiper onboarding-fourth-slide">
                    <div className="flex flex-col justify-center items-center gap-4 gap-for-last-screen">
                      <div className="onboarding-slide1 flex flex-col justify-center items-center gap-3">
                        <h1 className="onboarding-slide-mainheading-quick">
                          📖 Some Quick{" "}
                          <span className="onbording-head-yellow">
                            ToolTips
                          </span>
                        </h1>
                        <p className="onboarding-slide-subheading">
                          Let The Magic Begin
                        </p>
                        <p className="onboarding-slide-subheading">
                          Enjoy your Dashboard!
                        </p>
                      </div>
                      <div className="onboarding-slide2">
                        <div>
                          <div className="wizr-wing-onbording mt-[3em] mb-[2em]">
                            <img src={winzerScoreWings} alt="" />
                          </div>
                        </div>
                        <div className="onboarding-slide4">
                          <h1 className="enjoy-logo-bottom-text">
                            <span>Enjoy!</span>
                          </h1>
                          <p className="onboarding-slide-bottom-text">
                            A trading strategy is a set of basic conditions you
                            use to enter and exit a trade.
                          </p>
                          <button
                            className="borading-next-button mt-4 last-screen-button relative"
                            onClick={handleNavigateToHomePage}
                          >
                            <img
                              className="w-[20px] absolute left-[12px] top-[7px]"
                              src={topshineIMG}
                              alt=""
                            />
                            Enter The Dashboard
                            <img
                              className="w-[20px] absolute right-[12px] bottom-[7px]"
                              src={bottomshineIMG}
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BoardingFlowStepper;
