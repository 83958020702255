import React, { useEffect, useRef, useState } from "react";
import dollar from "@/assets/dollar.svg";
import dollarBurn from "@/assets/dollarBurn.svg";
import { DateRange } from "react-day-picker";
import { useAppSelector } from "@/store/reduxHooks";
import { useQuery } from "react-query";
import { getPnL } from "@/module/dashboard/api/insights";
import { addDays, format } from "date-fns";
import { GetPnLResponse } from "@/module/dashboard/interface/insightsInterface";
import { formatRupees } from "../../module/dayLevelAnalysis/components/ExitProtocolHistory/helper";
import { emDash } from "@/utils/constants";
import { CustomErrorBoundary } from "../errorBoundary/CustomErrorBoundary";
import { Tooltip } from "react-tooltip";
import infoIcon from "@/assets/InfoIcon.svg";

const PLInsights = ({ date }: { date: DateRange | undefined }) => {
  const userState = useAppSelector((state) => state.userState);
  const { brokerId, isAuthenticated, isGuidedFlowSeen } = userState;
  const [isApiError, setIsApiError] = useState(false);
  const childRef = useRef<any>(null);

  const pnL = useQuery({
    queryKey: ["pnL", date, brokerId, isAuthenticated],
    queryFn: () => {
      setIsApiError(false);
      return getPnL({
        start_date: date?.from
          ? format(date.from, "yyyy-MM-dd")
          : format(addDays(new Date(), -7), "yyyy-MM-dd"),
        end_date: date?.to
          ? format(date.to, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        broker_id: brokerId,
      });
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && brokerId.length > 0 && isGuidedFlowSeen,
    retry: false,
    onError: (error) => {
      setIsApiError(true);
    },
  });

  const [pnlData, setPnlData] = useState<GetPnLResponse>();

  useEffect(() => {
    if (pnL.isSuccess && pnL.data) {
      const data = pnL.data[0];
      setPnlData(data);
      if (data.trades_count == 0 || data.trades_count == null) {
        setIsApiError(true);
      }
    }
  }, [pnL.data]);

  const [ROI_PERCENTAGE, SET_ROI_PERCENTAGE] = useState(0);

  useEffect(() => {
    if (pnlData && pnlData?.roi !== null && pnlData.total_mtm !== null) {
      const roi_percentage =
        pnlData.total_mtm < 0
          ? 50 + Math.abs(pnlData.roi) / 2 >= 100
            ? 100
            : 50 + Math.abs(pnlData.roi) / 2
          : 50 - Math.abs(pnlData.roi) / 2 < 0
          ? 0
          : 50 - Math.abs(pnlData.roi) / 2;

      SET_ROI_PERCENTAGE(roi_percentage);
    } else if (pnlData && pnlData.roi == null) {
      SET_ROI_PERCENTAGE(50);
    }
  }, [pnlData]);

  return (
    <CustomErrorBoundary
      isError={pnL.isError}
      isLoading={pnL.isLoading}
      error={pnL.error}
      isApiError={isApiError}
      childRef={childRef}
      noData={pnlData?.trades_count ? pnlData.trades_count == 0 : true}
    >
      <div className="roi-section" ref={childRef} id="pl-insight-guide">
        <div className="trade-circle-container">
          <div className="roi-trade-circle">
            <div className="roi-circle">
              <p className="cir-roi-text-web">ROI</p>
              <span
                className="cir-roi-text-web"
                style={{
                  color:
                    pnlData?.total_mtm && pnlData?.total_mtm > 0
                      ? "#295F17"
                      : "#560a0a",
                }}
              >
                {pnlData?.roi ? pnlData.roi : 0} %
              </span>
              <span
                className="cir-roi-text-web"
                style={{
                  color:
                    pnlData?.total_mtm && pnlData?.total_mtm > 0
                      ? "#295F17"
                      : "#ffd5a4",
                }}
              >
                {pnlData?.total_mtm && pnlData?.total_mtm < 0 ? "-" : ""}₹
                {pnlData?.total_mtm
                  ? formatRupees(Math.abs(pnlData.total_mtm))
                  : 0}
              </span>
            </div>
            <div
              id="roi-3d-circle"
              className={
                pnlData?.total_mtm && pnlData.total_mtm > 0
                  ? "roi-inner-circle-positive"
                  : "roi-inner-circle-negative"
              }
              style={{
                top: `${ROI_PERCENTAGE}%`,
              }}
            ></div>
          </div>

          <div className="win-trade-circle">
            <div className="circle">
              <p className="cir-roi-text-web ">Trades</p>
              <span className="cir-roi-text-web ">
                {pnlData?.trades_count ? pnlData.trades_count : 0}
              </span>
            </div>
            <div
              id="win-3d-circle"
              className="inner-circle"
              style={{
                top: `${
                  pnlData?.trades_count && pnlData.trades_count != 0 ? 0 : 100
                }%`,
              }}
            ></div>
          </div>
          <div className="lose-trade-circle">
            <div className="lose-circle">
              <p className="cir-roi-text-web ">Charges</p>
              <span className="cir-roi-text-web ">
                {pnlData?.total_charges_ptg ? pnlData.total_charges_ptg : 0}%
              </span>
              <span className="cir-roi-text-web ">
                ₹
                {pnlData?.total_charges
                  ? formatRupees(pnlData.total_charges)
                  : 0}
              </span>
            </div>
            <div
              id="loss-3d-circle"
              className="lose-inner-circle"
              style={{
                top: `${
                  pnlData?.total_charges_ptg
                    ? pnlData.total_charges_ptg > 100
                      ? 0
                      : 100 - pnlData.total_charges_ptg
                    : 100
                }%`,
              }}
            ></div>
          </div>
        </div>

        <div className="win-lose-wrapper">
          <div className="win-trade ">
            <div className="displayFlexCentreColumn pl-5">
              <img src={dollar} alt="" />
              <p className="flex items-center gap-1 justify-center">
                Win Per Trade{" "}
                <a
                  data-tooltip-id="wpt-tooltip-border"
                  className="cursor-pointer"
                >
                  <img className="w-[16px]" src={infoIcon} alt="" />
                </a>
              </p>

              <Tooltip
                id="wpt-tooltip-border"
                border={"1px solid #ffffff67"}
                className="circle-page-header-tooltip"
                content="The average profit on all winning trades."
                style={{ zIndex: 99 }}
              />
              <span>
                ₹{pnlData?.avg_win ? formatRupees(pnlData.avg_win) : emDash}
              </span>
            </div>
          </div>
          <div className="lose-trade">
            <div className="displayFlexCentreColumn pl-5">
              <img src={dollarBurn} alt="" />
              <p className="flex items-center gap-1 justify-center">
                Loss Per Trade{" "}
                <a
                  data-tooltip-id="lpt-tooltip-border"
                  className="cursor-pointer"
                >
                  <img className="w-[16px]" src={infoIcon} alt="" />
                </a>
              </p>

              <Tooltip
                className="circle-page-header-tooltip"
                id="lpt-tooltip-border"
                border={"1px solid #ffffff67"}
                content="The average loss on all losing trades."
                offset={10}
                style={{ zIndex: 99 }}
              />
              <span>
                ₹{pnlData?.avg_loss ? formatRupees(pnlData.avg_loss) : emDash}
              </span>
            </div>
          </div>
        </div>
      </div>
    </CustomErrorBoundary>
  );
};

export default PLInsights;
