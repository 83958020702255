import Loader from "@/components/loader/Loader";
import { useAppSelector } from "@/store/reduxHooks";
import toast from "react-hot-toast";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const AuthGuard = ({
  children,
  redireactPath = "/login",
}: {
  children: React.ReactElement;
  redireactPath?: string;
}) => {
  const userState = useAppSelector((state) => state.userState);
  const { isLoggedIn, isLoading, isAuthenticated } = userState;
  const redirect = () => {
    toast.error(<p>You should be logged in!</p>, {
      duration: 2000,
      id: "login_error",
    });
    return <Navigate to={redireactPath} replace={true} />;
  };

  return <>{isLoading ? <Loader /> : isLoggedIn ? children : redirect()}</>;
};

const verificationRoutes = [
  "/verifyemail",
  "/verifynumber",
  "/welcome",
  "/consent",
  "/add-widget",
];

const VerifyGuard = ({ children }: { children: React.ReactElement }) => {
  const userState = useAppSelector((state) => state.userState);
  const { user, isAuthenticated } = userState;
  const navigate = useNavigate();
  const location = useLocation();
  const handleVerification = () => {
    if (!verificationRoutes.includes(location.pathname)) {
      if (!user.email_confirmed_at) {
        navigate("/verifyemail");
      } else if (!user.phone_number_confirmed_at) {
        navigate("/verifynumber");
      } else if (!user.consent_seen_flag) {
        navigate("/consent");
      } else if (!user.wg_seen_flag) {
        navigate("/add-widget");
      } else if (!user.profile_set_flag) {
        navigate("/welcome");
      }
    } else {
      if (location.pathname === "/verifyemail" && user.email_confirmed_at) {
        navigate("/");
      }
      if (
        location.pathname === "/verifynumber" &&
        user.phone_number_confirmed_at
      ) {
        navigate("/");
      }
      if (location.pathname === "/consent" && user.consent_seen_flag) {
        navigate("/");
      }
      if (location.pathname === "/add-widget" && user.wg_seen_flag) {
        navigate("/");
      }
      if (location.pathname === "/welcome" && user.profile_set_flag) {
        navigate("/");
      }
    }
    return children;
  };

  return <>{!isAuthenticated ? <Loader /> : handleVerification()}</>;
};

export { AuthGuard, VerifyGuard };
