import { AxiosResponse } from "axios";
import {
  CreateStrategy,
  GetStrategyAnalysisResponse,
  GetStrategyResponse,
} from "../interface/StrategyInterface";
import { apiClient } from "@/utils/api";

const getStrategyAnalysisByStrategyName = async ({
  start_date,
  end_date,
  broker_id,
}: {
  start_date: string;
  end_date: string;
  broker_id: string;
}): Promise<GetStrategyAnalysisResponse[]> => {
  const param = {
    start_date,
    end_date,
    broker_id,
  };

  const res: AxiosResponse<GetStrategyAnalysisResponse[]> = await apiClient.get(
    "/insights/v1/strategy",
    {
      params: param,
    }
  );

  return res.data;
};

const getStrategies = async (): Promise<GetStrategyResponse[]> => {
  const res: AxiosResponse<GetStrategyResponse[]> = await apiClient.get(
    "/user/v1/show-ts"
  );

  return res.data;
};

const getStrategyByName = async (
  strategyName: string
): Promise<GetStrategyResponse[]> => {
  const res: AxiosResponse<GetStrategyResponse[]> = await apiClient.get(
    `/user/v1/show-ts-log/${strategyName}`
  );

  return res.data;
};
const createStrategy = async (
  strategy: CreateStrategy
): Promise<CreateStrategy> => {
  const res: AxiosResponse<GetStrategyResponse> = await apiClient.post(
    "/user/v1/create-ts",
    strategy
  );

  return res.data;
};

const getStrategyAnalysisByStrategyVersion = async ({
  start_date,
  end_date,
  broker_id,
}: {
  start_date: string;
  end_date: string;
  broker_id: string;
}): Promise<GetStrategyAnalysisResponse[]> => {
  const param = {
    start_date,
    end_date,
    broker_id,
  };
  const res: AxiosResponse<GetStrategyAnalysisResponse[]> = await apiClient.get(
    "/insights/v1/strategy-version",
    {
      params: param,
    }
  );

  return res.data;
};

export {
  getStrategyAnalysisByStrategyName,
  getStrategies,
  createStrategy,
  getStrategyByName,
  getStrategyAnalysisByStrategyVersion,
};
