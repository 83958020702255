import Layout from "@/components/Layout";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import "@/pages/strategy/Strategy.css";
import { useEffect, useRef, useState } from "react";
import { DateRange } from "react-day-picker";
import { addDays, format } from "date-fns";
import Header from "@/components/Header";
import { useQuery } from "react-query";
import {
  getStrategies,
  getStrategyAnalysisByStrategyName,
  getStrategyAnalysisByStrategyVersion,
  getStrategyByName,
} from "@/module/strategy/api/strategy";
import {
  GetStrategyAnalysisResponse,
  GetStrategyResponse,
  ModifiedGetStrategyAnalysisResponse,
} from "@/module/strategy/interface/StrategyInterface";
import { useAppSelector } from "@/store/reduxHooks";
import CreateStrategyModal from "@/module/dayLevelAnalysis/components/createStrategy/CreateStrategyModal";
import EditStrategyModal from "@/module/dayLevelAnalysis/components/editStrategyModal.tsx/EditStrategyModal";
import {
  defaultStrategy,
  formatRupees,
} from "@/module/dayLevelAnalysis/components/ExitProtocolHistory/helper";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { CustomErrorBoundary } from "@/components/errorBoundary/CustomErrorBoundary";
import mostProfitWingsImg from "@/assets/mostProfitWings.svg";
import greatJobWingsImg from "@/assets/greatJobWings.svg";
import lowestProfitWingsImg from "@/assets/lowestProfitWings.svg";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { convertToDateRange } from "@/utils/helper";
import strategyfindIcon from "@/assets/strategyAnalysisICON.svg";
import dollarStrategy from "@/assets/dollar-strategy.svg";
import CustomCalendar from "../dashboard/CustomCalendar";

const dummyStrategyAnalysisData = {
  profit_factor: 0,
  ps_score: 0,
  r_r: 1,
  roi: 0,
  strategy: "Dummy",
  text: "Most Profitable",
  total_charges: 0,
  total_charges_ptg: 0,
  total_mtm: 0,
  trades_count: 0,
  win_rate: 0,
  wizr_score: 0,
};

enum tradeType {
  Most_Profitable = "Most Profitable",
  Doing_Great_Job = "Doing Great Job",
  Lowest_Profitable = "Lowest Profitable",
}

const Strategy = () => {
  const { isAuthenticated, brokerId, dateRange, isGuidedFlowSeen } =
    useAppSelector((state) => state.userState);
  const modifiedDateRange: DateRange = convertToDateRange(dateRange);

  const [isApiError, setIsApiError] = useState(false);
  const [isApiError2, setIsApiError2] = useState(false);
  const childRef = useRef<any>(null);
  const [date, setDate] = useState<DateRange | undefined>(modifiedDateRange);

  const [selectedStrategy, setSelectedStrategy] = useState<string>("");
  const [showStrategyAnalysisType, setShowStrategyAnalysisType] =
    useState<any>("byStrategyName");

  const [strategy, setStrategy] = useState<GetStrategyResponse[]>([]);

  const [strategyAnalyisByVersionData, seStrategyAnalyisByVersionData] =
    useState<GetStrategyAnalysisResponse[]>([]);

  const strategyAnalysisByStrategyName = useQuery({
    queryKey: [
      "strategyAnalysisData",
      dateRange,
      brokerId,
      strategy,
      showStrategyAnalysisType,
      isAuthenticated,
    ],
    queryFn: () => {
      setIsApiError2(false);
      return getStrategyAnalysisByStrategyName({
        start_date: modifiedDateRange?.from
          ? format(modifiedDateRange.from, "yyyy-MM-dd")
          : format(addDays(new Date(), -7), "yyyy-MM-dd"),
        end_date: modifiedDateRange?.to
          ? format(modifiedDateRange.to, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        broker_id: brokerId,
      });
    },
    refetchOnWindowFocus: false,
    enabled:
      isAuthenticated &&
      brokerId.length > 0 &&
      showStrategyAnalysisType == "byStrategyName" &&
      strategy.length > 0 &&
      isGuidedFlowSeen,
    retry: 0,
    onError: (error) => {
      setIsApiError2(true);
    },
  });

  const strategyAnalysisByStrategyVersion = useQuery({
    queryKey: [
      "strategyAnalysisByVersionData",
      dateRange,
      brokerId,
      strategy,
      showStrategyAnalysisType,
      isAuthenticated,
    ],
    queryFn: () =>
      getStrategyAnalysisByStrategyVersion({
        start_date: modifiedDateRange?.from
          ? format(modifiedDateRange.from, "yyyy-MM-dd")
          : format(addDays(new Date(), -7), "yyyy-MM-dd"),
        end_date: modifiedDateRange?.to
          ? format(modifiedDateRange.to, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        broker_id: brokerId,
      }),
    refetchOnWindowFocus: false,
    enabled:
      isAuthenticated &&
      brokerId.length > 0 &&
      strategy.length > 0 &&
      isGuidedFlowSeen,
    retry: 0,
  });

  const userTradeStrategies = useQuery({
    queryKey: ["userTradeStrategies", isAuthenticated],
    queryFn: () => {
      setIsApiError(false);
      return getStrategies();
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && isGuidedFlowSeen,
    retry: 0,
    onError: (error) => {
      setIsApiError(true);
    },
  });

  const singleStrategy = useQuery({
    queryKey: ["singleStrategy", selectedStrategy, isAuthenticated],
    queryFn: () => getStrategyByName(selectedStrategy),
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && selectedStrategy.length > 0 && isGuidedFlowSeen,
    retry: 0,
  });

  useEffect(() => {
    if (userTradeStrategies.isSuccess && userTradeStrategies.data) {
      setStrategy(userTradeStrategies.data);
    }
  }, [userTradeStrategies.data]);

  const [strategyAnalysisData, setstrategyAnalysisData] = useState<
    ModifiedGetStrategyAnalysisResponse[]
  >([]);

  useEffect(() => {
    if (
      strategyAnalysisByStrategyName.isSuccess &&
      strategyAnalysisByStrategyName.data
    ) {
      const data = strategyAnalysisByStrategyName.data;
      processStrategyAnalysisData(data);
    }
  }, [strategyAnalysisByStrategyName.data]);

  useEffect(() => {
    if (
      strategyAnalysisByStrategyVersion.isSuccess &&
      strategyAnalysisByStrategyVersion.data
    ) {
      const data = strategyAnalysisByStrategyVersion.data;
      seStrategyAnalyisByVersionData(data);
      if (showStrategyAnalysisType == "byStrategyVersion") {
        processStrategyAnalysisData(data);
      }
      // for latest strategies
      const updatedStrategyArray = strategy.map((strategyObj) => {
        const matchingData = data.find(
          (dataObj) =>
            dataObj.strategy === strategyObj.strategy &&
            dataObj.strategy_version == strategyObj.version
        );

        // Check if a match is found
        if (matchingData) {
          // Add the "pnl" key to the strategy object
          return { ...strategyObj, pnl: matchingData.total_mtm };
        } else {
          // If no match is found, return the original strategy object
          return strategyObj;
        }
      });
      setStrategy(updatedStrategyArray);
    }
  }, [strategyAnalysisByStrategyVersion.data]);

  const processStrategyAnalysisData = (
    strategyAnalysis: GetStrategyAnalysisResponse[]
  ) => {
    const sortedStartegies = strategyAnalysis
      .filter((el) => el.strategy !== defaultStrategy)
      .sort((a, b) => b.wizr_score - a.wizr_score);

    // Filter out objects with negative total_mtm values
    const nonNegativeStrategies = sortedStartegies.filter(
      (strategy) => strategy.total_mtm >= 0
    );

    if (nonNegativeStrategies.length > 0) {
      const highestTotalMtmObject = nonNegativeStrategies.reduce(
        (maxObject, currentObject) => {
          return currentObject.total_mtm > maxObject.total_mtm
            ? currentObject
            : maxObject;
        },
        nonNegativeStrategies[0]
      );

      const lowestTotalMtmObject = nonNegativeStrategies.reduce(
        (minObject, currentObject) => {
          return currentObject.total_mtm < minObject.total_mtm
            ? currentObject
            : minObject;
        },
        nonNegativeStrategies[0]
      );

      const findSecondHighestMtmObject = (
        strategies: GetStrategyAnalysisResponse[]
      ): GetStrategyAnalysisResponse => {
        let highestObject = { ...strategies[0], total_mtm: -Infinity };
        let secondHighestObject = { ...strategies[0], total_mtm: -Infinity };

        for (const strategy of strategies) {
          if (strategy.total_mtm > highestObject.total_mtm) {
            secondHighestObject = highestObject;
            highestObject = strategy;
          } else if (strategy.total_mtm > secondHighestObject.total_mtm) {
            secondHighestObject = strategy;
          }
        }

        return secondHighestObject;
      };

      const secondHighestTotalMtmObject = findSecondHighestMtmObject(
        nonNegativeStrategies
      );

      // Add keys to each object in the sortedStartegies array
      const modifiedSortedStartegies: ModifiedGetStrategyAnalysisResponse[] =
        sortedStartegies.map((strategy) => {
          if (strategy.total_charges >= 0) {
            if (
              strategy.strategy === highestTotalMtmObject.strategy &&
              strategy.strategy_version ==
                highestTotalMtmObject.strategy_version
            ) {
              return { ...strategy, text: tradeType.Most_Profitable };
            } else if (
              strategy.strategy === secondHighestTotalMtmObject.strategy &&
              strategy.strategy_version ==
                secondHighestTotalMtmObject.strategy_version
            ) {
              return { ...strategy, text: tradeType.Doing_Great_Job };
            } else if (
              strategy.strategy === lowestTotalMtmObject.strategy &&
              strategy.strategy_version == lowestTotalMtmObject.strategy_version
            ) {
              return { ...strategy, text: tradeType.Lowest_Profitable };
            } else {
              return strategy;
            }
          } else {
            return strategy;
          }
        });
      if (modifiedSortedStartegies.length > 0) {
        setstrategyAnalysisData(modifiedSortedStartegies);
      } else {
        setstrategyAnalysisData([dummyStrategyAnalysisData]);
      }
    }
  };

  const [strategyVersions, setStrategyVersions] = useState<
    GetStrategyResponse[]
  >([]);
  useEffect(() => {
    if (singleStrategy?.isSuccess && singleStrategy.data) {
      const strategyVersions = singleStrategy.data.sort(
        (a, b) => b.version - a.version
      );
      // for all  strategies versions

      const updatedStrategyVersionsArray = strategyVersions.map(
        (strategyObj) => {
          const matchingData = strategyAnalyisByVersionData.find(
            (dataObj) =>
              dataObj.strategy === strategyObj.strategy &&
              dataObj.strategy_version == strategyObj.version
          );

          // Check if a match is found
          if (matchingData) {
            // Add the "pnl" key to the strategy object
            return { ...strategyObj, pnl: matchingData.total_mtm };
          } else {
            // If no match is found, return the original strategy object
            return { ...strategyObj, pnl: 0 };
          }
        }
      );

      setStrategyVersions(updatedStrategyVersionsArray);
    }
  }, [singleStrategy?.data, strategyAnalyisByVersionData]);

  const handleStrategyVersionClick = (strategy: string) => {
    setSelectedStrategy((prevVersion) =>
      prevVersion === strategy ? "" : strategy
    );
  };

  return (
    <Layout>
      <Header>
        <CustomCalendar date={date} setDate={setDate} />
      </Header>
      <div className="strategy-analysis-section padding-top-main-content">
        <div className="strategy-pageheading displayFlexCentre">
          <div className="calenderLogoText">
            <h1
              className="flex items-center justify-center"
              id="strategy-analysis-guide"
            >
              <img src={dollarStrategy} alt="" /> Strategy Analysis
            </h1>
          </div>
        </div>
        <div className="analysis-select-dropDown">
          <Select
            onValueChange={setShowStrategyAnalysisType}
            defaultValue={showStrategyAnalysisType}
          >
            <SelectTrigger className="broker-id-select-menu-btn">
              <SelectValue placeholder="Select a Broker" />
            </SelectTrigger>
            <SelectContent className="select-content-drop">
              <SelectGroup className="select-broker-popup ">
                <SelectItem value={"byStrategyName"}>
                  Show analysis by strategy name
                </SelectItem>
                <SelectItem value={"byStrategyVersion"}>
                  Show analysis by strategy versions
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            1024: {
              slidesPerView: 2,
              spaceBetween: 50,
            },
            1350: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {strategyAnalysisData?.length > 0 ? (
            <>
              {strategyAnalysisData.map(
                (el: ModifiedGetStrategyAnalysisResponse, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="strategy-card-tag-top">
                        {el.text && (
                          <div
                            className="swiper-inside-card-top-text"
                            style={{
                              color:
                                el.text == tradeType.Most_Profitable
                                  ? "#FF9900"
                                  : el.text == tradeType.Lowest_Profitable
                                  ? "#FF9292"
                                  : el.text == tradeType.Doing_Great_Job
                                  ? "#ADC0E7"
                                  : "white",
                            }}
                          >
                            {el.text}
                          </div>
                        )}

                        <div className="swiper-inside-card-top-sub-text">
                          #{index + 1}. {el.strategy}{" "}
                          {el.strategy_version
                            ? `(v${el.strategy_version})`
                            : ""}
                        </div>
                      </div>
                      <div
                        className="swiper-inside-card"
                        style={{
                          background:
                            el.text == tradeType.Most_Profitable
                              ? "#2a231b"
                              : el.text == tradeType.Lowest_Profitable
                              ? "#271A1A"
                              : el.text == tradeType.Doing_Great_Job
                              ? "#2A2A2A"
                              : "#2a231b",
                        }}
                      >
                        <div className="wizer-score inside-cardlogo">
                          <img
                            src={
                              el.text == tradeType.Most_Profitable
                                ? mostProfitWingsImg
                                : el.text == tradeType.Lowest_Profitable
                                ? lowestProfitWingsImg
                                : el.text == tradeType.Doing_Great_Job
                                ? greatJobWingsImg
                                : mostProfitWingsImg
                            }
                            alt=""
                          />
                          <div className="wizer-score-title-data-wrap">
                            <h3>Wizr Score</h3>
                            <span>{el.wizr_score}</span>
                          </div>
                        </div>
                        <div className="swiper-inside-card-title">
                          <p>Strategy Used in:</p>
                          <span
                            style={{
                              color:
                                el.text == tradeType.Most_Profitable
                                  ? "#FF9900"
                                  : el.text == tradeType.Lowest_Profitable
                                  ? "#FF9292"
                                  : el.text == tradeType.Doing_Great_Job
                                  ? "#ADC0E7"
                                  : "white",
                            }}
                          >
                            Total of {el.trades_count} Trades
                          </span>
                        </div>
                        <div
                          className="profit-charges-wrapper "
                          style={{
                            background:
                              el.text == tradeType.Most_Profitable
                                ? "#39291E"
                                : el.text == tradeType.Lowest_Profitable
                                ? "#37221E"
                                : el.text == tradeType.Doing_Great_Job
                                ? "#303030"
                                : "#39291E",
                            borderColor:
                              el.text == tradeType.Most_Profitable
                                ? "#7E4A1B"
                                : el.text == tradeType.Lowest_Profitable
                                ? "#783232"
                                : el.text == tradeType.Doing_Great_Job
                                ? "#8A8A8A"
                                : "#7E4A1B",
                          }}
                        >
                          <h2>
                            <img src="./profitIcon-coin.svg" alt="" />
                            <span
                              className="profit-span"
                              style={{
                                color:
                                  el.total_mtm >= 0 ? "#FFB763" : "#FF9292",
                              }}
                            >
                              {el.total_mtm >= 0 ? "Profit:" : "Loss:"}
                            </span>{" "}
                            {el.total_mtm >= 0 ? "+" : "-"}₹
                            {formatRupees(Math.abs(el.total_mtm))} ({el.roi}%)
                          </h2>
                          <h2>
                            <img src="./chargesIcon-coin.svg" alt="" />
                            <span className="charges-span">Charges:</span> -₹
                            {formatRupees(el.total_charges)} (
                            {el.total_charges_ptg}%)
                          </h2>
                        </div>
                        <div className="win-rate-percentage-grid">
                          <div
                            className="win-rate-percentage-grid-box "
                            style={{
                              background:
                                el.text == tradeType.Most_Profitable
                                  ? "#39291E"
                                  : el.text == tradeType.Lowest_Profitable
                                  ? "#37221E"
                                  : el.text == tradeType.Doing_Great_Job
                                  ? "#303030"
                                  : "#39291E",
                            }}
                          >
                            <p
                              style={{
                                color:
                                  el.text == tradeType.Most_Profitable
                                    ? "#FF9900"
                                    : el.text == tradeType.Lowest_Profitable
                                    ? "#FF9292"
                                    : el.text == tradeType.Doing_Great_Job
                                    ? "#ADC0E7"
                                    : "white",
                              }}
                            >
                              Win Rate{" "}
                            </p>
                            <span>{el.win_rate}%</span>
                          </div>

                          <div
                            className="win-rate-percentage-grid-box"
                            style={{
                              background:
                                el.text == tradeType.Most_Profitable
                                  ? "#39291E"
                                  : el.text == tradeType.Lowest_Profitable
                                  ? "#37221E"
                                  : el.text == tradeType.Doing_Great_Job
                                  ? "#303030"
                                  : "#39291E",
                            }}
                          >
                            <p
                              style={{
                                color:
                                  el.text == tradeType.Most_Profitable
                                    ? "#FF9900"
                                    : el.text == tradeType.Lowest_Profitable
                                    ? "#FF9292"
                                    : el.text == tradeType.Doing_Great_Job
                                    ? "#ADC0E7"
                                    : "white",
                              }}
                            >
                              Profit Factor{" "}
                            </p>
                            <span>{el.profit_factor}</span>
                          </div>
                          <div
                            className="win-rate-percentage-grid-box"
                            style={{
                              background:
                                el.text == tradeType.Most_Profitable
                                  ? "#39291E"
                                  : el.text == tradeType.Lowest_Profitable
                                  ? "#37221E"
                                  : el.text == tradeType.Doing_Great_Job
                                  ? "#303030"
                                  : "#39291E",
                            }}
                          >
                            <p
                              style={{
                                color:
                                  el.text == tradeType.Most_Profitable
                                    ? "#FF9900"
                                    : el.text == tradeType.Lowest_Profitable
                                    ? "#FF9292"
                                    : el.text == tradeType.Doing_Great_Job
                                    ? "#ADC0E7"
                                    : "white",
                              }}
                            >
                              RR{" "}
                            </p>
                            <span>1:{el.r_r}</span>
                          </div>
                          <div
                            className="win-rate-percentage-grid-box"
                            style={{
                              background:
                                el.text == tradeType.Most_Profitable
                                  ? "#39291E"
                                  : el.text == tradeType.Lowest_Profitable
                                  ? "#37221E"
                                  : el.text == tradeType.Doing_Great_Job
                                  ? "#303030"
                                  : "#39291E",
                            }}
                          >
                            <p
                              style={{
                                color:
                                  el.text == tradeType.Most_Profitable
                                    ? "#FF9900"
                                    : el.text == tradeType.Lowest_Profitable
                                    ? "#FF9292"
                                    : el.text == tradeType.Doing_Great_Job
                                    ? "#ADC0E7"
                                    : "white",
                              }}
                            >
                              Psychology
                            </p>
                            <span>{el.ps_score}%</span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                }
              )}
            </>
          ) : (
            <SwiperSlide className="no-data-available-swiper-slides">
              <div className="no-data-stratehy-slides flex flex-col gap-4 items-center justify-center">
                <div className="no-strategy-icon">
                  <img src={strategyfindIcon} alt="" />
                </div>

                <p>
                  The date will appear, once we get more insights. Please update
                  more Exit Protocols.
                </p>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
      </div>

      <div className="container-table">
        <div
          className="strategy-table-button-wrap w-[300px]"
          id="strategy-table-guide"
        >
          <span>Strategy</span>
          <CreateStrategyModal btnText="Create Strategy" />
        </div>
        <div>
          <CustomErrorBoundary
            isError={userTradeStrategies.isError}
            isLoading={userTradeStrategies.isLoading}
            error={userTradeStrategies.error}
            isApiError={isApiError}
            childRef={childRef}
          >
            <div className="responsive-table-container" ref={childRef}>
              <table className="responsive-table ">
                <thead className="responsive-table__head first-row-border">
                  <tr className="responsive-table__row">
                    <th className="responsive-table__head__title responsive-table__head__title--name">
                      Strategy Name
                    </th>
                    <th className="responsive-table__head__title responsive-table__head__title--name">
                      Version
                    </th>

                    <th className="responsive-table__head__title responsive-table__head__title--entry">
                      Entry Criteria
                    </th>
                    <th className="responsive-table__head__title responsive-table__head__title--exit">
                      Exit Criteria
                    </th>
                    <th className="responsive-table__head__title responsive-table__head__title--netpnl">
                      Net PnL
                    </th>
                    <th className="responsive-table__head__title responsive-table__head__title--edit"></th>
                  </tr>
                </thead>
                {/* <!-- Responsive Table Body Section --> */}
                <tbody className="responsive-table__body ">
                  {strategy.length > 0 ? (
                    <>
                      {strategy.map(
                        (el: GetStrategyResponse, index: number) => {
                          return (
                            <>
                              <tr
                                className={`responsive-table__row table-class-odd-color`}
                              >
                                <td className="responsive-table__body__text responsive-table__body__text--name">
                                  <img src="./grip-vertical.svg" alt="" />
                                  <span>{el.strategy} </span>
                                  {el.version !== 1 && (
                                    <p
                                      onClick={() =>
                                        handleStrategyVersionClick(el.strategy)
                                      }
                                    >
                                      {selectedStrategy.length > 0 &&
                                      selectedStrategy === el.strategy ? (
                                        <MdOutlineKeyboardArrowUp
                                          style={{ cursor: "pointer" }}
                                        />
                                      ) : (
                                        <MdOutlineKeyboardArrowDown
                                          style={{ cursor: "pointer" }}
                                        />
                                      )}
                                    </p>
                                  )}
                                </td>
                                <td className="responsive-table__body__text responsive-table__body__text--name strategy-version">
                                  <img src="./grip-vertical.svg" alt="" />
                                  <span>
                                    <>{el.version}</>
                                  </span>
                                </td>

                                <td className="responsive-table__body__text responsive-table__body__text--entry">
                                  {el.entry_criteria
                                    ? el.entry_criteria.map(
                                        (el: string, index: number) => (
                                          <p>
                                            {index + 1}.{el}
                                          </p>
                                        )
                                      )
                                    : ""}
                                </td>
                                <td className="responsive-table__body__text responsive-table__body__text--exit">
                                  {el.exit_criteria
                                    ? el.exit_criteria.map(
                                        (el: string, index: number) => (
                                          <p>
                                            {index + 1}.{el}
                                          </p>
                                        )
                                      )
                                    : ""}
                                </td>
                                <td className="responsive-table__body__text responsive-table__body__text--netpnl flex items-center">
                                  {el.pnl ? (el.pnl >= 0 ? "+" : "-") : ""}₹{" "}
                                  {el.pnl ? formatRupees(Math.abs(el.pnl)) : 0}
                                </td>
                                <td className="responsive-table__body__text responsive-table__body__text--edit edit-down-up-strategy-table-btn">
                                  <EditStrategyModal editStrategy={el} />
                                </td>
                              </tr>
                              {selectedStrategy?.length > 0 &&
                                selectedStrategy === el.strategy && (
                                  <div className="table-class-even-color">
                                    {strategyVersions
                                      .filter((e) => e.version !== el.version)
                                      .sort((a, b) => b.version - a.version)
                                      .map((item) => (
                                        <div
                                          className="border-top-in-version"
                                          key={`${
                                            item.strategy + item.version
                                          }`}
                                        >
                                          <tr className="responsive-table__row border-b-[0.5px] border-[#1a1a1ab7]">
                                            <td className="responsive-table__body__text responsive-table__body__text--name">
                                              <img
                                                src="./grip-vertical.svg"
                                                alt=""
                                              />
                                              <span>{item.strategy} </span>
                                            </td>
                                            <td
                                              className="responsive-table__body__text responsive-table__body__text--name"
                                              onClick={() =>
                                                handleStrategyVersionClick(
                                                  el.strategy
                                                )
                                              }
                                            >
                                              <img
                                                src="./grip-vertical.svg"
                                                alt=""
                                              />
                                              <span>
                                                <>{item.version}</>
                                              </span>
                                            </td>

                                            <td className="responsive-table__body__text responsive-table__body__text--entry">
                                              {item.entry_criteria
                                                ? item.entry_criteria.map(
                                                    (
                                                      elem: string,
                                                      index: number
                                                    ) => (
                                                      <p>
                                                        {index + 1}.{elem}
                                                      </p>
                                                    )
                                                  )
                                                : ""}
                                            </td>
                                            <td className="responsive-table__body__text responsive-table__body__text--exit">
                                              {item.exit_criteria
                                                ? item.exit_criteria.map(
                                                    (
                                                      elem: string,
                                                      index: number
                                                    ) => (
                                                      <p>
                                                        {index + 1}.{elem}
                                                      </p>
                                                    )
                                                  )
                                                : ""}
                                            </td>
                                            <td className="responsive-table__body__text responsive-table__body__text--netpnl flex items-center">
                                              {item.pnl
                                                ? item.pnl >= 0
                                                  ? "+"
                                                  : "-"
                                                : ""}
                                              ₹{" "}
                                              {item.pnl
                                                ? formatRupees(
                                                    Math.abs(item.pnl)
                                                  )
                                                : 0}
                                            </td>
                                          </tr>
                                        </div>
                                      ))}
                                  </div>
                                )}
                            </>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <>No strategy created till now, please create one.</>
                  )}
                </tbody>
              </table>
            </div>
          </CustomErrorBoundary>
        </div>
      </div>
    </Layout>
  );
};

export default Strategy;
