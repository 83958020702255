import { GetAllBrokersResponse, User } from "@/module/auth/api/auth";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addDays } from "date-fns";
import Cookie from "js-cookie";

export type DateRangeType = {
  from: string;
  to: string;
};

type InitialState = {
  isLoggedIn: boolean;
  isLoading: boolean;
  brokerId: string;
  isAuthenticated: boolean;
  user: User;
  isEmailOtpSent: boolean;
  isPhOtpSent: boolean;
  brokersList: GetAllBrokersResponse[];
  singleDate: string;
  dateRange: DateRangeType;
  isNavigateToDashboard: boolean;
  isGuidedFlowSeen: boolean;
};

const initState: InitialState = {
  isLoggedIn: false,
  isLoading: true,
  brokerId: "",
  isAuthenticated: false,
  user: {
    name: "User",
    go_seen_flag: false,
    wg_seen_flag: false,
  },
  isEmailOtpSent: false,
  isPhOtpSent: false,
  brokersList: [],
  singleDate: new Date().toISOString(),
  dateRange: {
    from: addDays(new Date(), -7).toISOString(),
    to: new Date().toISOString(),
  },
  isNavigateToDashboard: false,
  isGuidedFlowSeen: false,
};

export const getItemFromLocalStorage = (name: string) => {
  const jsonData =
    typeof window === "undefined" ? null : localStorage.getItem(name);
  const data = jsonData ? JSON.parse(jsonData) : null;
  return data;
};

const unsetUser = () => {
  Cookie.remove("auth-token");
  localStorage.removeItem("isEmailOtpSent");
  localStorage.removeItem("isPhOtpSent");
  localStorage.removeItem("user");
};
const authSlice = createSlice({
  name: "auth",
  initialState: initState,
  reducers: {
    setAuth: (state, action: PayloadAction<boolean>) => {
      state.isLoading = false;
      state.isLoggedIn = action.payload;
    },
    logOutUser: (state) => {
      unsetUser();
      state.isLoggedIn = false;
      state.brokerId = "";
      state.isAuthenticated = false;
      state.isEmailOtpSent = false;
      state.isPhOtpSent = false;
      state.brokersList = [];
      state.singleDate = new Date().toISOString();
      state.dateRange = {
        from: addDays(new Date(), -7).toISOString(),
        to: new Date().toISOString(),
      };
      state.user = {
        name: "User",
        go_seen_flag: false,
        wg_seen_flag: false,
      };
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.isGuidedFlowSeen = action.payload.go_seen_flag;
    },
    setBroker: (state, action: PayloadAction<string>) => {
      state.brokerId = action.payload;
    },
    setEmailOtpState: (state) => {
      state.isEmailOtpSent = true;
      localStorage.setItem("isEmailOtpSent", JSON.stringify(true));
    },
    setPhOtpState: (state) => {
      state.isPhOtpSent = true;
      localStorage.setItem("isPhOtpSent", JSON.stringify(true));
    },

    setBrokersList: (state, action: PayloadAction<GetAllBrokersResponse[]>) => {
      state.brokersList = action.payload;
    },
    setSingleDate: (state, action: PayloadAction<string>) => {
      state.singleDate = action.payload;
    },
    setDateRange: (state, action: PayloadAction<DateRangeType>) => {
      state.dateRange = {
        from: action.payload.from,
        to: action.payload.to,
      };
    },
    setIsNavigateToDashboard: (state, action: PayloadAction<boolean>) => {
      state.isNavigateToDashboard = action.payload;
    },
  },
});
export default authSlice.reducer;
export const {
  logOutUser,
  setAuth,
  setUser,
  setBroker,
  setEmailOtpState,
  setPhOtpState,
  setBrokersList,
  setSingleDate,
  setDateRange,
  setIsNavigateToDashboard,
} = authSlice.actions;
