import { useRef } from "react";
import "../welcomePages/boardingflowstepper.css";
import { LogoutConfirmationModal } from "../welcomePages/LogoutConfirmationModal";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { updateWidgetStatus } from "@/module/auth/api/auth";
import { useNavigate } from "react-router-dom";
import "@/pages/auth/consentScreen/consentscreen.css";
import topshineIMG from "@/assets/web-top-shine.svg";
import bottomshineIMG from "@/assets/web-bottom-shine.svg";
import { useAppDispatch, useAppSelector } from "@/store/reduxHooks";
import { setIsNavigateToDashboard } from "../authSlice";
import onboardWidgetIMG from "@/assets/boardingFlowWidget.svg";
import "@/pages/auth/addWidget/addwidgetmodal.css";

const AddWidget = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userState = useAppSelector((state) => state.userState);
  const { user } = userState;
  const addWidgetRef = useRef<any>(null);
  const queryClient = useQueryClient();
  const updateAddWidgetStatus = useMutation({
    mutationFn: updateWidgetStatus,
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        queryClient.invalidateQueries("user");

        if (!user.profile_set_flag) {
          navigate("/welcome");
        } else {
          dispatch(setIsNavigateToDashboard(true)); // loader while navigate to dashboard
        }
      }
    },
    onError: (error) => {
      toast.error(<p>Something went wrong!Please try again!</p>, {
        duration: 2000,
      });
    },
  });

  const handleWidgetConfirmation = () => {
    updateAddWidgetStatus.mutate();
  };

  // Open the link in a new tab
  const handleAddWidget = () => {
    window.open(
      "https://chromewebstore.google.com/detail/betwizr-trade-better-ever/bmecaabjbjbmaijnikgfldalkcfcdnkd?hl=en-GB&authuser=0&pli=1",
      "_blank"
    );
  };

  return (
    <>
      <div
        className="flex flex-col justify-center items-center onboarding-inner-content min-h-[100vh] text-[#fff] p-20"
        ref={addWidgetRef}
      >
        <LogoutConfirmationModal containerRef={addWidgetRef} />
        <div className="add-widget-confirmation-container flex flex-col justify-center items-center gap-4 bg-[#262626] p-7 px-20 rounded-[40px]">
          <div className="onboarding-slide1 flex flex-col justify-center items-center gap-4">
            <h1 className="onboarding-slide-mainheading-quick">
              Enable the <span className="onbording-head-yellow">Co-Pilot</span>
            </h1>
            <p className="onboarding-slide-subheading w-[55%] text-center">
              Kick off your journey with Betwizr by ensuring the widget is
              included and activated in your extensions.
            </p>
          </div>

          <div className="onboarding-slide-line-two w-[80%] mt-[2em] mb-[1em] mr-[3em]">
            <img src={onboardWidgetIMG} alt="" />
          </div>
          <div className="onboarding-slide-line-three flex flex-col justify-center items-center gap-2">
            <p className="onboarding-slide-subheading  w-[55%] text-center">
              Tap the button below to effortlessly add the widget and embark on
              your adventure!
            </p>

            <div className="confirm-widget-btn flex flex-col items-center justify-center gap-3">
              <button
                className="borading-next-button mt-1 relative"
                onClick={handleAddWidget}
              >
                <img
                  className="w-[20px] absolute left-[12px] top-[7px]"
                  src={topshineIMG}
                  alt=""
                />
                Add the widget
                <img
                  className="w-[20px] absolute right-[12px] bottom-[7px]"
                  src={bottomshineIMG}
                  alt=""
                />
              </button>
              <button
                className="mt-1 text-[#fba137] underline text-xl"
                onClick={handleWidgetConfirmation}
              >
                I have added the widget, proceed
              </button>
            </div>
            <p className="onboarding-slide-bottom-text text-center">
              *You can’t skip this step as the extension is necessary to access
              all the features
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddWidget;
