import { ReactNode } from "react";
import { CalendarIcon } from "@radix-ui/react-icons";
import { format } from "date-fns";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import datecalenderdropdownIcon from "@/assets/date-calender-dropdown-icon.png";
const DatePicker = ({
  date,
  setDate,
  children,
  open,
  setOpen,
  selectedDate,
}: {
  date: Date | undefined;
  setDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  children?: ReactNode;
  open: boolean;
  setOpen: (value: boolean) => void;
  selectedDate: Date | undefined;
}) => {
  return (
    <Popover
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (!open) {
          setDate(selectedDate);
        }
      }}
    >
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            " justify-start text-left font-normal on-hover-bg-date-pik-btn",
            !selectedDate && "text-muted-foreground"
          )}
          style={{ width: "15vw" }}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {selectedDate ? (
            format(selectedDate, "PPP")
          ) : (
            <span>Pick a date</span>
          )}
          <img src={datecalenderdropdownIcon} alt="" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0 calender-popover" align="start">
        <Calendar
          className="dropdown-calender-popup"
          mode="single"
          selected={date}
          onSelect={setDate}
          defaultMonth={date}
          initialFocus
          fixedWeeks
          disabled={{ after: new Date() }}
        />
        {children}
      </PopoverContent>
    </Popover>
  );
};

export default DatePicker;
