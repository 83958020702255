import { Dispatch, SetStateAction, useEffect, useState } from "react";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import moment from "moment";
import { DateRange } from "react-day-picker";
import { useAppDispatch } from "@/store/reduxHooks";
import { setDateRange } from "../auth/authSlice";
import { format } from "date-fns";
import { Calendar } from "lucide-react";

export default function CustomCalendar({
  date,
  setDate,
}: {
  date: DateRange | undefined;
  setDate: Dispatch<SetStateAction<DateRange | undefined>>;
}) {
  const dispatch = useAppDispatch();
  const now = new Date();
  const today = moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
  );

  const weekAfter = moment(today).add(7, "days");

  const [from, setFrrom] = useState(moment(date?.from));
  const [to, setTo] = useState(moment(date?.to));

  const ranges = {
    "last 7 days": [moment(today).subtract(7, "days"), moment(today)],
    "last 30 days": [moment(today).subtract(30, "days"), moment(today)],
    "last 90 days": [moment(today).subtract(90, "days"), moment(today)],
  };

  const local = {
    format: "YYYY-MM-DD",
    sundayFirst: true,
  };

  function closePicker() {
    const daterangepicker = document.getElementById("daterangepicker");
    if (daterangepicker) {
      daterangepicker.classList.remove("daterangepicker-layout-open");
      daterangepicker.classList.add("daterangepicker-layout-close");
    }
  }

  const applyCallback = (startDate: any, endDate: any) => {
    setFrrom(startDate);
    setTo(endDate);

    const formatdate = {
      from: moment(startDate).toDate(),
      to: moment(endDate).toDate(),
    };

    setDate(formatdate);
    dispatch(
      setDateRange({
        from: formatdate.from
          ? formatdate.from?.toISOString()
          : date?.from
          ? date?.from.toISOString()
          : today.toDate().toISOString(),
        to: formatdate.to
          ? formatdate.to?.toISOString()
          : date?.to
          ? date?.to.toISOString()
          : weekAfter.toDate().toISOString(),
      })
    );

    closePicker();
  };

  useEffect(() => {
    // Find the button with class 'applyButton'
    const button = document.querySelector(".applyButton") as HTMLElement;
    // If the button exists, change its inner text
    if (button) {
      button.innerText = "Confirm"; // changing the text of button from Apply to confirm
    }

    const cancelButton = document.querySelector(".cancelButton") as HTMLElement;

    document.addEventListener("click", closePicker);

    if (cancelButton) {
      cancelButton.addEventListener("click", closePicker);
    }

    return () => {
      cancelButton.removeEventListener("click", closePicker);
      document.removeEventListener("click", closePicker);
    };
  }, []);

  // for opening and closing of calender on click of input box
  const toggleVisibility = (e: any) => {
    const daterangepicker = document.getElementById("daterangepicker");
    if (daterangepicker) {
      if (
        !daterangepicker.classList.contains("daterangepicker-layout-open") &&
        !daterangepicker.classList.contains("daterangepicker-layout-close")
      ) {
        daterangepicker.classList.add("daterangepicker-layout-open");
      } else if (
        daterangepicker.classList.contains("daterangepicker-layout-open")
      ) {
        closePicker();
      } else if (
        daterangepicker.classList.contains("daterangepicker-layout-close")
      ) {
        daterangepicker.classList.remove("daterangepicker-layout-close");
        daterangepicker.classList.add("daterangepicker-layout-open");
      }
    }
  };

  return (
    <DateTimeRangeContainer
      ranges={ranges}
      start={from}
      end={to}
      local={local}
      applyCallback={applyCallback}
      twelveHoursClock={true}
      darkMode={true}
      style={{
        fromDate: {
          color: "#181818",
          backgroundColor: "#fba137",
        },
        toDate: { color: "#181818", backgroundColor: "#fba137" },
        betweenDates: {
          color: "#181818",
          backgroundColor: "#fba137",
        },
        hoverCell: {
          color: "#181818",
          backgroundColor: "#fff",
        },
        customRangeButtons: {
          backgroundColor: "transparent",
          color: "#fba137",
          borderColor: "#777578b2",
        },
        customRangeSelected: {
          backgroundColor: "transparent",
          color: "#fba137",
          borderColor: "#777578b2",
        },
      }}
    >
      <div className="calender-input-date-range flex items-center justify-center px-2">
        <Calendar color="#fba137" />
        <input
          id="formControlsTextB"
          type="text"
          placeholder={`${format(from.toDate(), "yyyy-MM-dd")} - ${format(
            to.toDate(),
            "yyyy-MM-dd"
          )}`}
          onClick={toggleVisibility}
          className="text-[#fff] border-none-for-input"
        />
      </div>
    </DateTimeRangeContainer>
  );
}
