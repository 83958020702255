import { useEffect, useState } from "react";
import "./verifyuser.css";
import { Button } from "@/components/ui/button";
import OtpInput from "react-otp-input";
import { useAppDispatch, useAppSelector } from "@/store/reduxHooks";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { sendEmailOtp, verifyEmailOtp } from "@/module/auth/api/auth";
import { useNavigate } from "react-router";
import { setEmailOtpState } from "../authSlice";
import betLogo from "@/assets/bet-logo.svg";
import topshineIMG from "@/assets/web-top-shine.svg";
import bottomshineIMG from "@/assets/web-bottom-shine.svg";

const errorPattern =
  /HTTP 404 error: Unable to create record: The requested resource \/Services\/([A-Za-z0-9]+)\/VerificationCheck was not found/;

const VerifyEmail = () => {
  const { user } = useAppSelector((store) => store.userState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isEmailOtpSent } = useAppSelector((state) => state.userState);
  const [otp, setOtp] = useState<string>("");
  const [resentOtp, setResentOtp] = useState(false);
  const handleOTPChange = (otp: string) => {
    setOtp(otp);
  };

  const emailOtp = useMutation({
    mutationFn: sendEmailOtp,
    onSuccess: (data) => {
      if (data.status == "SUCCESS") {
        toast.success(<p>Email is already verified !</p>, {
          duration: 2000,
        });
        navigate("/verifynumber");
        return;
      }
      if (data.status == "PENDING") {
        if (resentOtp) {
          setResentOtp(false);
        }
        toast.success(
          <p>
            Otp has been sent to registered email! Please check your email!
          </p>,
          {
            duration: 2000,
          }
        );
        dispatch(setEmailOtpState());
      }
    },
    onError: (error) => {
      toast.error(<p>Something went wrong!Please try again!</p>, {
        duration: 2000,
      });
    },
  });

  const emailOtpVerification = useMutation({
    mutationFn: verifyEmailOtp,
    onSuccess: (data) => {
      if (data.status == "SUCCESS") {
        toast.success(<p>Email has been verified Successfully!</p>, {
          duration: 2000,
        });
        queryClient.invalidateQueries("user");
        navigate("/verifynumber");
      }
      if (data.status == "PENDING") {
        toast.success(<p>Invalid Otp!</p>, {
          duration: 2000,
        });
      }
    },
    onError: (error: any) => {
      const match = errorPattern.exec(error.response?.data);

      if (error && error.response && error.response.data && match) {
        toast.error(<p>Otp has been expired!</p>, {
          duration: 2000,
        });
        setResentOtp(true);
        return;
      }
      toast.error(<p>Something went wrong!Please try again!</p>, {
        duration: 2000,
      });
    },
  });

  const haldleEmailVerification = () => {
    emailOtpVerification.mutate(otp);
  };

  useEffect(() => {
    if (!isEmailOtpSent && !user.email_confirmed_at) {
      emailOtp.mutate();
    }
  }, []);

  const onlyNumberKey = (event: any) => {
    if (event.which < 48 || event.which > 57) {
      event.preventDefault();
    }
  };

  return (
    <div className="verify-user-page">
      <div className="dialog-content">
        <div className="verify-email-otp-logo-text flex items-center justify-center">
          <div className="logo-img varify-email-otp-logo ">
            <img src={betLogo} alt="" />
          </div>
        </div>
        <p className="verify-email-head">Verify your Email</p>
        <p className="verify-email-second-head">
          We have sent you a verification code, to the registered email. Please
          enter it.
        </p>

        <OtpInput
          value={otp}
          onChange={handleOTPChange}
          numInputs={6}
          inputType="text"
          renderSeparator={<span></span>}
          shouldAutoFocus
          renderInput={(props) => (
            <input
              onKeyPress={onlyNumberKey}
              {...props}
              className="otp-input"
              style={{
                borderBottom: "2px solid #ccc",
                width: "50px",
                height: "60px",
                fontSize: "40px",
                textAlign: "center",
                outline: "none",
                color: "white",
                background: "transparent",
              }}
            />
          )}
          containerStyle={{
            display: "flex",
            justifyContent: "space-around",
            margin: "30px 0",
          }}
        />
        <div className="verify-and-resend-btn flex items-center justify-center gap-4">
          <Button
            onClick={haldleEmailVerification}
            className="email-otp-verify-button relative"
          >
            <img
              className="w-[20px] absolute left-[10px] top-[6px]"
              src={topshineIMG}
              alt=""
            />
            Verify
            <img
              className="w-[20px] absolute right-[10px] bottom-[6px]"
              src={bottomshineIMG}
              alt=""
            />
          </Button>
          {resentOtp && (
            <Button
              className="email-otp-verify-button resend-otp-button"
              onClick={() => {
                emailOtp.mutate();
                setOtp("");
              }}
            >
              Resend Otp
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
