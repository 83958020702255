import { apiClient } from "@/utils/api";
import type { AxiosResponse } from "axios";
import { UTCTimestamp } from "lightweight-charts";

export interface GetExitProtocolHistoryResponseModified
  extends GetExitProtocolHistoryResponse {
  time: UTCTimestamp;
}
export interface GetExitProtocolHistoryResponse {
  tradingsymbol: string;
  scrip: string;
  average_traded_qty: number;
  trade_type: string;
  trade_points: number;
  trade_mtm: number;
  trade_roi: number;
  trade_duration: number;
  average_entry_price: number;
  average_exit_price: number;
  trade_id: string;
  trade_outcome: string;
  trade_start_time: string;
  broker_id: string;
  emotion: string | null;
  strategy: string | null;
  entry_criteria: string[] | null;
  exit_criteria: string[] | null;
  trade_plan_followed: string | null;
  exit_reason: string | null;
  notes: string[] | null;
  trade_end_time: string;
  trade_date: string;
  strategy_version: number;
}

const getExitProtocolHistory = async ({
  start_date,
  end_date,
  broker_id,
}: {
  start_date: string;
  end_date: string;
  broker_id?: string;
}): Promise<GetExitProtocolHistoryResponse[]> => {
  const param = {
    start_date,
    end_date,
    broker_id,
  };
  if (!broker_id) {
    delete param?.broker_id;
  }
  const res: AxiosResponse<GetExitProtocolHistoryResponse[]> =
    await apiClient.get("/insights/v1/show-journal", {
      params: param,
    });

  return res.data;
};

const updateExitProtocol = async (
  editExitProtocol: Partial<GetExitProtocolHistoryResponse>
) => {
  const response = await apiClient.post(
    "/widget/v1/upload-ep",
    editExitProtocol
  );
  return response;
};

export { getExitProtocolHistory, updateExitProtocol };
