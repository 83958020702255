import { resetPassword } from "@/module/auth/api/auth";
import { ResetPasswordInterface } from "@/module/auth/interface/AuthInterface";
import { resetPasswordValidation } from "@/validation/AuthValidation";
import { FormikHelpers, useFormik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { FiEyeOff, FiEye } from "react-icons/fi";
import OtpInput from "react-otp-input";
import "@/pages/auth/forgotPassword.tsx/resetpassword.css";

const errorPattern =
  /HTTP 404 error: Unable to create record: The requested resource \/Services\/([A-Za-z0-9]+)\/VerificationCheck was not found/;

const ResetPassword = () => {
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    code: "",
    new_password: "",
    confirm_new_password: "",
  };
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState<boolean>(true);

  const mutation = useMutation({
    mutationFn: resetPassword,
    onSuccess: (data) => {
      toast.success(<p>Password has been reset Successfully!</p>, {
        duration: 2000,
      });
      navigate("/login");
    },
    onError: (error: any) => {
      const match = errorPattern.exec(error.response?.data);

      if (error && error.response && error.response.data && match) {
        toast.error(<p>Otp has been expired Please try again!</p>, {
          duration: 2000,
        });
        navigate("/sendresetotp");
        return;
      }
      toast.error(<p>Something went wrong!Please try again!</p>, {
        duration: 2000,
      });
    },
  });

  const onSubmit = async (
    values: ResetPasswordInterface,
    actions: FormikHelpers<ResetPasswordInterface>
  ) => {
    const data = {
      email: values.email,
      code: values.code,
      new_password: values.new_password,
    };
    mutation.mutate(data);
    actions.resetForm();
    setTimeout(() => {
      actions.setSubmitting(false);
    }, 500);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: resetPasswordValidation,
  });
  const onlyNumberKey = (event: any) => {
    if (event.which < 48 || event.which > 57) {
      event.preventDefault();
    }
  };

  return (
    <div className="reset-password-container">
      <form
        onSubmit={formik.handleSubmit}
        className="reset-change-password-form-container display-flex-align-justy-center height-reset-form"
      >
        <div className="adInputsreset change-pass-input-lable-container gap-[30px]">
          <div className="textFieldI textFieldI">
            <div className="send-otp-reset-password-lable">Email</div>
            <input
              type="text"
              placeholder="Enter email..."
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              className="reset-password-otp-input"
            />
            {formik.touched.email && formik.errors.email && (
              <p className="signupPage-input-error">
                {formik.errors.email.toString()}
              </p>
            )}
          </div>
          <div className="textFieldI textFieldI">
            <h2 className="accDetH2 send-otp-reset-password-lable">
              New Password
            </h2>
            <div className="passwordInputRow display-flex-align-justy-center">
              <input
                className="reset-password-otp-input"
                type={hidePassword ? "password" : "text"}
                placeholder="New Password"
                onBlur={formik.handleBlur}
                name="new_password"
                value={formik.values.new_password}
                onChange={formik.handleChange}
              />
              {hidePassword ? (
                <FiEye
                  className="iconSPasswordcp"
                  onClick={() => {
                    setHidePassword(false);
                  }}
                />
              ) : (
                <FiEyeOff
                  className="iconSPasswordcp"
                  onClick={() => {
                    setHidePassword(true);
                  }}
                />
              )}
            </div>
            {formik.touched.new_password && formik.errors.new_password && (
              <p className="change-password-error-text  current-new-password-error-msg">
                {formik.errors.new_password.toString()}
              </p>
            )}
          </div>
          <div className="textFieldI textFieldI">
            <h2 className="accDetH2 send-otp-reset-password-lable">
              Confirm New Password
            </h2>
            <div className="passwordInputRow display-flex-align-justy-center">
              <input
                className="reset-password-otp-input"
                type={hideConfirmPassword ? "password" : "text"}
                placeholder="Confirm new Password"
                onBlur={formik.handleBlur}
                name="confirm_new_password"
                value={formik.values.confirm_new_password}
                onChange={formik.handleChange}
              />
              {hideConfirmPassword ? (
                <FiEye
                  className="iconSPasswordcp"
                  onClick={() => {
                    setHideConfirmPassword(false);
                  }}
                />
              ) : (
                <FiEyeOff
                  className="iconSPasswordcp"
                  onClick={() => {
                    setHideConfirmPassword(true);
                  }}
                />
              )}
            </div>
            {formik.touched.confirm_new_password &&
              formik.errors.confirm_new_password && (
                <p className="change-password-error-text current-new-password-error-msg">
                  {formik.errors.confirm_new_password.toString()}
                </p>
              )}
          </div>
          <div className="textFieldI textFieldI">
            <h2 className="accDetH2 send-otp-reset-password-lable">
              Enter Otp
            </h2>
            <OtpInput
              value={formik.values.code}
              onChange={(code) => formik.setFieldValue("code", code)}
              numInputs={6}
              inputType="text"
              renderSeparator={<span></span>}
              shouldAutoFocus
              renderInput={(props) => (
                <input
                  onKeyPress={onlyNumberKey}
                  {...props}
                  className="otp-input otp-number-inputfor-reset"
                />
              )}
              containerStyle={{
                display: "flex",
                justifyContent: "space-around",
                margin: "20px 0",
              }}
            />
          </div>
        </div>
        <div className="adButtons">
          <button
            className="saveButton adButton1 password-change-save-btn button-max-width"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Reset Password
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
