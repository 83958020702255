import { ReactNode, useEffect, useState } from "react";
import "@/components/Header.css";
import { useAppDispatch, useAppSelector } from "@/store/reduxHooks";

import { setBroker } from "@/pages/auth/authSlice";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { capitalizeFirstLetterOfWords } from "@/heplers/constants";

import micSupportIMG from "@/assets/micImg.svg";
import topshineIMG from "@/assets/web-top-shine.svg";
import bottomshineIMG from "@/assets/web-bottom-shine.svg";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { useNavigate } from "react-router-dom";
import infoIcon from "@/assets/InfoIcon.svg";
import { Tooltip } from "react-tooltip";
import { LuMonitorPlay } from "react-icons/lu";

const Header = ({
  children,
  hideBrokerDropdown,
}: {
  children: ReactNode;
  hideBrokerDropdown?: boolean;
}) => {
  const navigate = useNavigate();
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [navBarStyle, setNavBarStyle] = useState({ top: "0px" });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        setNavBarStyle({ top: "-100px" });
      } else {
        setNavBarStyle({ top: "0px" });
      }
      setLastScrollTop(scrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.userState);
  const { isLoggedIn, user, brokerId, brokersList } = userState;

  const [selectedBroker, setSelectedBroker] = useState<any>("");

  useEffect(() => {
    if (selectedBroker) {
      dispatch(setBroker(selectedBroker));
    }
  }, [selectedBroker]);

  return (
    <>
      <div className="header" id="navbar" style={navBarStyle}>
        <div className="header-left-section">
          <h3>
            👋 Welcome <span>{capitalizeFirstLetterOfWords(user.name)}!</span>
          </h3>
        </div>
        <div className="header-right-section">
          <div className="broker-id-select-menu" id="broker-id-select-guide">
            {!hideBrokerDropdown && brokersList && brokersList?.length > 0 && (
              <Select onValueChange={setSelectedBroker} defaultValue={brokerId}>
                <SelectTrigger className="broker-id-select-menu-btn">
                  <SelectValue placeholder="Select a Broker" />
                </SelectTrigger>
                <SelectContent className="broker-dropdown-container ">
                  <SelectGroup className="select-broker-popup">
                    <SelectLabel className="text-center displayFlexCentre">
                      Select from Brokers
                    </SelectLabel>
                    {brokersList?.map((broker) => {
                      return (
                        <SelectItem value={broker.brkr_id} key={broker.brkr_id}>
                          {broker.brkr_name != "All"
                            ? `${broker.brkr_name}-${broker.brkr_id}`
                            : broker.brkr_name}
                        </SelectItem>
                      );
                    })}
                  </SelectGroup>
                </SelectContent>
              </Select>
            )}
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="d-flex flex-column header-date-picker-celender-btn"
          >
            {children}
          </div>

          <div className="position-relative">
            <img
              className="w-[20px] absolute left-[12px] top-[7px]"
              src={topshineIMG}
              alt=""
            />
            <button
              className="syncnow-button-header"
              id="betwizr_Sync_now_button"
            >
              Sync Now
            </button>
            <img
              className="w-[20px] absolute right-[12px] bottom-[7px]"
              src={bottomshineIMG}
              alt=""
            />
          </div>
          <Popover>
            <PopoverTrigger className="flex items-center justify-center border-solid border-1 border-amber-600 p-2 rounded-xl cursor-pointer">
              <img className="w-[20px] h-[20px]" src={micSupportIMG} alt="" />
            </PopoverTrigger>
            <PopoverContent className="support-in-webapp">
              <div className="flex flex-col items-center justify-center">
                <div>
                  <p className="got-question-text-webapp">
                    Got a Question ? Contact Us On
                  </p>{" "}
                </div>
                <p className="support-email-webapp mt-2">
                  support@betwizr.trade
                </p>
              </div>
            </PopoverContent>
          </Popover>
          <a
            data-tooltip-id="demo-videos"
            className="cursor-pointer"
            onClick={() => navigate("/tutorials")}
          >
            <LuMonitorPlay
              size="40px"
              color="#fba137"
              opacity=".7"
              strokeWidth="1px"
            />
          </a>
          <Tooltip
            id="demo-videos"
            className="circle-page-header-tooltip"
            border={"1px solid #ffffff67"}
            content="Demo Videos"
          />
        </div>
      </div>
    </>
  );
};

export default Header;
