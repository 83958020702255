import { useEffect, useRef, useState } from "react";
import happy from "@/assets/happy-emotion.svg";
import zen from "@/assets/zen-emotion.svg";
import angry from "@/assets/angry-emotion.svg";
import panic from "@/assets/panic-emotion.svg";
import sad from "@/assets/sad-emaotion.svg";
import logOutEmotion from "@/assets/LogoutEmotion.svg";
import { DateRange } from "react-day-picker";
import { useQuery } from "react-query";
import { getEmotions } from "@/module/dashboard/api/insights";
import { format, addDays } from "date-fns";
import { EmotionsMap, initialEmotionsMap } from "./helper";
import { useAppSelector } from "@/store/reduxHooks";
import { CustomErrorBoundary } from "../errorBoundary/CustomErrorBoundary";

const MindsetGraph = ({ date }: { date: DateRange | undefined }) => {
  const userState = useAppSelector((state) => state.userState);
  const { brokerId, isAuthenticated, isGuidedFlowSeen } = userState;
  const [isApiError, setIsApiError] = useState(false);
  const childRef = useRef<any>(null);
  const emotions = useQuery({
    queryKey: ["emotions", date, brokerId, isAuthenticated],
    queryFn: () => {
      setIsApiError(false);
      return getEmotions({
        start_date: date?.from
          ? format(date.from, "yyyy-MM-dd")
          : format(addDays(new Date(), -7), "yyyy-MM-dd"),
        end_date: date?.to
          ? format(date.to, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        broker_id: brokerId,
      });
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && brokerId.length > 0 && isGuidedFlowSeen,
    retry: 0,
    onError: (error) => {
      setIsApiError(true);
    },
  });

  const [emotionMap, setEmotionMap] = useState<EmotionsMap>({
    happy: {
      emotion: "happy",
      trades_cnt: 0,
      total_mtm: 0,
      percentage: 0,
    },
    zen: {
      emotion: "zen",
      trades_cnt: 0,
      total_mtm: 0,
      percentage: 0,
    },
    panic: {
      emotion: "panic",
      trades_cnt: 0,
      total_mtm: 0,
      percentage: 0,
    },
    angry: {
      emotion: "angry",
      trades_cnt: 0,
      total_mtm: 0,
      percentage: 0,
    },
    sad: {
      emotion: "sad",
      trades_cnt: 0,
      total_mtm: 0,
      percentage: 0,
    },
    null: {
      emotion: "null",
      trades_cnt: 0,
      total_mtm: 0,
      percentage: 0,
    },
  });

  useEffect(() => {
    if (emotions.isSuccess && emotions.data) {
      const data = emotions.data;

      const totalTradeCount = data.reduce((acc, curr) => {
        return acc + curr.trades_cnt;
      }, 0);

      setEmotionMap(() => {
        const updatedEmotionMap = { ...initialEmotionsMap };
        data.forEach((el) => {
          const emotion = el.emotion;
          const percentage =
            totalTradeCount === 0
              ? 0
              : Math.round((el.trades_cnt / totalTradeCount) * 100);
          updatedEmotionMap[emotion] = { ...el, percentage };
        });

        return updatedEmotionMap;
      });
    }
  }, [emotions.data]);

  return (
    <CustomErrorBoundary
      isError={emotions.isError}
      isLoading={emotions.isLoading}
      error={emotions.error}
      isApiError={isApiError}
      childRef={childRef}
    >
      {emotionMap && (
        <div className="emotion-section" ref={childRef}>
          <div className="emotion-content">
            <div className="emotion-title" id="mindset-graph-guide">
              🧠 MindSet Graph
            </div>

            <div className="emotion-status-section">
              <div className="emotion-status-grid">
                <div>
                  <p className="emotion-stauts-title">
                    Happy{" "}
                    <span className="happy">
                      {emotionMap["happy"].percentage}% (
                      {emotionMap["happy"].trades_cnt} Entries)
                    </span>
                  </p>

                  <div className="emotion-stauts-progress-bar-wrap">
                    <div className="emotion-progress-bar">
                      <div
                        className="progress happy-progressbar"
                        role="progressbar"
                        aria-label="Example 1px high"
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <div
                          className="progress-bar"
                          style={{
                            width: `${emotionMap["happy"].percentage}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div>
                      <img className="happy-img" src={happy} alt="" />
                    </div>
                  </div>
                </div>
                <div>
                  <p className="emotion-stauts-title">
                    Zen{" "}
                    <span className="zen">
                      {emotionMap["zen"].percentage}% (
                      {emotionMap["zen"].trades_cnt} Entries)
                    </span>
                  </p>

                  <div className="emotion-stauts-progress-bar-wrap">
                    <div className="emotion-progress-bar">
                      <div
                        className="progress zen-progressbar"
                        role="progressbar"
                        aria-label="Example 1px high"
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <div
                          className="progress-bar"
                          style={{ width: `${emotionMap["zen"].percentage}%` }}
                        ></div>
                      </div>
                    </div>
                    <div>
                      <img className="zen-img" src={zen} alt="" />
                    </div>
                  </div>
                </div>
                <div>
                  <p className="emotion-stauts-title">
                    Sad{" "}
                    <span className="sad">
                      {" "}
                      {emotionMap["sad"].percentage}% (
                      {emotionMap["sad"].trades_cnt} Entries)
                    </span>
                  </p>

                  <div className="emotion-stauts-progress-bar-wrap">
                    <div className="emotion-progress-bar">
                      <div
                        className="progress sad-progressbar"
                        role="progressbar"
                        aria-label="Example 1px high"
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <div
                          className="progress-bar"
                          style={{ width: `${emotionMap["sad"].percentage}%` }}
                        ></div>
                      </div>
                    </div>
                    <div>
                      <img className="sad-img" src={sad} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="emotion-status-grid">
                <div>
                  <p className="emotion-stauts-title">
                    Angry{" "}
                    <span className="angry">
                      {emotionMap["angry"].percentage}% (
                      {emotionMap["angry"].trades_cnt} Entries)
                    </span>
                  </p>

                  <div className="emotion-stauts-progress-bar-wrap">
                    <div className="emotion-progress-bar">
                      <div
                        className="progress angry-progressbar"
                        role="progressbar"
                        aria-label="Example 1px high"
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <div
                          className="progress-bar"
                          style={{
                            width: `${emotionMap["angry"].percentage}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div>
                      <img className="angry-img" src={angry} alt="" />
                    </div>
                  </div>
                </div>
                <div>
                  <p className="emotion-stauts-title">
                    Panic{" "}
                    <span className="panic">
                      {" "}
                      {emotionMap["panic"].percentage}% (
                      {emotionMap["panic"].trades_cnt} Entries)
                    </span>
                  </p>

                  <div className="emotion-stauts-progress-bar-wrap">
                    <div className="emotion-progress-bar">
                      <div
                        className="progress panic-progressbar"
                        role="progressbar"
                        aria-label="Example 1px high"
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <div
                          className="progress-bar"
                          style={{
                            width: `${emotionMap["panic"].percentage}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div>
                      <img className="panic-img" src={panic} alt="" />
                    </div>
                  </div>
                </div>

                <div>
                  <p className="emotion-stauts-title">
                    Not Logged{" "}
                    <span className="sad">
                      {" "}
                      {emotionMap["null"].percentage}% (
                      {emotionMap["null"].trades_cnt} Entries)
                    </span>
                  </p>

                  <div className="emotion-stauts-progress-bar-wrap">
                    <div className="emotion-progress-bar">
                      <div
                        className="progress sad-progressbar"
                        role="progressbar"
                        aria-label="Example 1px high"
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <div
                          className="progress-bar"
                          style={{ width: `${emotionMap["null"].percentage}%` }}
                        ></div>
                      </div>
                    </div>
                    <div>
                      <img className="sad-img" src={logOutEmotion} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </CustomErrorBoundary>
  );
};

export default MindsetGraph;
