import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { useAppDispatch } from "@/store/reduxHooks";
import { useState } from "react";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { logOutUser } from "../authSlice";
import { useNavigate } from "react-router-dom";
import topshineIMG from "@/assets/web-top-shine.svg";
import bottomshineIMG from "@/assets/web-bottom-shine.svg";
import blacktopshineIMG from "@/assets/web-black-top-shine.svg";
import blackbottomshineIMG from "@/assets/web-black-bottom-shine.svg";

export function LogoutConfirmationModal({
  containerRef,
}: {
  containerRef: any;
}) {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const logOut = () => {
    dispatch(logOutUser());
    toast.success(<p>Logged out successfully!</p>, {
      duration: 2000,
    });
    queryClient.clear();
    navigate("/login");
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          className="log-out-btn-boardingflow relative"
          id="webapp_confirmation_modal_logout_open_button"
          style={{
            position: "fixed",
            top: containerRef.current
              ? containerRef.current.getBoundingClientRect().y + 20
              : 20,
            right: containerRef.current
              ? containerRef.current.getBoundingClientRect().x + 45
              : 20,
          }}
        >
          <img
            className="w-[20px] absolute left-[10px] top-[6px]"
            src={topshineIMG}
            alt=""
          />
          Log Out
          <img
            className="w-[20px] absolute right-[10px] bottom-[6px]"
            src={bottomshineIMG}
            alt=""
          />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[600px] sure-to-logout">
        <div className="flex flex-col items-start">
          <h1 className="text-[26px] font-semibold confirm-the-action">
            Confirm the action
          </h1>
          <h1 className="text-[22px] font-light mt-4 mb-10">
            Are you sure you want to log out ?
          </h1>
          <div className="flex justify-end text-lg no-yes-btn w-[100%]">
            <button
              className="no-btn yes-btn relative"
              onClick={() => setOpen(false)}
            >
              <img
                className="w-[20px] absolute left-[12px] top-[7px]"
                src={blacktopshineIMG}
                alt=""
              />
              Cancel
              <img
                className="w-[20px] absolute right-[12px] bottom-[7px]"
                src={blackbottomshineIMG}
                alt=""
              />
            </button>
            <button
              className="yes-btn relative"
              id="betwizr_webapp_boarding_logout_btn"
              onClick={() => {
                logOut();
              }}
            >
              <img
                className="w-[20px] absolute left-[12px] top-[7px]"
                src={topshineIMG}
                alt=""
              />
              Confirm
              <img
                className="w-[20px] absolute right-[12px] bottom-[7px]"
                src={bottomshineIMG}
                alt=""
              />
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
