import { getScoreTrend, getWizrScore } from "@/module/dashboard/api/insights";
import {
  GetScoreTrendResponse,
  GetWizrScoreResponse,
} from "@/module/dashboard/interface/insightsInterface";
import { formatPFValue, formatRRValue } from "@/module/dayLevelAnalysis/components/ExitProtocolHistory/helper";
import { useAppDispatch, useAppSelector } from "@/store/reduxHooks";
import { emDash } from "@/utils/constants";
import { ApexOptions } from "apexcharts";
import { addDays, format } from "date-fns";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { DateRange } from "react-day-picker";
import { useQuery } from "react-query";
import { CustomErrorBoundary } from "../errorBoundary/CustomErrorBoundary";
import { setSingleDate } from "@/pages/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import infoIcon from "@/assets/InfoIcon.svg";

const initWizrScoreData = {
  r_r: 0,
  profit_factor: 0,
  win_rate: 0,
  rr_score: 0,
  pf_score: 0,
  wr_score: 0,
  ps_score: 0,
  wizr_score: 0,
};

const TradeScoreOvertimeChart = ({ date }: { date: DateRange | undefined }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userState = useAppSelector((state) => state.userState);
  const { brokerId, isAuthenticated, isGuidedFlowSeen } = userState;
  const [isApiError, setIsApiError] = useState(false);
  const childRef = useRef<any>(null);
  const scoreTrend = useQuery({
    queryKey: ["scoreTrend", date, brokerId, isAuthenticated],
    queryFn: () => {
      setIsApiError(false);
      return getScoreTrend({
        start_date: date?.from
          ? format(date.from, "yyyy-MM-dd")
          : format(addDays(new Date(), -7), "yyyy-MM-dd"),
        end_date: date?.to
          ? format(date.to, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        broker_id: brokerId,
      });
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && brokerId.length > 0 && isGuidedFlowSeen,
    retry: 0,
    onError: (error) => {
      setIsApiError(true);
    },
  });

  const wizrScore = useQuery({
    queryKey: ["wizrScore", date, brokerId, isAuthenticated],
    queryFn: () =>
      getWizrScore({
        start_date: date?.from
          ? format(date.from, "yyyy-MM-dd")
          : format(addDays(new Date(), -7), "yyyy-MM-dd"),
        end_date: date?.to
          ? format(date.to, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        broker_id: brokerId,
      }),
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && brokerId.length > 0 && isGuidedFlowSeen,
    retry: 0,
  });
  const [scoreData, setScoreData] = useState<GetScoreTrendResponse[]>([]);

  useEffect(() => {
    if (scoreTrend.isSuccess && scoreTrend.data) {
      const sortedData = scoreTrend.data.sort(
        (a: GetScoreTrendResponse, b: GetScoreTrendResponse) => {
          return (
            new Date(a.trading_day).getTime() -
            new Date(b.trading_day).getTime()
          );
        }
      );
      setScoreData(sortedData);
      if (sortedData.length == 0) {
        setIsApiError(true);
      }
    }
  }, [scoreTrend.data]);

  const [wizrScoreData, setWizrScoreData] =
    useState<GetWizrScoreResponse>(initWizrScoreData);

  useEffect(() => {
    if (wizrScore.isSuccess && wizrScore.data) {
      const scoreData = wizrScore.data;

      setWizrScoreData(scoreData);
    }
    if (wizrScore.isError) {
      setWizrScoreData(initWizrScoreData);
    }
  }, [wizrScore]);

  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },

      events: {
        dataPointMouseEnter: function (event) {
          event.srcElement.style.cursor = "pointer";
        },
        dataPointSelection: function (
          event,
          chartContext,
          { seriesIndex, dataPointIndex, config }
        ) {
          if (seriesIndex !== undefined && dataPointIndex !== undefined) {
            var timestamp =
              chartContext.data.w.globals.categoryLabels[dataPointIndex];
            const originalDate = moment(timestamp, "DD/MM").toDate();

            setTimeout(() => {
              dispatch(setSingleDate(originalDate.toISOString()));
              navigate("/day-level-analysis");
            }, 10);
          }
        },
      },
    },

    markers: {
      size: 5,
    },
    colors: ["#FBA137"],
    tooltip: {
      enabled: true,
      theme: "dark",
      shared: false,
      intersect: true,
    },
    grid: {
      borderColor: "#FFFFFF59",
      strokeDashArray: 2,
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 5,
        right: 40,
        bottom: 5,
        left: 50,
      },
      position: "front",
    },
    xaxis: {
      categories: scoreData.map((el: GetScoreTrendResponse) => {
        return el.trading_day;
      }),
      tooltip: {
        enabled: false,
      },
      offsetY: 20,
      position: "bottom",
      axisBorder: {
        show: false,
      },
      labels: {
        hideOverlappingLabels: true,
        trim: true,
        style: {
          colors: "#FFFFFF",
          fontSize: "14px",
          fontWeight: 400,
          fontFamily: "Source Sans Pro",
        },
        formatter: function (value, timestamp: number, opts) {
          return moment(value).format("DD/M");
        },
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 4,
      labels: {
        formatter: function (val) {
          return Number(val).toString();
        },
        align: "right",
        minWidth: 50,
        maxWidth: 80,
        style: {
          colors: "#FFFFFF",
          fontSize: "14px",
          fontWeight: 400,
          fontFamily: "Source Sans Pro",
        },
        offsetX: 0,
      },
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "round",
      colors: ["#FEA338"],
      width: 2,
      dashArray: 0,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Number(val).toString();
      },
      offsetY: -10,
      textAnchor: "middle",
      style: {
        fontSize: "14px",
        colors: ["#FFFFFF"],
        fontFamily: "Source Sans Pro",
        fontWeight: 400,
      },
      dropShadow: {
        enabled: false,
      },
      background: {
        enabled: false,
      },
    },
  };
  const series = [
    {
      name: "score",
      data: scoreData.map((el: GetScoreTrendResponse) => {
        return Math.round(el.wizr_score);
      }),
    },
  ];

  return (
    <CustomErrorBoundary
      isError={scoreTrend.isError}
      isLoading={scoreTrend.isLoading}
      error={scoreTrend.error}
      isApiError={isApiError}
      childRef={childRef}
      customClassName="cumulative-error-msg h-[70%] top-[60%]"
      noData={scoreData.length == 0}
    >
      <div className="trade-score-heading-data-box">
        <div className="title p-0">
          <h3 id="trade-score-overtime-guide">
            <span>Wizr Score </span>Overtime
          </h3>
        </div>
        <div className="trade-score-header-right">
          <div className="trade-score-header-data-list">
            <h5 className="flex items-center gap-1">
              Overall Score{" "}
              <a
                data-tooltip-id="overall-score-tooltip-border"
                className="cursor-pointer"
              >
                <img className="w-[16px]" src={infoIcon} alt="" />
              </a>
            </h5>

            <Tooltip
              id="overall-score-tooltip-border"
              className="circle-page-header-tooltip"
              border={"1px solid #ffffff67"}
              place="top"
              content="Wizr Score"
            />
            <span className="rr">
              {wizrScoreData.wizr_score ? wizrScoreData.wizr_score : emDash}
            </span>
          </div>
          <div className="trade-score-header-data-list">
            <h5 className="flex items-center gap-1">
              WinRate{" "}
              <a data-tooltip-id="wr-tooltip-border" className="cursor-pointer">
                <img className="w-[16px]" src={infoIcon} alt="" />
              </a>
            </h5>

            <Tooltip
              className="circle-page-header-tooltip"
              border={"1px solid #ffffff67"}
              id="wr-tooltip-border"
              place="top"
              content="Reflects the percentage of your winning trades out of total trades taken."
            />
            <span>
              {wizrScoreData.win_rate ? `${wizrScoreData.win_rate}%` : emDash}
            </span>
          </div>

          <div className="trade-score-header-data-list">
            <h5>RR</h5>
            <span className="rr">
              {!wizrScoreData.r_r || wizrScoreData.r_r <= 0
                ? emDash
                : `${formatRRValue(wizrScoreData.r_r)}`}
            </span>
          </div>
          <div className="trade-score-header-data-list">
            <h5 className="flex items-center gap-1">
              Profit Factor
              <a data-tooltip-id="pf-tooltip-border" className="cursor-pointer">
                <img className="w-[16px]" src={infoIcon} alt="" />
              </a>
              <Tooltip
                className="circle-page-header-tooltip"
                border={"1px solid #ffffff67"}
                id="pf-tooltip-border"
                content="Total profits divided by total losses. A profit factor above 1.0 indicates a profitable trading system."
              />
            </h5>
            <span>
              {wizrScoreData.profit_factor
                ? formatPFValue(wizrScoreData.profit_factor)
                : emDash}
            </span>
          </div>
        </div>
      </div>
      <div id="chart" ref={childRef}>
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={350}
          width={"100%"}
        />
      </div>
    </CustomErrorBoundary>
  );
};

export default TradeScoreOvertimeChart;
