import { RouteInterface } from "../interface/RouteInterface";
import Dashboard from "@/pages/dashboard/Dashboard";
import DayLevelAnalysis from "@/pages/dayLevelAnalysis/DayLevelAnalysis";
import Profiles from "@/pages/profiles/Profiles";
import Strategy from "@/pages/strategy/Strategy";
import VerifyEmail from "@/pages/auth/verifyUser/VerifyEmail";
import VerifyPhNumber from "@/pages/auth/verifyUser/VerifyNumber";
import BoardingFlowStepper from "@/pages/auth/welcomePages/BoardingFlowStepper";
import Tutorials from "@/pages/tutorials/Tutorials";
import ConsentScreen from "@/pages/auth/consentScreen/ConsentScreen";
import AddWidget from "@/pages/auth/addWidget/AddWidget";

const AppRoutesData: RouteInterface[] = [
  {
    path: "/",
    component: Dashboard,
    name: "Dashboard",
  },
  {
    path: "/day-level-analysis",
    component: DayLevelAnalysis,
    name: "DayLevelAnalysis",
  },
  {
    path: "/strategy",
    component: Strategy,
    name: "Strategy",
  },
  {
    path: "/profiles",
    component: Profiles,
    name: "Profiles",
  },
  {
    path: "/verifyemail",
    component: VerifyEmail,
    name: "VerifyUser",
  },
  {
    path: "/verifynumber",
    component: VerifyPhNumber,
    name: "VerifyPhNumber",
  },
  {
    path: "/consent",
    component: ConsentScreen,
    name: "ConsentScreen",
  },
  {
    path: "/welcome",
    component: BoardingFlowStepper,
    name: "BoardingFlowStepper",
  },
  {
    path: "/tutorials",
    component: Tutorials,
    name: "Tutorials",
  },
  {
    path: "/add-widget",
    component: AddWidget,
    name: "AddWidget",
  },
];

export default AppRoutesData;
