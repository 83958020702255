"use client";

import { ChevronDownIcon } from "@radix-ui/react-icons";
import {
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import {
  GetExitProtocolHistoryResponse,
  getExitProtocolHistory,
} from "@/module/dayLevelAnalysis/api/exitprotocolhistory";
import { useRef, useState } from "react";
import { DataTablePagination } from "@/module/dayLevelAnalysis/components/ExitProtocolHistory/TablePaginagtion";
import {
  downloadCSV,
  sortExitProtocols,
} from "@/module/dayLevelAnalysis/components/ExitProtocolHistory/helper";
import EditExitPortocolModal from "@/module/dayLevelAnalysis/components/ExitProtocolHistory/EditExitProtocolModal";
import { getColumns } from "@/module/dayLevelAnalysis/components/ExitProtocolHistory/Columns";
import "@/module/dayLevelAnalysis/components/ExitProtocolHistory/exitprotocolhistorytable.css";
import filterIconImg from "@/assets/filterIconImg.svg";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Checkbox } from "@/components/ui/checkbox";

import { CgClose } from "react-icons/cg";
import { useQuery } from "react-query";
import { useAppSelector } from "@/store/reduxHooks";
import moment from "moment";
import { format } from "date-fns";
import { CustomErrorBoundary } from "@/components/errorBoundary/CustomErrorBoundary";
import topshineIMG from "@/assets/web-top-shine.svg";
import bottomshineIMG from "@/assets/web-bottom-shine.svg";

const ExitProtocolHistory = () => {
  const userState = useAppSelector((state) => state.userState);
  const { brokerId, isAuthenticated, singleDate, isGuidedFlowSeen } = userState;
  const modifiedDate = moment(singleDate).toDate();
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [exitData, setExitData] = useState<GetExitProtocolHistoryResponse>();
  const [open, setOpen] = useState(false);
  const columns = getColumns(setExitData, setOpen);

  const [openHideColumnMenu, setOpenHideColumnMenu] = useState(false);
  const [data, setData] = useState<GetExitProtocolHistoryResponse[]>([]);
  const [isApiError, setIsApiError] = useState(false);

  const tableRef = useRef(null);

  const exitprotocolhistory = useQuery({
    queryKey: ["exitprotocolhistory", modifiedDate, brokerId, isAuthenticated],
    queryFn: () =>
      getExitProtocolHistory({
        start_date: modifiedDate
          ? format(modifiedDate, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        end_date: modifiedDate
          ? format(modifiedDate, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        broker_id: brokerId,
      }),
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && brokerId.length > 0 && isGuidedFlowSeen,
    retry: 0,
    onError: () => {
      setIsApiError(true);
    },
    onSuccess: (data) => {
      setIsApiError(false);
      const sortedData = sortExitProtocols(data);
      setData(sortedData);
    },
  });
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnVisibility,
    },
  });
  return (
    <div className="w-full historytable-daylevelpage">
      <div className="flex items-center py-4">
        <h1
          className="text-left exitprotohistorytableheading"
          id="exit-protocol-history-guide"
        >
          Trade Journal History
        </h1>
        <div className="ml-auto d-flex gap-2">
          <Button
            className="exitprotocolhistorytablebuttons relative"
            onClick={() => downloadCSV(data)}
          >
            <img
              className="w-[20px] absolute left-[8px] top-[5px]"
              src={topshineIMG}
              alt=""
            />
            Download to excel
            <img
              className="w-[20px] absolute right-[8px] bottom-[4px]"
              src={bottomshineIMG}
              alt=""
            />
          </Button>

          <Popover
            open={openHideColumnMenu}
            onOpenChange={(open) => {
              setOpenHideColumnMenu(open);
            }}
          >
            <PopoverTrigger asChild>
              <Button className="ml-auto exitprotocolhistorytablebuttons relative">
                <img
                  className="w-[20px] absolute left-[8px] top-[4px]"
                  src={topshineIMG}
                  alt=""
                />
                <img src={filterIconImg} alt="" /> Add/Modify Columns{" "}
                <ChevronDownIcon className="ml-2 h-4 w-4" />
                <img
                  className="w-[20px] absolute right-[8px] bottom-[4px]"
                  src={bottomshineIMG}
                  alt=""
                />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="add-modify-drop-down" align="end">
              <div className="add-modify-close-btn-head">
                <h1>Add /Modify columns</h1>
                <p onClick={() => setOpenHideColumnMenu(false)}>
                  <CgClose />
                </p>
              </div>

              <div
                className="select-all-check-box-strategy flex gap-2 items-center ml-[23px]"
                onClick={table.getToggleAllColumnsVisibilityHandler()}
              >
                <Checkbox
                  className="capitalize color-yellow-border"
                  checked={table.getIsAllColumnsVisible()}
                />
                <label>select all</label>
              </div>
              <div className="add-modifie-column-box-all">
                <div className="add-modify-column-left flex flex-col gap-4">
                  {table
                    .getAllColumns()
                    .filter((column) => column.getCanHide())
                    .filter((column, index) => index < 6)
                    .map((column, index) => {
                      return (
                        <div
                          className="checkbox-lable flex gap-2 items-center"
                          onClick={(value) => {
                            console.log("value:2", value.target);
                            column.toggleVisibility(!column.getIsVisible());
                          }}
                        >
                          <Checkbox
                            key={column.id}
                            className="capitalize color-yellow-border"
                            checked={column.getIsVisible()}
                          />

                          <label>
                            {column.columnDef.header && column.columnDef.header
                              ? `${column.columnDef.header}`
                              : ""}
                          </label>
                        </div>
                      );
                    })}
                </div>
                <div className="add-modify-column-left flex flex-col gap-4">
                  {table
                    .getAllColumns()
                    .filter((column) => column.getCanHide())
                    .filter((column, index) => index > 5)
                    .map((column, index) => {
                      return (
                        <div
                          className="checkbox-lable flex gap-2 items-center"
                          onClick={(value) => {
                            console.log("value:2", value.target);
                            column.toggleVisibility(!column.getIsVisible());
                          }}
                          key={index}
                        >
                          <Checkbox
                            key={column.id}
                            className="capitalize color-yellow-border"
                            checked={column.getIsVisible()}
                          />

                          <label>
                            {column.columnDef.header && column.columnDef.header
                              ? `${column.columnDef.header}`
                              : ""}
                          </label>
                        </div>
                      );
                    })}
                </div>
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
      <CustomErrorBoundary
        isError={exitprotocolhistory.isError}
        isLoading={exitprotocolhistory.isLoading}
        error={exitprotocolhistory.error}
        isApiError={isApiError}
        childRef={tableRef}
        customClassName="cumulative-error-msg"
        noData={data.length == 0}
      >
        <div ref={tableRef}>
          <div className="rounded-md">
            <Table>
              <TableHeader className="history-table-head">
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      No data available for the selected date range
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <div className="flex items-center justify-end space-x-2 py-4">
            <DataTablePagination table={table} data={data} />
          </div>
        </div>
      </CustomErrorBoundary>

      {open && exitData && (
        <EditExitPortocolModal
          exitData={exitData}
          open={open}
          setOpen={setOpen}
        />
      )}
    </div>
  );
};

export default ExitProtocolHistory;
