import { useQuery, useQueryClient } from "react-query";
import Layout from "@/components/Layout";
import {
  GetTraderProfileResponse,
  getAllTraderProfile,
  getTraderProfileByBrokerId,
} from "@/module/Profile/api/traderProfile";
import { Editprofilemodal } from "@/pages/profiles/editProfile/EditProfileModal";
import ChangePasswordModal from "@/pages/auth/changePassword/ChangePasswordModal";
import "@/pages/profiles/profiles.css";
import { Button } from "@/components/ui/button";
import { useAppDispatch, useAppSelector } from "@/store/reduxHooks";
import { logOutUser } from "@/pages/auth/authSlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Header from "@/components/Header";
import { useEffect, useRef, useState } from "react";
import { CreateProfileModal } from "./createProfile.tsx/createProfileModal";
import { initialTradeProfile } from "./editProfile/helper";
import { CustomErrorBoundary } from "@/components/errorBoundary/CustomErrorBoundary";
import topshineIMG from "@/assets/web-top-shine.svg";
import bottomshineIMG from "@/assets/web-bottom-shine.svg";

const Profiles = () => {
  const userState = useAppSelector((state) => state.userState);

  const { brokerId, isAuthenticated, isGuidedFlowSeen } = userState;
  const [isApiError, setIsApiError] = useState(false);
  const childRef = useRef<any>(null);

  const [date, setDate] = useState<Date | undefined>(new Date());
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [traderProfileList, setTraderProfileList] = useState<
    GetTraderProfileResponse[]
  >([initialTradeProfile]);

  const [selectedTraderProfile, setSelectedTraderProfile] =
    useState<GetTraderProfileResponse>();
  const userTradeProfile = useQuery({
    queryKey: ["userTradeProfile", brokerId, isAuthenticated],
    queryFn: () => getTraderProfileByBrokerId(brokerId),
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && brokerId.length > 0 && isGuidedFlowSeen,
    retry: 0,
  });
  const allTraderProfiles = useQuery({
    queryKey: ["allTraderProfiles", isAuthenticated],
    queryFn: () => {
      setIsApiError(false);

      return getAllTraderProfile();
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && isGuidedFlowSeen,
    retry: 0,
    onError: (error) => {
      setIsApiError(true);
    },
  });

  useEffect(() => {
    if (userTradeProfile.isSuccess && userTradeProfile.data) {
      const data = userTradeProfile.data;
      setSelectedTraderProfile(data);
    }
  }, [userTradeProfile.data]);
  useEffect(() => {
    if (allTraderProfiles.isSuccess && allTraderProfiles.data) {
      const data = allTraderProfiles.data;
      const sortedData = data.sort((a, b) => {
        return a.broker_id.localeCompare(b.broker_id);
      });

      setTraderProfileList(sortedData);
    }
  }, [allTraderProfiles.data]);

  const logOut = () => {
    dispatch(logOutUser());
    toast.success(<p>Logged out successfully!</p>, {
      duration: 2000,
    });
    queryClient.clear();
    navigate("/login");
  };

  // edit trader profile
  const [openEditTraderProfile, setOpenEditTraderProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] =
    useState<GetTraderProfileResponse>();
  return (
    <>
      <Layout>
        <Header hideBrokerDropdown>{""}</Header>
        <div className="trade-profile padding-top-main-content">
          <div className="trade-profile-header-top">
            <div
              className="trade-profile-header-top-dflex"
              id="trader-profile-list-guide"
            >
              <h3>Trader Profile</h3>
              <CreateProfileModal />
            </div>
            <div className="trade-profile-header-top-dflex">
              <ChangePasswordModal />
              <Button
                className="log-out-btn-profile relative"
                id="betwizr_webapp_logout_btn"
                onClick={logOut}
              >
                <img
                  className="w-[20px] absolute left-[8px] top-[5px]"
                  src={topshineIMG}
                  alt=""
                />
                Logout
                <img
                  className="w-[20px] absolute right-[8px] bottom-[5px]"
                  src={bottomshineIMG}
                  alt=""
                />
              </Button>
            </div>
          </div>
          <CustomErrorBoundary
            isError={allTraderProfiles.isError}
            isLoading={allTraderProfiles.isLoading}
            error={allTraderProfiles.error}
            isApiError={isApiError}
            childRef={childRef}
          >
            {traderProfileList.map((el: GetTraderProfileResponse) => (
              <div ref={childRef}>
                <div className="borker-id-edit-btn-container">
                  <div className="flex-auto-row">
                    <h1 className="profile-page-broker-name">
                      Broker Name:{" "}
                      <span className="broker-id-name-profile">
                        {el.broker_name}
                      </span>
                    </h1>
                    <h1>
                      Broker Id:{" "}
                      <span className="broker-id-name-profile">
                        {el.broker_id}
                      </span>
                    </h1>
                  </div>
                  <div>
                    <Button
                      className="web-edit-profile-modal-btn"
                      onClick={() => {
                        setSelectedProfile(el);
                        setOpenEditTraderProfile(true);
                      }}
                    >
                      Edit Profile
                    </Button>
                  </div>
                </div>
                <div className="trade-profile-wrap">
                  <div className="trade-profile-boxs">
                    <h3>{el.daily_risk_limit_ptg}%</h3>
                    <p>Daily Risk Limit</p>
                    <span>{el.weekly_risk_limit_ptg}% Weekly Limit</span>
                  </div>
                  <div className="trade-profile-boxs">
                    <h3>{el.daily_profit_target_ptg}%</h3>
                    <p>Profit Target</p>
                    <span>{el.weekly_profit_target_ptg}% Weekly target</span>
                  </div>
                  <div className="trade-profile-boxs">
                    <h3>{el.trade_risk_limit_ptg}%</h3>
                    <p>Trade level risk</p>
                  </div>
                  <div className="trade-profile-boxs">
                    <h3>{el.trades_per_day}</h3>
                    <p>Daily No. of trades</p>
                  </div>
                  <div className="trade-profile-boxs">
                    <h3>
                      ₹
                      {Math.round(
                        (el.charges_limit_per_day_ptg * el.account_size) / 100
                      )}
                    </h3>
                    <p>Charges</p>
                    <span>
                      {el.charges_limit_per_day_ptg}% (of Account Size)
                    </span>
                  </div>
                  <div className="trade-profile-boxs">
                    <h3 className="scrip-profile-text-red">{el.scrip}</h3>
                    <p>Scrip Mostly Traded</p>
                  </div>
                  <div className="trade-profile-boxs">
                    <h3>{el.desired_r_multiple}</h3>
                    <p>Desired R Multiple</p>
                  </div>
                  <div className="trade-profile-boxs">
                    <h3>₹{el.account_size}</h3>
                    <p>Account Size</p>
                  </div>
                </div>
              </div>
            ))}
          </CustomErrorBoundary>
        </div>
      </Layout>
      {selectedProfile && openEditTraderProfile && (
        <Editprofilemodal
          profileData={selectedProfile}
          open={openEditTraderProfile}
          setOpen={setOpenEditTraderProfile}
        />
      )}
    </>
  );
};

export default Profiles;
