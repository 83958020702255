import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { FormikHelpers, useFormik } from "formik";
import { useState } from "react";
import { createStrategyInterface } from "@/module/dayLevelAnalysis/interface/createStrategyInterface";
import { validateForm } from "@/validation/StrategyValidation";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import "@/module/dayLevelAnalysis/components/createStrategy/createstrategymodal.css";
import { createStrategy } from "@/module/strategy/api/strategy";
import sixdotsIMG from "@/assets/sixdots.svg";
import { CgClose } from "react-icons/cg";

import topshineIMG from "@/assets/web-top-shine.svg";
import bottomshineIMG from "@/assets/web-bottom-shine.svg";

import blacktopshineIMG from "@/assets/web-black-top-shine.svg";
import blackbottomshineIMG from "@/assets/web-black-bottom-shine.svg";

const CreateStrategyModal = ({
  btnText,
  customClassName,
  source,
  handleNextPage,
}: {
  btnText: string;
  customClassName?: string;
  source?: string;
  handleNextPage?: any;
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: createStrategy,
    onSuccess: (data) => {
      toast.success(<p> Strategy created successfully!</p>, {
        duration: 2000,
      });
      queryClient.invalidateQueries("userTradeStrategies");
      if (source == "welcome") {
        handleNextPage(); // for boarding flow
      }
      handleClose();
    },
    onError: (error) => {
      toast.error(<p>Error while creating stratgy!</p>, {
        duration: 2000,
      });
    },
  });

  const initialValues: createStrategyInterface = {
    strategyName: "",
    entryCriteria: [],
    exitCriteria: [],
    newEntryCriteria: "",
    newExitCriteria: "",
  };

  const handleSubmit = async (
    values: createStrategyInterface,
    actions: FormikHelpers<createStrategyInterface>
  ) => {
    const data = {
      strategy: values.strategyName,
      entry_criteria:
        values.entryCriteria.length > 0 ? values.entryCriteria : null,
      exit_criteria:
        values.exitCriteria.length > 0 ? values.exitCriteria : null,
    };

    if (values.newEntryCriteria && values.newEntryCriteria.length > 1) {
      if (!data.entry_criteria) {
        data.entry_criteria = [];
      }
      data.entry_criteria.push(values.newEntryCriteria);
    }
    if (values.newExitCriteria && values.newExitCriteria.length > 1) {
      if (!data.exit_criteria) {
        data.exit_criteria = [];
      }
      data.exit_criteria.push(values.newExitCriteria);
    }

    mutation.mutate(data);
    actions.resetForm();
    setTimeout(() => {
      actions.setSubmitting(false);
    }, 500);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validate: validateForm,
    onSubmit: handleSubmit,
  });

  const addEntryCriteria = () => {
    if (formik.values.newEntryCriteria.trim() !== "") {
      formik.setValues({
        ...formik.values,
        entryCriteria: [
          ...formik.values.entryCriteria,
          formik.values.newEntryCriteria,
        ],
        newEntryCriteria: "",
      });
    }
  };
  const addExitCriteria = () => {
    if (formik.values.newExitCriteria.trim() !== "") {
      formik.setValues({
        ...formik.values,
        exitCriteria: [
          ...formik.values.exitCriteria,
          formik.values.newExitCriteria,
        ],
        newExitCriteria: "",
      });
    }
  };

  const handleRemoveExitCriteria = (indexToRemove: number) => {
    formik.setFieldValue(
      "exitCriteria",
      formik.values.exitCriteria.filter(
        (_: any, index: number) => index !== indexToRemove
      )
    );
  };
  const handleRemoveEntryCriteria = (indexToRemove: number) => {
    formik.setFieldValue(
      "entryCriteria",
      formik.values.entryCriteria.filter(
        (_: any, index: number) => index !== indexToRemove
      )
    );
  };
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          className={`web-create-strategy-modal-btn relative ${customClassName}`}
          onClick={handleOpen}
        >
          <img
            className="w-[20px] absolute left-[10px] top-[5px]"
            src={topshineIMG}
            alt=""
          />
          +{btnText}
          <img
            className="w-[20px] absolute right-[10px] bottom-[5px]"
            src={bottomshineIMG}
            alt=""
          />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[750px] web-strategy-modal-container-main ">
        <form
          className="strategy-creation-form"
          onSubmit={formik.handleSubmit}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        >
          <div
            className="web-createStrategi-wrppper"
            id="web-createStrategi-wrppper"
          >
            <div className="web-createStrategi-content-form">
              <div className="web-text-logo-wrapper">
                <div className="web-logo-text-container">
                  <h2 className="web-create-playbook-text">
                    📖 Create your{" "}
                    <span className="web-strategy-yellow-heading">
                      Strategy
                    </span>
                  </h2>
                </div>
              </div>

              <div className="web-inputs-limit-wrapper">
                <div className="web-lable">Strategy Name</div>
                <input
                  className="web-playbook-inputs"
                  type="text"
                  placeholder="Enter name..."
                  name="strategyName"
                  value={formik.values.strategyName}
                  onChange={formik.handleChange}
                />
                {formik.touched.strategyName && formik.errors.strategyName && (
                  <div className="error">{formik.errors.strategyName}</div>
                )}
              </div>

              <div className="web-play-book-criteria">
                <h1>Criteria</h1>

                <div className="web-criteria-inputs">
                  <div className="web-criteria-lable">Entry Criteria</div>
                  <div className="web-criteria">
                    <div className="web-entry-exit-criteria-all-inputs">
                      <div className="web-criteria-input-field">
                        <div className="web-six-dot-img">
                          <img src={sixdotsIMG} alt="" />
                        </div>
                        <div className="web-criteria-input-entry-exit">
                          <input
                            type="text"
                            className="web-playbook-inputs"
                            name="newEntryCriteria"
                            value={formik.values.newEntryCriteria}
                            onChange={formik.handleChange}
                            autoComplete="off"
                            placeholder="*Example* Entry Criteria"
                            onKeyDown={(e) => {
                              if (e.key == "Enter") {
                                addEntryCriteria();
                              }
                            }}
                          />
                          <Button
                            type="button"
                            className="web-plusbutton web-entry-exit-btn-plus"
                            color="primary"
                            onClick={addEntryCriteria}
                          >
                            +
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="web-currentNotes web-currentNotesStrategy">
                    {formik.values.entryCriteria.map(
                      (el: string, index: number) => (
                        <div className="web-currents-notes-wrapper-num-remove">
                          <p key={index} className="web-currentNotesPeraNumber">
                            <span className="web-currentNotesIndex">
                              {index + 1}
                            </span>
                            <span className="web-currentNotesPera">{el}</span>
                          </p>
                          <button
                            className="web-entry-exit-btn-minus"
                            type="button"
                            onClick={() => handleRemoveEntryCriteria(index)}
                          >
                            <CgClose />
                          </button>
                        </div>
                      )
                    )}
                  </div>

                  <div className="web-criteria-lable">Exit Criteria</div>
                  <div className="web-criteria">
                    <div className="web-entry-exit-criteria-all-inputs">
                      <div className="web-criteria-input-field">
                        <div className="web-six-dot-img">
                          <img src={sixdotsIMG} alt="" />
                        </div>
                        <div className="web-criteria-input-entry-exit">
                          <input
                            type="text"
                            className="web-playbook-inputs"
                            name="newExitCriteria"
                            value={formik.values.newExitCriteria}
                            onChange={formik.handleChange}
                            autoComplete="off"
                            placeholder="*Example* Exit Criteria"
                            onKeyDown={(e) => {
                              if (e.key == "Enter") {
                                addExitCriteria();
                              }
                            }}
                          />
                          <Button
                            type="button"
                            className="web-plusbutton web-entry-exit-btn-plus"
                            color="primary"
                            onClick={addExitCriteria}
                          >
                            +
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="web-currentNotes web-currentNotesStrategy">
                    {formik.values.exitCriteria.map(
                      (el: string, index: number) => (
                        <div className="web-currents-notes-wrapper-num-remove">
                          <p key={index} className="web-currentNotesPeraNumber">
                            <span className="web-currentNotesIndex">
                              {index + 1}
                            </span>
                            <span className="web-currentNotesPera">{el}</span>
                          </p>
                          <button
                            className="web-entry-exit-btn-minus"
                            type="button"
                            onClick={() => handleRemoveExitCriteria(index)}
                          >
                            <CgClose />
                          </button>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>

              <div className="web-modal-footer web-strategy-book-footer">
                <button
                  className="web-cancel-strategy-button relative"
                  type="button"
                  onClick={() => {
                    // formik.resetForm();
                    handleClose();
                  }}
                >
                  <img
                    className="w-[20px] absolute left-[12px] top-[7px]"
                    src={blacktopshineIMG}
                    alt=""
                  />
                  Cancel
                  <img
                    className="w-[20px] absolute right-[12px] bottom-[7px]"
                    src={blackbottomshineIMG}
                    alt=""
                  />
                </button>
                <button
                  className="web-create-strategy-button relative"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  <img
                    className="w-[20px] absolute left-[8px] top-[6px]"
                    src={topshineIMG}
                    alt=""
                  />
                  Create Strategy
                  <img
                    className="w-[20px] absolute right-[8px] bottom-[5px]"
                    src={bottomshineIMG}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateStrategyModal;
