import { useAppSelector } from "@/store/reduxHooks";
import { useEffect, useState } from "react";
import Joyride, { CallBackProps, Step } from "react-joyride";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { updateGuidedFlowStatus } from "../auth/api/auth";
import toast from "react-hot-toast";
export default function MultiRouteWrapper() {
  const userState = useAppSelector((state) => state.userState);
  const { user } = userState;
  const [stepIndex, setStepIndex] = useState(0);
  const navigate = useNavigate();
  const path = window.location.pathname;

  const updateGuidedStatus = useMutation({
    mutationFn: updateGuidedFlowStatus,
    onSuccess: (data) => {
      console.log("data:", data);
      window.location.href = "/";
    },
    onError: (error) => {
      toast.error(<p>Something went wrong!Please try again!</p>, {
        duration: 2000,
      });
    },
  });

  const steps: Step[] = [
    {
      target: "#broker-id-select-guide",
      content: (
        <>
          <h1>
            Betwizr users are able to manage multiple broker IDs under one
            account. By using this dropdown menu, they can select a specific
            broker ID which they have added to their account to view its
            details.
          </h1>
        </>
      ),
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: "#betwizr_Sync_now_button",
      content: (
        <>
          <h1>
            The Sync Now button enables users to sync missing trade data for any
            of their broker IDs, ensuring that the data in their Betwizr account
            is always up to date.
          </h1>
        </>
      ),
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: "#pl-insight-guide",
      content: (
        <>
          <h1>
            This section provides insights into trades taken within the selected
            date range, offering details on ROI, Win/Loss per trade, number of
            trades taken, and accumulated charges.
          </h1>
        </>
      ),
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: "#wizr-graph-guide",
      content: (
        <>
          <h1>
            The Wizr Score is a user's performance score determined by factors
            such as profit factor, risk-to-reward ratio (R:R), win rate, and
            psychology.
          </h1>
        </>
      ),
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: "#pl-growth-cumulative-guide",
      content: (
        <>
          <h1>
            This graph represents the cumulative profit/loss (P/L) growth over
            the selected date range.
          </h1>
        </>
      ),
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: "#pl-growth-daily-guide",
      content: (
        <>
          <h1>
            This graph shows the day-wise profit/loss (P/L) captured throughout
            the selected date range.
          </h1>
        </>
      ),
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: "#trade-score-overtime-guide",
      content: (
        <>
          <h1>
            This graph represents the changes in Wizr Score captured over the
            selected date range.
          </h1>
        </>
      ),
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: "#trade-plan-dicipline-guide",
      content: (
        <>
          <h1>
            The Trade Plan Discipline section displays losses incurred when the
            trader does not adhere to the trade plan or rules they have
            established.
          </h1>
        </>
      ),
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: "#instrument-perf-guide",
      content: (
        <>
          <h1>
            This section displays the script-wise performance of the user,
            color-coded from green (indicating profitable performances) to red
            (indicating loss-making performances).
          </h1>
        </>
      ),
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: "#pl-tod-guide",
      content: (
        <>
          <h1>
            This graph illustrates the trader's profits and losses within the
            selected date range, segmented by time of day. It helps users
            identify the optimal trading time for them.
          </h1>
        </>
      ),
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: "#mindset-graph-guide",
      content: (
        <>
          <h1>
            This section reflects the trader's mindset during the trades taken
            within the specified date range, based on the data provided in the
            exit protocol form for each trade.
          </h1>
        </>
      ),
      placement: "auto",
      disableBeacon: true,
      data: {
        next: "/day-level-analysis",
      },
    },

    {
      target: "#day-level-analysis-calender-guide",
      content: (
        <>
          <h1>
            This section offers a calendar view of the entire month, enabling
            users to evaluate their daily performance at a glance.
          </h1>
        </>
      ),
      placement: "auto",
      disableBeacon: true,
      data: {
        previous: "/",
      },
    },
    {
      target: "#day-level-analysis-calendar-sidebar-guide",
      content: (
        <>
          <h1>
            This section presents the aggregated value of big win/loss days or
            win/loss days visible for the current month on the calendar. The
            goal for the user is to minimize the occurrence of big loss days in
            order to become a profitable trader.
          </h1>
        </>
      ),
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: "#chart-replay-guide",
      content: (
        <>
          <h1>
            This section displays the candlestick patterns of the trades taken
            by the user, allowing them to review their trade decisions.
          </h1>
        </>
      ),
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: "#chart-replay-sidebar-guide",
      content: (
        <>
          <h1>
            This section provides additional insights into the trades taken,
            including notes captured by the user after each trade.
          </h1>
        </>
      ),
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: "#exit-protocol-history-guide",
      content: (
        <>
          <h1>
            This section serves as a journal, displaying all exit protocol data
            captured for the day. Users can revisit and learn from their trade
            experiences.
          </h1>
        </>
      ),
      placement: "auto",
      disableBeacon: true,

      data: {
        next: "/strategy",
      },
    },

    {
      target: "#strategy-analysis-guide",
      content: (
        <>
          <h1>
            This section ranks the user-created strategies based on performance,
            listing them from best to worst.
          </h1>
        </>
      ),
      placement: "auto",
      disableBeacon: true,

      data: {
        previous: "/day-level-analysis",
      },
    },
    {
      target: "#strategy-table-guide",
      content: (
        <>
          <h1>
            This section displays all strategies created by the user to date,
            including details of previous versions.
          </h1>
        </>
      ),
      placement: "auto",
      disableBeacon: true,

      data: {
        next: "/profiles",
      },
    },

    {
      target: "#trader-profile-list-guide",
      content: (
        <>
          <h1>
            This section allows users to create or edit trader profiles defined
            for respective broker IDs, which are used for all calculations and
            analyses.
          </h1>
        </>
      ),
      placement: "auto",
      disableBeacon: true,
      data: {
        previous: "/strategy",
      },
    },
  ];
  const [run, setRun] = useState(false);

  const scrollToElementWithOffset = (id: string, offset = 100) => {
    const element = document.getElementById(id);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      setTimeout(() => {
        window.scrollTo({
          top: offsetPosition,
          behavior: "auto",
        });
      }, 100);
    }
  };

  const handleCallback = (data: CallBackProps) => {
    const { action, index, step, type } = data;

    if (type === "step:after") {
      if (action === "next") {
        setStepIndex((prev) => prev + 1);
        if (step.data && step.data.next) {
          navigate(step.data.next);
        }
      } else if (action === "prev") {
        setStepIndex((prev) => prev - 1);
        if (step.data && step.data.previous) {
          navigate(step.data.previous);
        }
      }
    } else if (type === "tooltip" && action === "update") {
      scrollToElementWithOffset((step.target as string).replace("#", ""));
    } else if (type === "tour:end" || type === "error:target_not_found") {
      setRun(false);
      setStepIndex(0);
      updateGuidedStatus.mutate();
    }
  };

  useEffect(() => {
    if (user.profile_set_flag && !user.go_seen_flag && path == "/") {
      setTimeout(() => {
        setRun(true);
      }, 1000);
    }
  }, [user]);

  return (
    <div>
      <Joyride
        callback={handleCallback}
        continuous
        run={run}
        stepIndex={stepIndex}
        steps={steps}
        showSkipButton
        scrollToFirstStep
        showProgress
        disableOverlayClose
        disableScrolling
        hideCloseButton
        styles={{
          options: {
            zIndex: 999999,
            arrowColor: "#262626",
            backgroundColor: "#262626",
            textColor: "#ffffff",
            primaryColor: "#fba137",
          },
          buttonNext: {
            color: "#181818",
            fontWeight: "600",
          },
          tooltipFooter: {
            marginTop: "0px",
          },
          buttonSkip: {
            color: "#fba137",
            textDecoration: "underline",
          },
        }}
      />
    </div>
  );
}
