import { DateRangeType } from "@/pages/auth/authSlice";
import { DateRange } from "react-day-picker";

export const checkMobileDevice = () => {
  // Check screen width
  const screenWidth = window.innerWidth;
  const isMobile = screenWidth < 1020; // Assume width less than 1020px is mobile
  // Check user agent for tablet
  const isTablet = /iPad|Android/i.test(navigator.userAgent);
  // Return true if it's either mobile or tablet
  return isMobile || isTablet;
};

export const convertToDateRange = (dateRangeType: DateRangeType): DateRange => {
  return {
    from: new Date(dateRangeType.from),
    to: new Date(dateRangeType.to),
  };
};
