import React, { useEffect, useState } from "react";
import "./verifyuser.css";
import { Button } from "@/components/ui/button";
import OtpInput from "react-otp-input";
import { useAppDispatch, useAppSelector } from "@/store/reduxHooks";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { sendMobileOtp, verifyMobileOtp } from "@/module/auth/api/auth";
import { useNavigate } from "react-router-dom";
import { setPhOtpState } from "../authSlice";
import betLogo from "@/assets/bet-logo.svg";
import topshineIMG from "@/assets/web-top-shine.svg";
import bottomshineIMG from "@/assets/web-bottom-shine.svg";

const errorPattern =
  /HTTP 404 error: Unable to create record: The requested resource \/Services\/([A-Za-z0-9]+)\/VerificationCheck was not found/;

const VerifyPhNumber = () => {
  const { user } = useAppSelector((store) => store.userState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isPhOtpSent } = useAppSelector((state) => state.userState);

  const [otp, setOtp] = useState<string>("");
  const [resentOtp, setResentOtp] = useState(false);

  const handleOTPChange = (otp: string) => {
    setOtp(otp);
  };

  const phoneOtp = useMutation({
    mutationFn: sendMobileOtp,
    onSuccess: (data) => {
      if (data.status == "SUCCESS") {
        toast.success(<p>Mobile number is already verified !</p>, {
          duration: 2000,
        });
        navigate("/consent");
        return;
      }

      if (data.status == "PENDING") {
        if (resentOtp) {
          setResentOtp(false);
        }
        toast.success(
          <p>
            Otp has been sent to registered phone number! Please check your your
            messages!
          </p>,
          {
            duration: 2000,
          }
        );
        dispatch(setPhOtpState());
      }
    },
    onError: (error) => {
      toast.error(<p>Something went wrong!Please try again!</p>, {
        duration: 2000,
      });
    },
  });
  const phNumberOtpVerification = useMutation({
    mutationFn: verifyMobileOtp,
    onSuccess: (data) => {
      if (data.status == "SUCCESS") {
        toast.success(<p>Phone Number has been verified Successfully!</p>, {
          duration: 2000,
        });
        queryClient.invalidateQueries("user");
        navigate("/consent");

        return;
      }
      if (data.status == "PENDING") {
        toast.success(<p>Invalid Otp!</p>, {
          duration: 2000,
        });
        return;
      }
    },
    onError: (error: any) => {
      const match = errorPattern.exec(error.response?.data);
      if (error && error.response && error.response.data && match) {
        toast.error(<p>Otp has been expired!</p>, {
          duration: 2000,
        });
        setResentOtp(true);
        return;
      }
      toast.error(<p>Something went wrong!Please try again!</p>, {
        duration: 2000,
      });
    },
  });

  const haldlePhNumberVerification = () => {
    phNumberOtpVerification.mutate(otp);
  };

  useEffect(() => {
    if (!isPhOtpSent && !user.phone_number_confirmed_at) {
      phoneOtp.mutate();
    }
  }, []);
  const onlyNumberKey = (event: any) => {
    if (event.which < 48 || event.which > 57) {
      event.preventDefault();
    }
  };

  return (
    <div className="verify-user-page">
      <div className="dialog-content">
        <div className="verify-email-otp-logo-text flex items-center justify-center">
          <div className="logo-img varify-email-otp-logo ">
            <img src={betLogo} alt="" />
          </div>
        </div>
        <p className="verify-email-head">Verify your Phone Number</p>
        <p className="verify-email-second-head">
          We have sent you a verification code, to the registered phone number.
          Please enter it.
        </p>

        <OtpInput
          value={otp}
          onChange={handleOTPChange}
          numInputs={6}
          inputType="text"
          renderSeparator={<span></span>}
          shouldAutoFocus
          renderInput={(props) => (
            <input
              {...props}
              className="otp-input"
              onKeyPress={onlyNumberKey}
              style={{
                borderBottom: "2px solid #ccc", // Add a border only at the bottom
                width: "60px",
                height: "60px",
                fontSize: "40px",
                textAlign: "center",
                outline: "none",
                color: "white", // Set the input text color to white
                background: "transparent",
              }}
            />
          )}
          containerStyle={{
            display: "flex",
            justifyContent: "space-around",
            margin: "20px 0",
          }}
        />
        <div className="verify-and-resend-btn flex items-center justify-center gap-4">
          <Button
            className="email-otp-verify-button relative"
            onClick={haldlePhNumberVerification}
          >
            <img
              className="w-[20px] absolute left-[10px] top-[6px]"
              src={topshineIMG}
              alt=""
            />
            Verify
            <img
              className="w-[20px] absolute right-[10px] bottom-[6px]"
              src={bottomshineIMG}
              alt=""
            />
          </Button>
          {resentOtp && (
            <Button
              className="email-otp-verify-button resend-otp-button"
              onClick={() => {
                phoneOtp.mutate();
                setOtp("");
              }}
            >
              Resend Otp
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyPhNumber;
