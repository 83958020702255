import * as Yup from "yup";

export const TraderProfileValidationObjects = {
  dailyRiskLimitPtg: Yup.number()
    .typeError("Enter a valid value")
    .min(0.0001, "Daily  Risk Limit must be greater than zero")
    .required("Daily Limit is Required"),
  weeklyRiskLimitPtg: Yup.number()
    .typeError("Enter a valid value")
    .min(0.0001, "Weekly Risk Limit must be greater than zero.")
    .required("Weekly Risk Limit is required"),
  dailyProfitTargetPtg: Yup.number()
    .typeError("Enter a valid value")
    .min(0.0001, "Daily  Profit Target must be greater than zero")
    .required("Daily profit target is required"),
  weeklyProfitTargetPtg: Yup.number()
    .typeError("Enter a valid value")
    .min(0.0001, "Weekly Profit Target must be greater than zero")
    .required("Weekly Profit Target is required"),
  tradesPerDay: Yup.number()
    .typeError("Enter a valid value")
    .min(0.0001, "Daily No. of trades  must be greater than zero")
    .required("Daily No. of trades  is required"),
  scrip: Yup.string().required("Scrip is required"),
  brokerId: Yup.string().required("Broker id is required."),
  brokerName: Yup.string().required("Broker name is required."),
  chargesLimitPerDayPtg: Yup.number()
    .typeError("Enter a valid value")
    .min(0.0001, "Charges Limit Per Day must be greater than zero")
    .required("Charges Limit Per Day is required"),
  accountSize: Yup.number()
    .typeError("Enter a valid value")
    .typeError("Enter a valid value")
    .min(0.0001, "Account Size must be greater than zero")
    .required("Account Size is required"),
  tradeRiskLimitPtg: Yup.number()
    .typeError("Enter a valid value")
    .min(0.0001, "Trade Level Risk must be greater than zero")
    .required("Trade Level Risk is required"),
  desiredReturnMultiple: Yup.number()
    .typeError("Enter a valid value")
    .min(0.0001, "Desired R:R must be greater than zero")
    .required("Desired R:R is required"),
  traderType: Yup.string().required("Trader Type is required"),
};

export const TraderProfileValidationSchema = Yup.object(
  TraderProfileValidationObjects
);
export const createTraderProfileValidationSchema = Yup.object({
  ...TraderProfileValidationObjects,
  brokerName: Yup.string().required("Broker name is required"),
  brokerId: Yup.string().required("Broker Id is required"),
});
