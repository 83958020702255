import * as Yup from "yup";
const phoneRegExp = /^[6-9]\d{9}$/;
export const loginValidation = Yup.object().shape({
  username: Yup.string().required("Please type in a valid username."),
  password: Yup.string().required("Please enter a valid password."),
});

export const changePasswordValidation = Yup.object().shape({
  old_password: Yup.string().required("Please enter a valid password."),
  new_password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{7,20}\S$/,
      "Invalid Password. Must be at least 8 characters with uppercase, lowercase, special character, and a numeric digit. No spaces allowed."
    )
    .required("Please enter a valid password."),
});

export const registerValidation = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Email address is required"),
  name: Yup.string().required("Please enter your Full Name."),
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{7,20}\S$/,
      "Invalid Password. Must be at least 8 characters with uppercase, lowercase, special character, and a numeric digit. No spaces allowed."
    )
    .required("Please enter a valid password."),
  confirmPassword: Yup.string()
    .required("Required")
    .test("password-match", "Passwords must match.", function (value) {
      return this.parent.password === value;
    }),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Invalid phone number")
    .required("Phone number is required"),
});

export const emailValidation = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Email address is required"),
});

export const resetPasswordValidation = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Email address is required"),
  new_password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{7,20}\S$/,
      "Invalid Password. Must be at least 8 characters with uppercase, lowercase, special character, and a numeric digit. No spaces allowed."
    )
    .required("Please enter a valid password."),
  confirm_new_password: Yup.string()
    .required("Required")
    .test("password-match", "Passwords must match.", function (value) {
      return this.parent.new_password === value;
    }),
  code: Yup.number().required("please enter the otp"),
});
