import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import {
  traderProfileDataType,
  traderProfileType,
} from "@/module/Profile/interface/ProfileInterface";
import { TraderProfileValidationSchema } from "@/validation/TraderProfileValidation";
import { FormikHelpers, useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  capitalizeFirstLetterOfWords,
  isEqualIgnoreCase,
  traderTypeOptionsType,
} from "@/pages/profiles/editProfile/helper";
import { useMutation, useQueryClient } from "react-query";
import {
  GetTraderProfileResponse,
  updateTraderProfile,
} from "@/module/Profile/api/traderProfile";
import toast from "react-hot-toast";
import "@/pages/profiles/editProfile/editprofilemodal.css";
import topshineIMG from "@/assets/web-top-shine.svg";
import bottomshineIMG from "@/assets/web-bottom-shine.svg";

import {
  ScripOptionType,
  getError,
  initialProfileData,
  initialTradeProfile,
  scripOptions,
  traderTypeOptions,
} from "@/pages/profiles/editProfile/helper";

export function Editprofilemodal({
  profileData,
  open,
  setOpen,
}: {
  profileData: GetTraderProfileResponse;
  open: boolean;
  setOpen: (value: boolean) => void;
}) {
  const [initialValues, setInitialvalues] = useState(initialProfileData);
  const OldProfiledata = {
    brokerId: profileData.broker_id,
    brokerName: profileData.broker_name,
    dailyRiskLimitPtg: profileData.daily_risk_limit_ptg,
    weeklyRiskLimitPtg: profileData.weekly_risk_limit_ptg,
    tradesPerDay: profileData.trades_per_day,
    scrip: profileData.scrip,
    dailyProfitTargetPtg: profileData.daily_profit_target_ptg,
    weeklyProfitTargetPtg: profileData.weekly_profit_target_ptg,
    chargesLimitPerDayPtg: profileData.charges_limit_per_day_ptg,
    accountSize: profileData.account_size,
    tradeRiskLimitPtg: profileData.trade_risk_limit_ptg,
    desiredReturnMultiple: profileData.desired_r_multiple,
    traderType: profileData.trader_type,
  };

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: updateTraderProfile,
    onSuccess: (data) => {
      toast.success(<p> Trader Profile Updated Successfully!</p>, {
        duration: 2000,
      });
      queryClient.invalidateQueries("userTradeProfile");
      queryClient.invalidateQueries("allTraderProfiles");
    },
    onError: (error) => {
      toast.error(<p>Error while updating Trader Profile!</p>, {
        duration: 2000,
      });
    },
  });

  const [traderProfile, setTraderProfile] =
    useState<traderProfileType>(initialTradeProfile);

  const onSubmit = (
    values: traderProfileDataType,
    actions: FormikHelpers<traderProfileDataType>
  ) => {
    const data: traderProfileType = {
      broker_id: values.brokerId,
      broker_name: values.brokerName,
      trader_type: values.traderType,
      daily_risk_limit_ptg: values.dailyRiskLimitPtg,
      weekly_risk_limit_ptg: values.weeklyRiskLimitPtg,
      trades_per_day: values.tradesPerDay,
      scrip: values.scrip,
      daily_profit_target_ptg: values.dailyProfitTargetPtg,
      weekly_profit_target_ptg: values.weeklyProfitTargetPtg,
      charges_limit_per_day_ptg: values.chargesLimitPerDayPtg,
      account_size: values.accountSize,
      trade_risk_limit_ptg: values.tradeRiskLimitPtg,
      desired_r_multiple: values.desiredReturnMultiple,
    };
    mutation.mutate(data);
    setTimeout(() => {
      actions.setSubmitting(false);
      setOpen(false);
    }, 500);
  };

  useEffect(() => {
    setTraderProfile(profileData);
  }, [profileData]);

  useEffect(() => {
    formik.setValues({
      traderType: traderProfile.trader_type
        ? capitalizeFirstLetterOfWords(traderProfile.trader_type)
        : "Day",
      brokerId: traderProfile.broker_id
        ? capitalizeFirstLetterOfWords(traderProfile.broker_id)
        : "",
      brokerName: traderProfile.broker_name
        ? capitalizeFirstLetterOfWords(traderProfile.broker_name)
        : "",
      dailyRiskLimitPtg: traderProfile.daily_risk_limit_ptg
        ? traderProfile.daily_risk_limit_ptg
        : 0,
      weeklyRiskLimitPtg: traderProfile.weekly_risk_limit_ptg
        ? traderProfile.weekly_risk_limit_ptg
        : 0,
      tradesPerDay: traderProfile.trades_per_day
        ? traderProfile.trades_per_day
        : 0,

      scrip: traderProfile.scrip
        ? capitalizeFirstLetterOfWords(traderProfile.scrip)
        : "",
      dailyProfitTargetPtg: traderProfile.daily_profit_target_ptg
        ? traderProfile.daily_profit_target_ptg
        : 0,
      weeklyProfitTargetPtg: traderProfile.weekly_profit_target_ptg
        ? traderProfile.weekly_profit_target_ptg
        : 0,
      chargesLimitPerDayPtg: traderProfile.charges_limit_per_day_ptg
        ? traderProfile.charges_limit_per_day_ptg
        : 0,
      accountSize: traderProfile.account_size ? traderProfile.account_size : 0,
      tradeRiskLimitPtg: traderProfile.trade_risk_limit_ptg
        ? traderProfile.trade_risk_limit_ptg
        : 0,
      desiredReturnMultiple: traderProfile.desired_r_multiple
        ? traderProfile.desired_r_multiple
        : 1,
    });

    if (traderProfile) {
      const chargesPercentage = traderProfile.charges_limit_per_day_ptg || 0; // Ensure chargesPercentage is a number
      const accountSize = traderProfile.account_size || 1; // Avoid division by zero
      setChargesValue(
        Number(((chargesPercentage * accountSize) / 100).toFixed(2))
      );
    }
  }, [traderProfile]);

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: TraderProfileValidationSchema,
  });

  /// Convert charges percentage to charges value and charges percentage to charges value

  const [chargesValue, setChargesValue] = useState(0);

  const convertToChargesPercentage = () => {
    const charges = chargesValue || 0; // Ensure chargesValue is a number
    const accountSize = formik.values.accountSize || 1; // Avoid division by zero
    const percentageValue = ((charges / accountSize) * 100).toFixed(2);
    formik.setFieldValue("chargesLimitPerDayPtg", percentageValue);
  };

  const convertToChargesValue = () => {
    const chargesPercentage = formik.values.chargesLimitPerDayPtg || 0; // Ensure chargesPercentage is a number
    const accountSize = formik.values.accountSize || 1; // Avoid division by zero
    const value = Number(((chargesPercentage * accountSize) / 100).toFixed(2));
    setChargesValue(value);
  };

  const handleKeyPress = (event: any) => {
    const charCode = event.charCode;

    // Allow digits (0-9) and the decimal point (.)
    if (!((charCode >= 48 && charCode <= 57) || charCode === 46)) {
      event.preventDefault();
    }
  };

  const isValueChanged = !isEqualIgnoreCase(formik.values, OldProfiledata);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[650px] web-trader-profile-container-main">
        <div className="web-betwizr-trader-profile-inputs">
          <div className="web-text-logo-wrapper">
            <div className="web-logo-text-container">
              <h2 className="web-trader-profile-heading-main">
                Edit Trader Profile
              </h2>
              <h4 className="web-trader-profile-heading-sub">
                We will track your trading discipline based on limits set by you
              </h4>
            </div>
          </div>
          <form
            className="web-traderProfile-form-field"
            onSubmit={formik.handleSubmit}
          >
            <div className="web-edit-trader-broker-id-password">
              <div className="web-all-inputs-box">
                <h6 className="web-broker-name-id">Broker Name</h6>
                <div className="web-broker-id-name-box">
                  {traderProfile.broker_name}
                </div>
              </div>
              <div className="web-all-inputs-box">
                <h6 className="web-broker-name-id">
                  Broker Id{" "}
                  <span className="user-id-used-to-text">
                    (User ID used to login to broker terminal)
                  </span>
                </h6>

                <div className="web-broker-id-name-box">
                  {traderProfile.broker_id}
                </div>
              </div>
            </div>
            <div className="web-inputs-limit-wrapper">
              <div className="web-risk-profit">
                <div className="web-type-daily web-info-limit-text">
                  <div className="web-type">
                    <h6>Type</h6>
                  </div>
                  <div className="web-day-week-info">
                    <h6>
                      Daily <span className="opacity-50 text-sm">(in %)</span>
                    </h6>
                    <h6>
                      Weekly <span className="opacity-50 text-sm">(in %)</span>
                    </h6>
                  </div>
                </div>
                <div className="web-risk-profit-target web-all-inputs-wrapper">
                  <div className="web-risk-limit web-all-inputs-box">
                    <h6>Risk Limit</h6>

                    <div className="web-inputs-imgs">
                      <div className="input-width-percent-sign relative">
                        <input
                          className="padding-right-input"
                          name="dailyRiskLimitPtg"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          type="text"
                          value={formik.values.dailyRiskLimitPtg}
                          onKeyPress={handleKeyPress}
                        />
                        <span className="inline-flex items-center percent-inside-input">
                          |{"  "}%
                        </span>
                      </div>
                      <div className="web-division-sign text-2xl opacity-50">
                        |
                      </div>
                      <div className="input-width-percent-sign relative">
                        <input
                          className="padding-right-input"
                          type="text"
                          name="weeklyRiskLimitPtg"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.weeklyRiskLimitPtg}
                          onKeyPress={handleKeyPress}
                        />
                        <span className="inline-flex items-center percent-inside-input">
                          |{"  "}%
                        </span>
                      </div>
                      {/* <div className="info-icon-img">
                        <img src={InfoIcon} alt="" />
                      </div> */}
                    </div>
                  </div>
                  <div className="web-error-message-red">
                    {(() => {
                      const errors = getError(formik.errors);
                      return (
                        <p>
                          {errors.combinedRiskLimit ||
                            errors.weeklyRiskLimitPtg ||
                            errors.dailyRiskLimitPtg}
                        </p>
                      );
                    })()}
                  </div>
                  <div className="web-profit-target web-all-inputs-box">
                    <h6>Profit Target</h6>

                    <div className="web-inputs-imgs">
                      <div className="input-width-percent-sign relative">
                        <input
                          className="padding-right-input"
                          type="text"
                          name="dailyProfitTargetPtg"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.dailyProfitTargetPtg}
                          onKeyPress={handleKeyPress}
                        />
                        <span className="inline-flex items-center percent-inside-input">
                          |{"  "}%
                        </span>
                      </div>
                      <div className="web-division-sign text-2xl opacity-50">
                        |
                      </div>
                      <div className="input-width-percent-sign relative">
                        <input
                          className="padding-right-input"
                          type="text"
                          name="weeklyProfitTargetPtg"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.weeklyProfitTargetPtg}
                          onKeyPress={handleKeyPress}
                        />
                        <span className="inline-flex items-center percent-inside-input">
                          |{"  "}%
                        </span>
                      </div>
                      {/* <div className="info-icon-img">
                        <img src={InfoIcon} alt="" />
                      </div> */}
                    </div>
                  </div>
                  <div className="web-error-message-red">
                    {(() => {
                      const errors = getError(formik.errors);
                      return (
                        <p>
                          {errors.combinedProfitTarget ||
                            errors.weeklyProfitTargetPtg ||
                            errors.dailyProfitTargetPtg}
                        </p>
                      );
                    })()}
                  </div>
                </div>
              </div>
              <div className="web-divide-line"></div>
              <div className="web-trade-all">
                <div className="web-type-daily web-info-limit-text">
                  <div className="web-type">
                    <h6>Type</h6>
                  </div>
                  <div className="web-day-week-info">
                    <h6 className="web-limit-heading-mr-right">Limit</h6>
                  </div>
                </div>
                <div className="web-risk-profit-target web-all-inputs-wrapper">
                  <div className="web-profit-target web-all-inputs-box">
                    <h6>Account Size</h6>

                    <div className="web-inputs-imgs ">
                      <div className="input-width-percent-sign relative">
                        <span className="inline-flex items-center rupees-inside-input">
                          ₹{"  "}|
                        </span>
                        <input
                          className="web-big-input-box padding-left-input padding-right-big-input"
                          type="text"
                          name="accountSize"
                          onChange={formik.handleChange}
                          onBlur={(e) => {
                            formik.handleBlur(e);
                            convertToChargesValue();
                          }}
                          value={formik.values.accountSize}
                          onKeyPress={handleKeyPress}
                        />
                      </div>

                      {/* <div className="info-icon-img">
                        <img src={InfoIcon} alt="" />
                      </div>  */}
                    </div>
                  </div>
                  <div className="web-error-message-red">
                    {formik.touched.accountSize &&
                      formik.errors.accountSize && (
                        <p>{formik.errors.accountSize.toString()}</p>
                      )}
                  </div>
                  <div className="web-risk-limit web-all-inputs-box">
                    <h6>Trade Level Risk </h6>

                    <div className="web-inputs-imgs">
                      <div className="input-width-percent-sign relative">
                        <input
                          className="web-big-input-box padding-right-input padding-left-big-input"
                          type="text"
                          name="tradeRiskLimitPtg"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.tradeRiskLimitPtg}
                          onKeyPress={handleKeyPress}
                        />
                        <span className="inline-flex items-center percent-inside-input">
                          |{"  "}%
                        </span>
                      </div>
                      {/* 
                      <div className="info-icon-img">
                        <img src={InfoIcon} alt="" />
                      </div> */}
                    </div>
                  </div>
                  <div className="web-error-message-red">
                    {formik.touched.tradeRiskLimitPtg &&
                      formik.errors.tradeRiskLimitPtg && (
                        <p>{formik.errors.tradeRiskLimitPtg.toString()}</p>
                      )}
                  </div>
                  <div className="web-profit-target web-all-inputs-box">
                    <h6>Daily No. of Trades</h6>

                    <div className="web-inputs-imgs">
                      <input
                        className="web-big-input-box"
                        type="text"
                        name="tradesPerDay"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.tradesPerDay}
                        onKeyPress={handleKeyPress}
                      />

                      {/* <div className="info-icon-img">
                        <img src={InfoIcon} alt="" />
                      </div> */}
                    </div>
                  </div>
                  <div className="web-error-message-red">
                    {formik.touched.tradesPerDay &&
                      formik.errors.tradesPerDay && (
                        <p>{formik.errors.tradesPerDay.toString()}</p>
                      )}
                  </div>
                  <div className="web-profit-target web-all-inputs-box">
                    <h6>Charges per day ₹/%</h6>

                    <div className="web-inputs-imgs">
                      <div className="input-width-percent-sign relative">
                        <span className="inline-flex items-center rupees-inside-input">
                          ₹{"  "}|
                        </span>
                        <input
                          className="padding-left-input"
                          type="text"
                          value={chargesValue}
                          onChange={(e) =>
                            setChargesValue(Number(e.target.value))
                          }
                          onBlur={convertToChargesPercentage}
                          onKeyPress={handleKeyPress}
                        />
                      </div>
                      <div className="web-division-sign text-2xl opacity-50">
                        |
                      </div>
                      <div className="input-width-percent-sign relative">
                        <input
                          className="padding-right-input"
                          type="text"
                          name="chargesLimitPerDayPtg"
                          onChange={formik.handleChange}
                          onBlur={(e) => {
                            formik.handleBlur(e);
                            convertToChargesValue();
                          }}
                          value={formik.values.chargesLimitPerDayPtg}
                          onKeyPress={handleKeyPress}
                        />
                        <span className="inline-flex items-center percent-inside-input">
                          |{"  "}%
                        </span>
                      </div>
                      {/* <div className="info-icon-img">
                        <img src={InfoIcon} alt="" />
                      </div> */}
                    </div>
                  </div>
                  <div className="web-error-message-red">
                    {formik.touched.chargesLimitPerDayPtg &&
                      formik.errors.chargesLimitPerDayPtg && (
                        <p>{formik.errors.chargesLimitPerDayPtg.toString()}</p>
                      )}
                  </div>
                  <div className="web-profit-target web-all-inputs-box">
                    <h6>Scrip Mostly Traded</h6>

                    <div className="web-inputs-imgs">
                      <select
                        className="web-scrip-mostly-select-trade"
                        name="scrip"
                        value={formik.values.scrip}
                        onChange={formik.handleChange}
                      >
                        {scripOptions.map(
                          (el: ScripOptionType, index: number) => (
                            <option
                              className="web-scrip-option-trade"
                              key={index}
                              value={el.value}
                            >
                              {el.label}
                            </option>
                          )
                        )}
                      </select>
                      {/* <div className="info-icon-img">
                        <img src={InfoIcon} alt="" />
                      </div> */}
                    </div>
                  </div>
                  <div className="web-error-message-red">
                    {formik.touched.scrip && formik.errors.scrip && (
                      <p>{formik.errors.scrip.toString()}</p>
                    )}
                  </div>
                  <div className="web-profit-target web-all-inputs-box">
                    <h6>Desired R:R</h6>

                    <div className="web-inputs-imgs">
                      <p className="web-desired-rr-input-trader-profilefile">
                        1 :
                      </p>
                      <input
                        type="text"
                        name="desiredReturnMultiple"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.desiredReturnMultiple}
                        onKeyPress={handleKeyPress}
                      />

                      {/* <div className="info-icon-img">
                        <img src={InfoIcon} alt="" />
                      </div> */}
                    </div>
                  </div>
                  <div className="web-error-message-red">
                    {formik.touched.desiredReturnMultiple &&
                      formik.errors.desiredReturnMultiple && (
                        <p>{formik.errors.desiredReturnMultiple.toString()}</p>
                      )}
                  </div>

                  <div className="web-profit-target web-all-inputs-box">
                    <h6>Trader Type</h6>

                    <div className="web-inputs-imgs">
                      <select
                        className="web-trader-type-select-trade"
                        name="traderType"
                        value={formik.values.traderType}
                        onChange={formik.handleChange}
                      >
                        {traderTypeOptions.map(
                          (el: traderTypeOptionsType, index: number) => (
                            <option
                              key={index}
                              value={el.value}
                              className="web-trader-type-option-trade"
                              disabled={el.disabled}
                            >
                              {el.label}
                            </option>
                          )
                        )}
                      </select>
                      {/* <div className="info-icon-img">
                        <img src={InfoIcon} alt="" />
                      </div> */}
                    </div>
                  </div>
                  <div className="web-error-message-red">
                    {formik.touched.traderType && formik.errors.traderType && (
                      <p>{formik.errors.traderType.toString()}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="web-modal-footer web-traderprofile-footer">
              <button
                className="web-traderprofile-save-btn relative"
                onClick={() => setOpen(false)}
                type="button"
              >
                <img
                  className="w-[24px] absolute left-[12px] top-[7px]"
                  src={topshineIMG}
                  alt=""
                />
                Cancel
                <img
                  className="w-[24px] absolute right-[12px] bottom-[7px]"
                  src={bottomshineIMG}
                  alt=""
                />
              </button>
              <button
                className="web-traderprofile-save-btn relative"
                type="submit"
                disabled={formik.isSubmitting || !isValueChanged}
              >
                <img
                  className="w-[24px] absolute left-[12px] top-[7px]"
                  src={topshineIMG}
                  alt=""
                />
                Save
                <img
                  className="w-[24px] absolute right-[12px] bottom-[7px]"
                  src={bottomshineIMG}
                  alt=""
                />
              </button>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}
