import Layout from "@/components/Layout";
import "@/pages/dashboard/Dashboard.css";
import { useState } from "react";
import { DateRange } from "react-day-picker";
import Header from "@/components/Header";
import ScripMap from "@/components/charts/ScripMap";
import TradeScoreOvertimeChart from "@/components/charts/TradeScoreOvertimeChart";
import TradePlanDiscipline from "@/components/charts/TradePlanDiscipline";
import TodChart from "@/components/charts/TodChart";
import PLGrowthChartCummulative from "@/components/charts/PLGrowthChartCummulative";
import WizrGraph from "@/components/charts/WizrGraph";
import MindsetGraph from "@/components/charts/MindsetGraph";
import PLGrowthChartDaily from "@/components/charts/PLGrowthChartDaily";
import PLInsights from "@/components/charts/PLInsights";
import { useAppSelector } from "@/store/reduxHooks";
import { convertToDateRange } from "@/utils/helper";
import { Tooltip } from "react-tooltip";
import infoIcon from "@/assets/InfoIcon.svg";
import CustomCalendar from "./CustomCalendar";

export type GrowthOvertime = "daily" | "cummulative";

const Dashboard = () => {
  const { dateRange } = useAppSelector((state) => state.userState);
  const modifiedDateRange: DateRange = convertToDateRange(dateRange);

  const [date, setDate] = useState<DateRange | undefined>(modifiedDateRange);

  const [showGrowthOverTime, setShowGrowthOverTime] =
    useState<GrowthOvertime>("cummulative");

  return (
    <Layout>
      <Header>
        <CustomCalendar date={date} setDate={setDate} />
      </Header>
      <div className="main-content padding-top-main-content">
        <div className="main-content-left">
          <div className="roi-co-pilot-two-box">
            <div className="roi-co-pilot-wraper">
              <PLInsights date={date} />
              <WizrGraph date={date} />
            </div>
          </div>
          <div className="pnl-section">
            <div className="pnl-header-section">
              <div className="pnl-section-heading-and-buttons">
                <h3>
                  <span>P&L Growth </span> Overtime
                </h3>
                <div className="daily-btn position-relative">
                  <div className="cumulative-btn position-relative">
                    <button
                      onClick={() => {
                        setShowGrowthOverTime("cummulative");
                      }}
                      style={{
                        backgroundColor:
                          showGrowthOverTime == "cummulative" ? "#fea338" : "",
                      }}
                      className="flex items-center gap-1 justify-center"
                      id="pl-growth-cumulative-guide"
                    >
                      Cumulative
                      <a
                        data-tooltip-id="pnl-cummulative-tooltip-border"
                        className="cursor-pointer"
                      >
                        <img className="w-[16px]" src={infoIcon} alt="" />
                      </a>
                      <Tooltip
                        id="pnl-cummulative-tooltip-border"
                        className="circle-page-header-tooltip"
                        border={"1px solid #ffffff67"}
                        content="Displays how your total account P&L accumulated over the course of each trading day."
                      />
                    </button>
                    <button
                      onClick={() => setShowGrowthOverTime("daily")}
                      style={{
                        backgroundColor:
                          showGrowthOverTime == "daily" ? "#fea338" : "",
                      }}
                      className="flex items-center gap-2 justify-center"
                      id="pl-growth-daily-guide"
                    >
                      Daily
                      <a
                        data-tooltip-id="pnl-daily-tooltip-border"
                        className="cursor-pointer"
                      >
                        <img className="w-[16px]" src={infoIcon} alt="" />
                      </a>
                      <Tooltip
                        className="circle-page-header-tooltip"
                        border={"1px solid #ffffff67"}
                        id="pnl-daily-tooltip-border"
                        content="Displays your total net P&L for each trading day on the day it is realized."
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            {showGrowthOverTime == "cummulative" && (
              <PLGrowthChartCummulative date={date} />
            )}
            {showGrowthOverTime == "daily" && (
              <PLGrowthChartDaily date={date} />
            )}
          </div>
        </div>
      </div>
      <div className="trade-score-section">
        <div className="trade-score dashboard-card">
          <div className="trade-score-header p-0">
            <TradeScoreOvertimeChart date={date} />
          </div>
        </div>
      </div>
      <div className="trade-plan-discipline-copilot-wrap">
        <div className="dashboard-card">
          <div className="trade-plan-table-heading">
            <div className="title">
              <h3 id="trade-plan-dicipline-guide">
                <span>Trade Plan </span> Discipline
              </h3>
            </div>
          </div>
          <div className="table-wrapper" id="table-wrapper-dash">
            <TradePlanDiscipline date={date} />
          </div>
        </div>
        <div className="instrument-pnl-performance-wrap">
          <div className="instm-performance dashboard-card">
            <div className="title">
              <h3 id="instrument-perf-guide">
                Instrument <span> Wise Performance </span>
              </h3>
            </div>
            <div className="inst-perf-grid">
              <ScripMap date={date} />
            </div>
          </div>
          {/* <div className="pnl-performance dashboard-card">
          <h3 className="title">
            PnL Market<span> Day Performance</span>
          </h3>
        </div> */}
        </div>
        {/* <CopilotInsights filterType={CopilotCategory.adherence} date={date} /> */}
      </div>
      <div className="pnl-by-time-of-day-section">
        <div className="pnl-by-time-of-day dashboard-card">
          <div className="title">
            <h3 id="pl-tod-guide">
              P&L by <span> Time of the Day</span>
            </h3>
          </div>
          <TodChart date={date} />
        </div>
      </div>

      <MindsetGraph date={date} />
    </Layout>
  );
};

export default Dashboard;
