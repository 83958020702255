import { getWizrScore } from "@/module/dashboard/api/insights";
import { DateRange } from "react-day-picker";
import { useQuery } from "react-query";
import { Hint, RadarChart } from "react-vis";
import { format, addDays } from "date-fns";
import "react-vis/dist/style.css";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "@/store/reduxHooks";
import winzerScoreWings from "@/assets/wizer-score-img.svg";
import { GetWizrScoreResponse } from "@/module/dashboard/interface/insightsInterface";
import { CustomErrorBoundary } from "../errorBoundary/CustomErrorBoundary";
import { Tooltip } from "react-tooltip";
import infoIcon from "@/assets/InfoIcon.svg";

const styleTooltip = {
  display: "flex",
  alignItems: "center",
  justIfyContent: "center",
  padding: "1px",
  color: "#FEA338",
  background: "transparent",
};

const WizrGraph = ({ date }: { date: DateRange | undefined }) => {
  const userState = useAppSelector((state) => state.userState);
  const { brokerId, isAuthenticated, isGuidedFlowSeen } = userState;
  const [isApiError, setIsApiError] = useState(false);
  const childRef = useRef<any>(null);

  const wizrScore = useQuery({
    queryKey: ["wizrScore", date, brokerId, isAuthenticated],
    queryFn: () => {
      setIsApiError(false);

      return getWizrScore({
        start_date: date?.from
          ? format(date.from, "yyyy-MM-dd")
          : format(addDays(new Date(), -7), "yyyy-MM-dd"),
        end_date: date?.to
          ? format(date.to, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        broker_id: brokerId,
      });
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && brokerId.length > 0 && isGuidedFlowSeen,
    retry: 0,
    onError: (error) => {
      setScoreData({
        r_r: 0,
        profit_factor: 0,
        win_rate: 0,
        rr_score: 0,
        pf_score: 0,
        wr_score: 0,
        ps_score: 0,
        wizr_score: 0,
      });
      setWizrScoreData(data);
      setIsApiError(true);
    },
  });

  const data = [
    {
      name: "spider5",
      profitfacor: 100,
      winrate: 100,
      pschology: 100,
      returnrate: 100,
      stroke: "#FFFFFF73",
    },

    {
      name: "spider4",
      profitfacor: 80,
      winrate: 80,
      pschology: 80,
      returnrate: 80,
      stroke: "#FFFFFF73",
    },

    {
      name: "spider3",
      profitfacor: 60,
      winrate: 60,
      pschology: 60,
      returnrate: 60,
      stroke: "#FFFFFF73",
    },

    {
      name: "spider1",
      profitfacor: 40,
      winrate: 40,
      pschology: 40,
      returnrate: 40,
      stroke: "#FFFFFF73",
    },

    {
      name: "spider0",
      profitfacor: 20,
      winrate: 20,
      pschology: 20,
      returnrate: 20,
      stroke: "#FFFFFF73",
    },

    {
      name: "wizrScore",
      profitfacor: 0,
      winrate: 0,
      pschology: 0,
      returnrate: 0,
      fill: "#825929D9",
      stroke: "rgba(114,172,240,0.5)",
    },
  ];
  const [wizrScoreData, setWizrScoreData] = useState<any>(data);

  const [scoreData, setScoreData] = useState<GetWizrScoreResponse>({
    r_r: 0,
    profit_factor: 0,
    win_rate: 0,
    rr_score: 0,
    pf_score: 0,
    wr_score: 0,
    ps_score: 0,
    wizr_score: 0,
  });

  useEffect(() => {
    if (wizrScore.isSuccess && wizrScore.data) {
      const scoreData = wizrScore.data;
      setScoreData(scoreData);

      const newData = [...data];

      const wizrScoreIndex = newData.findIndex(
        (item) => item.name === "wizrScore"
      );

      if (wizrScoreIndex !== -1) {
        newData[wizrScoreIndex] = {
          ...newData[wizrScoreIndex],
          profitfacor: scoreData.pf_score,
          winrate: scoreData.wr_score,
          pschology: scoreData.ps_score,
          returnrate: scoreData.rr_score,
        };
      }
      setWizrScoreData(newData);
    }
  }, [wizrScore.data]);

  const RadarProps = {
    startingAngle: 150,
    data: wizrScoreData,
    domains: [
      {
        name: "",
        domain: [0, 100],
        getValue: (d: any) => d.pschology,
        tickFormat: (d: any) => "",
      },
      {
        name: "",
        domain: [0, 100],
        getValue: (d: any) => d.winrate,
        tickFormat: (d: any) => "",
      },
      {
        name: "",
        domain: [0, 100],
        getValue: (d: any) => d.profitfacor,
        tickFormat: (d: any) => "",
      },
      {
        name: "",
        domain: [0, 100],
        getValue: (d: any) => d.returnrate,
        tickFormat: (d: any) => "",
      },
    ],
    width: 600,
    height: 350,
  };

  return (
    <CustomErrorBoundary
      isError={wizrScore.isError}
      isLoading={wizrScore.isLoading}
      error={wizrScore.error}
      childRef={childRef}
      isApiError={isApiError}
    >
      {scoreData && (
        <div
          ref={childRef}
          className="main-content-right main-inside-content-wizScore"
          style={{ opacity: isApiError ? 0.6 : 1 }}
          id="wizr-graph-guide"
        >
          <div className="wizr-wing-transform">
            <h3 className="title text-center flex items-center gap-1 ml-4">
              <span>Wizr</span> Score
              <a
                data-tooltip-id="wizrs-tooltip-border"
                className="cursor-pointer"
              >
                <img className="w-[16px]" src={infoIcon} alt="" />
              </a>
              <Tooltip
                className="circle-page-header-tooltip"
                border={"1px solid #ffffff67"}
                id="wizrs-tooltip-border"
                content="Wizr Score"
              />
            </h3>
            <div className="wizer-score">
              <img src={winzerScoreWings} alt="" />
              <div className="wizer-score-title-data-wrap">
                <h3>Wizr Score</h3>
                <span>{scoreData.wizr_score}</span>
              </div>
            </div>
          </div>

          <div className="text-center wizer-chart">
            <div className="spider-graph-chart displayFlexCentre">
              <RadarChart
                {...RadarProps}
                colorRange={["transparent"]}
                colorType={"category"}
                style={{
                  polygons: {},
                  labels: {
                    // textAnchor: "middle",
                    // fontSize: "18px",
                    // fill: "#FEA338",
                  },
                  axes: {
                    line: {
                      opacity: "0.5",
                    },
                    ticks: {
                      display: "none",
                    },
                    text: {
                      // stroke: "#FEA338",
                      // fontWeight: 500,
                      // fontSize: "16px",
                    },
                  },
                }}
              >
                <Hint
                  style={styleTooltip}
                  value={{
                    wizrScore: Math.round(scoreData.pf_score),
                    x: -1,
                    y: 1.0,
                  }}
                  align={{
                    horizontal: "auto",
                    vertical: "auto",
                  }}
                >
                  <div>
                    <p>{"Profit factor"}</p>
                  </div>
                </Hint>

                <Hint
                  style={styleTooltip}
                  value={{
                    wizrScore: Math.round(scoreData.rr_score),
                    x: -0.9,
                    y: -0.9,
                  }}
                  align={{
                    horizontal: "auto",
                    vertical: "auto",
                  }}
                >
                  <div>
                    <p>{"R R"}</p>
                  </div>
                </Hint>

                <Hint
                  style={styleTooltip}
                  value={{
                    wizrScore: Math.round(scoreData.ps_score),
                    x: 1.05,
                    y: -0.9,
                  }}
                  align={{
                    horizontal: "auto",
                    vertical: "auto",
                  }}
                >
                  <div>
                    <p>{"Psychology"}</p>
                  </div>
                </Hint>
                <Hint
                  style={styleTooltip}
                  value={{
                    wizrScore: Math.round(scoreData.wr_score),
                    x: 1,
                    y: 1.0,
                  }}
                  align={{
                    horizontal: "auto",
                    vertical: "auto",
                  }}
                >
                  <div>
                    <p>{"Win rate"}</p>
                  </div>
                </Hint>
              </RadarChart>
            </div>
          </div>
        </div>
      )}
    </CustomErrorBoundary>
  );
};

export default WizrGraph;
