import { apiClient } from "@/utils/api";
import type { AxiosResponse } from "axios";
import { UTCTimestamp } from "lightweight-charts";

export interface SingleTrade {
  time: string;
  tradingsymbol: string;
  open: number;
  high: number;
  low: number;
  close: number;
}

export interface GetChartReplayResponse {
  m1: SingleTrade[];
  m5: SingleTrade[];
  m15: SingleTrade[];
}

export interface SingleTradeWithUTCTimestamp extends Omit<SingleTrade, "time"> {
  time: UTCTimestamp;
}

export interface GetChartReplayResponseWithUTCTimestamp {
  m1: SingleTradeWithUTCTimestamp[];
  m5: SingleTradeWithUTCTimestamp[];
  m15: SingleTradeWithUTCTimestamp[];
}

export interface GetOrdersResponse {
  tradingsymbol: string;
  quantity: number;
  transaction_type: string;
  average_price: number;
  trade_id: string;
  order_timestamp: string;
  order_date: string;
}

export interface GetOrdersResponseModified extends GetOrdersResponse {
  time: UTCTimestamp;
}

const getChartReplay = async ({
  trade_date,
  scrip,
  tradingsymbol,
}: {
  trade_date: string;
  scrip: string;
  tradingsymbol: string;
}): Promise<GetChartReplayResponse> => {
  const res: AxiosResponse<GetChartReplayResponse> = await apiClient.get(
    "/charts/v1/replay",
    {
      params: {
        trade_date,
        scrip,
        tradingsymbol,
      },
    }
  );

  return res.data;
};

const getAllOrders = async ({
  trade_id,
}: {
  trade_id: string;
}): Promise<GetOrdersResponse[]> => {
  const res: AxiosResponse<GetOrdersResponse[]> = await apiClient.get(
    "/insights/v1/trade-orders",
    {
      params: {
        trade_id,
      },
    }
  );
  return res.data;
};

export { getChartReplay, getAllOrders };
