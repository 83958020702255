"use client";

import { ColumnDef } from "@tanstack/react-table";
import { GetExitProtocolHistoryResponse } from "../../api/exitprotocolhistory";
import angryImg from "@/assets/angry-emotion.svg";
import sadImg from "@/assets/sad-emaotion.svg";
import panicImg from "@/assets/panic-emotion.svg";
import happyImg from "@/assets/happy-emotion.svg";
import zenImg from "@/assets/zen-emotion.svg";
import { ro } from "date-fns/locale";
import { Value } from "@radix-ui/react-select";
import { formatRupees } from "./helper";

const emotion = [
  { type: "happy", url: happyImg },
  { type: "zen", url: zenImg },
  { type: "angry", url: angryImg },
  { type: "panic", url: panicImg },
  { type: "sad", url: sadImg },
];

const getIconByType = (emotionType: string) => {
  const emotionObject = emotion.find((e) => e.type === emotionType);
  return emotionObject ? emotionObject.url : undefined;
};

export const getColumns = (
  setExitData: React.Dispatch<
    React.SetStateAction<GetExitProtocolHistoryResponse | undefined>
  >,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
): ColumnDef<GetExitProtocolHistoryResponse>[] => [
  {
    accessorKey: "numbering",
    enableHiding: false,
    header: "",
    cell: ({ row }) => (
      <div className="instruments-count-number">
        <span>{row.index + 1}</span>
      </div>
    ),
  },

  {
    accessorKey: "tradingsymbol",
    header: "Instrument",
    enableHiding: false,
    cell: ({ row }) => (
      <div className="text-center capitalize">
        {row.getValue("tradingsymbol")}
      </div>
    ),
  },
  {
    accessorKey: "trade_date",
    header: "Date of Entry",
    cell: ({ row }) => (
      <div className="text-center lowercase">{row.getValue("trade_date")}</div>
    ),
  },
  {
    accessorKey: "trade_start_time",
    header: "Entry Time",
    cell: ({ row }) => {
      return (
        <div className="text-center font-medium">
          {new Date(row.getValue("trade_start_time")).toLocaleTimeString()}
        </div>
      );
    },
  },
  {
    accessorKey: "emotion",
    header: "Emotions",
    cell: ({ row }) => {
      const emotion: string = row.getValue("emotion");
      return (
        <>
          {emotion && (
            <div className="history-table-emotion-text">
              <div className="astro-img-bg-box">
                <div className="astro-img-container">
                  <img
                    style={{ marginLeft: emotion == "angry" ? "7px" : "" }}
                    className="history-table-astro-img"
                    src={getIconByType(emotion)}
                    alt=""
                  />
                </div>
              </div>
              <div className="text-center font-medium capitalize">
                {emotion}
              </div>
            </div>
          )}
        </>
      );
    },
  },
  {
    accessorKey: "strategy",
    header: "Strategy (version)",
    enableHiding: false,
    cell: ({ row }) => {
      return (
        <div className="text-center font-medium capitalize">
          {row.getValue("strategy")}
          {row.original.strategy_version && (
            <>(v{row.original.strategy_version})</>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "trade_plan_followed",
    header: "Trade Plan Followed",
    cell: ({ row }) => {
      return (
        <div className="text-center font-medium capitalize">
          {row.getValue("trade_plan_followed")}
        </div>
      );
    },
  },
  {
    accessorKey: "average_entry_price",
    header: "Entry Price",
    cell: ({ row }) => {
      return (
        <div className="text-center font-medium">
          {formatRupees(row.getValue("average_entry_price"))}
        </div>
      );
    },
  },
  {
    accessorKey: "average_exit_price",
    header: "Exit Price",
    cell: ({ row }) => {
      return (
        <div className="text-center font-medium">
          {formatRupees(row.getValue("average_exit_price"))}
        </div>
      );
    },
  },
  {
    accessorKey: "trade_points",
    header: "Points",
    cell: ({ row }) => {
      return (
        <div className="text-center font-medium">
          {row.getValue("trade_points")}
        </div>
      );
    },
  },
  {
    accessorKey: "trade_roi",
    header: "ROI",
    cell: ({ row }) => {
      return (
        <div className="text-center font-medium">
          {row.getValue("trade_roi")}
        </div>
      );
    },
  },
  {
    accessorKey: "trade_mtm",
    header: "Profit",
    cell: ({ row }) => {
      return (
        <div className="text-center font-medium">
          {Number(row.getValue("trade_mtm")) > 0 ? "" : "-"}
          {formatRupees(Math.abs(row.getValue("trade_mtm")))}
        </div>
      );
    },
  },
  {
    accessorKey: "exit_reason",
    header: "Exit Reason",
    cell: ({ row }) => {
      return (
        <div className="text-center font-medium capitalize">
          {row.getValue("exit_reason")}
        </div>
      );
    },
  },
  {
    accessorKey: "trade_outcome",
    header: "Win/Loss",
    enableHiding: false,
    cell: ({ row }) => {
      return (
        <div className="text-center font-medium capitalize">
          {row.getValue("trade_outcome")}
        </div>
      );
    },
  },
  {
    accessorKey: "notes",
    header: "Notes",
    cell: ({ row }) => {
      const notes: string[] | null = row.getValue("notes");
      return (
        <div className="text-center font-medium">
          {notes &&
            notes.map((el: string, index: number) => {
              return (
                <p>
                  {index + 1}. {el}
                </p>
              );
            })}
        </div>
      );
    },
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => {
      const original = row.original;
      return (
        <p
          onClick={() => {
            setExitData(original);
            setOpen(true);
          }}
          className="trade-journal-edit-btn"
        >
          Edit
        </p>
      );
    },
  },
];
