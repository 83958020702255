import "@/components/errorBoundary/customerror.css";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

const CustomErrorBoundary = ({
  isError,
  isLoading,
  error,
  children,
  childRef,
  isApiError,
  customClassName,
  noData,
}: {
  isError: any;
  isLoading: any;
  error: any;
  children: any;
  childRef?: any;
  isApiError?: boolean;
  customClassName?: string;
  noData?: boolean;
}) => {
  return (
    <>
      <div
        style={{ opacity: isLoading ? 0.5 : "" }}
        className="loading-msg-content"
      >
        {children}
        {isApiError && (
          <>
            <div className={`loading-filter-blur ${customClassName}`}>
              {error?.response?.data.detail && (
                <div className="flex flex-col gap-2">
                  No data available for the selected date range
                  <Popover>
                    <PopoverTrigger className="flex items-center justify-center text-[#fea338] underline cursor-pointer hover:text-[#fea238c9]">
                      More Details
                    </PopoverTrigger>
                    <PopoverContent className="support-in-webapp more-details-support">
                      <div className="comment-square__arrow"></div>
                      <div className="flex flex-col items-center justify-center text-white text-center">
                        {error?.response?.data.detail}
                      </div>
                    </PopoverContent>
                  </Popover>
                </div>
              )}
              {noData ? "No data available for the selected date range" : ""}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export { CustomErrorBoundary };
