export const objectToSearchParams = (obj: any) => {
  const params = new URLSearchParams();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      params.append(key, obj[key]);
    }
  }

  return params;
};

export const capitalizeFirstLetterOfWords = (sentence: string) => {
  // Split the sentence into an array of words
  const words = sentence.split(" ");

  // Capitalize the first character of each word
  const capitalizedWords = words.map((word) => {
    if (word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    } else {
      return word; // If the word is an empty string, keep it as is
    }
  });

  // Join the capitalized words back into a sentence
  const capitalizedSentence = capitalizedWords.join(" ");

  return capitalizedSentence;
};
